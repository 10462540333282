import { Injectable, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { ILoggingConfigProvider } from './config-providers/logging-config.provider';
import { LogLevel, LogEntry, LoggingConfig, SinkConfig } from './models';
import { LoggerSink } from './sinks/logger.sink';

export const LOGGER_SINKS = new InjectionToken<LoggerSink[]>('LOGGER_SINKS');

@Injectable()
export class LoggerService implements OnDestroy {
	private unsubscribe$ = new Subject<void>();
	private logging$ = new Subject<LogEntry>();

	constructor(
		@Inject(LOGGER_SINKS) private sinks: LoggerSink[],
		private loggerConfigProvider: ILoggingConfigProvider
	) {
		this.initialize();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public log(scope: string, level: LogLevel, eventId: string, message: string, args?: unknown): void {
		this.logging$.next({
			level,
			scope,
			eventId,
			message,
			args
		});
	}

	private initialize(): void {
		this.logging$
			.pipe(withLatestFrom(this.loggerConfigProvider.config$), takeUntil(this.unsubscribe$))
			.subscribe(([logEntry, loggingConfig]: [LogEntry, LoggingConfig]) => {
				this.sinks.forEach((sink: LoggerSink) => {
					const sinkConfig = loggingConfig[sink.name] ? loggingConfig[sink.name] : loggingConfig.base;

					if (this.shouldLog(logEntry, sinkConfig)) {
						sink.log(logEntry);
					}
				});
			});
	}

	private shouldLog(logEntry: LogEntry, sinkConfig: SinkConfig): boolean {
		let logLevel = sinkConfig.default;

		for (const scope in sinkConfig) {
			if (logEntry.scope.startsWith(scope)) {
				logLevel = sinkConfig[scope];
				break;
			}
		}

		return logEntry.level >= logLevel;
	}
}
