<div id="sh-carousel-image-container-div" *transloco="let t">
	<img id="sh-carousel-image-img" [src]="imageList[currentImageIndex] | version | async" />
	<div id="sh-carousel-banner-text-div">
		<div id="sh-carousel-welcome-text-div">
			{{ t('welcome-to') }} <b>{{ t('shure-cloud') }}</b
			>, {{ firstName }}
		</div>
		<span id="sh-carousel-manage-devices-text-span">{{ t('manage-devices-licenses-and-more') }}</span>
	</div>
</div>
