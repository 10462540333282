import { DeviceModel } from '@shure/shared/models';

import { DeviceImage } from './device-image';

/**
 * `DeviceImageBehaviorPlugin` describes the images associated with a
 * specific device.
 */
export interface DeviceImageBehaviorPlugin {
	/**
	 * Get images associated with a specific device.
	 *
	 * Expects:
	 *  - Raw image filenames - all file names will be formatted with complete
	 *    path to image.
	 *  - `lightsBackground` to be placed in `{ASSETS_PATH}/devices-lights/devices/*`
	 *  - `lightsOverlay` to be placed in `{ASSETS_PATH}/devices-lights/lights/*`
	 *  - All other to be placed in `{ASSETS_PATH}/devices/*`
	 */
	getImage(model: DeviceModel): DeviceImage | undefined;
}
