import { Injectable, signal } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscriber,
	Subscription,
	filter,
	from,
	map,
	merge,
	take,
	takeUntil,
	tap
} from 'rxjs';

import { ApolloCacheFactory } from '@shure/shared/angular/apollo';
import { ILogger, jsonFormat } from '@shure/shared/angular/logging';
import { OktaInterfaceService, monitorLoginState } from '@shure/shared/angular/okta';

import {
	DiscoveryDeviceFragment,
	DiscoveredDevicesSubscriptionGQL,
	DiscoveredDevicesQueryGQL,
	DiscoveredDevicesQueryOpResult
} from '../../graphql/generated/cloud-sys-api';

import { DeviceDiscoveryApiService } from './device-discovery.service';

export const DISCOVERED_DEVICES_QUERY_PAGE_SIZE = 50;
export interface DeviceDiscoveryEvent {
	added?: DiscoveryDeviceFragment;
	updated?: DiscoveryDeviceFragment;
	removed?: string;
}

@Injectable({ providedIn: 'root' })
export class DaiDeviceDiscoveryService implements DeviceDiscoveryApiService {
	public readonly deviceDiscoveryInProgress = signal(false);
	public readonly numDiscoveredDevices = signal(-1);
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	private deviceAddedInternal$ = new Subject<string>();
	private deviceRemovedInternal$ = new Subject<string>();
	private discoveredDevicesMap = new Map<string, DiscoveryDeviceFragment>();
	private discoveredDevicesInternal$ = new BehaviorSubject<DiscoveryDeviceFragment[]>([]);

	private _discoveryEvents$ = new Subject<DeviceDiscoveryEvent>();

	private paginatedQueryEndCursor$: Subject<string | null> | undefined = undefined;

	constructor(
		logger: ILogger,
		private readonly discoveredDevicesQueryGQL: DiscoveredDevicesQueryGQL,
		private readonly discoveredDevicesSubscriptionGQL: DiscoveredDevicesSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		private readonly apolloCacheFactory: ApolloCacheFactory
	) {
		this.logger = logger.createScopedLogger('DaiDeviceDiscoveryService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public deviceAdded$() {
		return this.deviceAddedInternal$.asObservable();
	}

	public deviceRemoved$() {
		return this.deviceRemovedInternal$.asObservable();
	}

	public discoveryEvents$() {
		return merge(
			this._discoveryEvents$,
			from([...this.discoveredDevicesMap.values()].map((device) => ({ added: device })))
		);
	}

	public discoveredDevicesCount$() {
		return this.discoveredDevicesInternal$.pipe(map((devices) => devices.length));
	}

	/**
	 * This function is responsible for emitting a list of devices, and properites as determined by
	 * the supplied query function, that have been discovered. Essentially, this function creates and
	 * returns a new Observable the caller can subscribe that is a list of discovered Devices
	 * along with their properties. As devices are added/removed, a new list is emitted on the observable.
	 *
	 * - This discovery service maintains a list of discovered devices, and then
	 *   keeps that list accurate as deviceAdded and deviceRemoved events are received.
	 *
	 * - When the service starts/initializes, a query is performed for every device currently claimed
	 *   Each device returned results in a deviceAdded event being added to the discoveryEvent subject
	 *
	 * - As deviceAdded and removed events are received, the events are added to the discoveryEvent subject
	 *
	 * - The contents and changes to the discoveryEvent subject drive this function.
	 *
	 * Within the creation of the Observable that's returned (a closure), is the storage of the returnedDevices
	 * and their respective query handler.
	 *
	 * - When a deviceAdded event is received, the query is dispatched for the device. When the query
	 *   response is received, the device and it's data is added to the list of returned devices, then
	 *   the updated list of returned devices is emitted on the Observable. At this point, the device is avaiable
	 *   for anyone subscirbe to the over-all Observable. For book keeping purposes, the device to query handler
	 *   mapping is stored so we can unsubscribs from the query if a deviceRemoved event is received later on.
	 *
	 * - When a deviceRemoved event is received, we unsubscribe from the query, remove the device from the
	 *   list of returned devices, then emit the updated list of devices on the Observable.
	 *
	 * @param elementQueryFunction The query function to execute and subscribe to when a device is added.
	 * @param elementFilterFunction A filter function to omit exeucting query fuction on some devices.
	 * @returns
	 */
	public getDiscoveredDevicesByQuery$<ElementType extends { id: string }>(
		elementQueryFunction: (id: string) => Observable<ElementType>,
		elementFilterFunction?: (element: ElementType) => boolean
	): Observable<ElementType[]> {
		return new Observable((observer: Subscriber<ElementType[]>) => {
			observer.next([]); // Start by emitting empty array in case no devices that pass the filter exist
			try {
				const returnedDevices = new Map<string, ElementType>();
				const queryHandlers = new Map<string, Subscription>();
				this.discoveryEvents$().subscribe((e) => {
					if ('added' in e && e.added?.id) {
						const queryHandler = elementQueryFunction(e.added?.id)
							.pipe(filter((d) => (elementFilterFunction ? elementFilterFunction(d) : true)))
							// eslint-disable-next-line rxjs/no-nested-subscribe
							.subscribe((d) => {
								returnedDevices.set(d.id, d);
								observer.next([...returnedDevices.values()]);
							});
						queryHandlers.set(e.added?.id, queryHandler);
					} else if ('removed' in e && e.removed) {
						const queryHandler = queryHandlers.get(e.removed);
						if (queryHandler) {
							queryHandler.unsubscribe();
							queryHandlers.delete(e.removed);
						}
						returnedDevices.delete(e.removed);
						observer.next([...returnedDevices.values()]);
					}
				});

				// finalizer method... to automatically unsbubscribe from all queries
				// when/if no one is subscribe to the Observable.
				observer.add(() => {
					queryHandlers.forEach((queryHandler) => queryHandler.unsubscribe());
				});
			} catch (error) {
				observer.error();
			}
		});
	}

	private initService() {
		this.logger.information('initService', 'user logged in, initializing service');

		// we need a new destroy$ subject since it is "completed" when the user logs out.

		this.discoveredDevicesMap.clear();
		this.emitDiscoveredDevices();
		this.subscribeDiscoveredDevices();

		this.paginatedQueryEndCursor$ = new Subject<string | null>();
		this.paginatedQueryEndCursor$.subscribe({
			next: (endCursor) => {
				this.queryDiscoveredDevicesNetwork(endCursor);
			}
		});
		this.paginatedQueryEndCursor$.next(null); // trigger the initial query;
		this.deviceDiscoveryInProgress.set(true);
	}

	private suspendService() {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.discoveredDevicesMap.clear();
		this.emitDiscoveredDevices();
		this.destroy$.next();
		this.destroy$.complete();
		this.paginatedQueryEndCursor$?.complete();
		this.deviceDiscoveryInProgress.set(false);
	}

	private handleDeviceAdded(device: DiscoveryDeviceFragment): void {
		this.logger.information('handleDeviceAdded', '', jsonFormat({ device }));

		this._discoveryEvents$.next({ added: device });
		this.deviceAddedInternal$.next(device.id);
		this.discoveredDevicesMap.set(device.id, device);
		this.emitDiscoveredDevices();
	}

	private handleDeviceRemoved(id: string): void {
		this.logger.information('handleDeviceRemoved', '', { id });

		this._discoveryEvents$.next({ removed: id });
		this.deviceRemovedInternal$.next(id);
		this.discoveredDevicesMap.delete(id);
		this.emitDiscoveredDevices();

		// remove the entry from the apollo cache. The only other way it would
		// get removed is the app refreshing or closing down.
		this.apolloCacheFactory.cache?.evict({ id: `Device:${id}` });
	}

	private emitDiscoveredDevices() {
		this.discoveredDevicesInternal$.next([...this.discoveredDevicesMap.values()]);
		this.numDiscoveredDevices.set(this.discoveredDevicesMap.size);
	}

	private subscribeDiscoveredDevices(): void {
		this.discoveredDevicesSubscriptionGQL
			.subscribe(
				{},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  always fetch from network, then store in cache
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					if (change.data) {
						if ('added' in change.data.discoveredDevices) {
							this.handleDeviceAdded(change.data.discoveredDevices.added);
						} else if ('removed' in change.data.discoveredDevices) {
							this.handleDeviceRemoved(change.data.discoveredDevices.removed);
						}
					}
				},

				complete: () => {
					this.logger.warning('subscribeDiscoveredDevices', 'completed');
				},

				error: (err) => {
					setTimeout(() => {
						this.logger.error('subscribeDiscoveredDevices', 'error', jsonFormat({ err }));
						this.subscribeDiscoveredDevices();
					}, 10000);
				}
			});
	}

	private queryDiscoveredDevicesNetwork(afterCursor: string | null): void {
		this.discoveredDevicesQueryGQL
			.fetch(
				{
					first: DISCOVERED_DEVICES_QUERY_PAGE_SIZE,
					after: afterCursor,
					deviceModels: []
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(
				take(1),
				tap((devices) => this.processDiscoveredDevicesQueryResult(devices.data))
			)
			.subscribe();
	}

	private processDiscoveredDevicesQueryResult(devices: DiscoveredDevicesQueryOpResult) {
		const { hasNextPage, endCursor } = devices.discoveredDevicesConnection.pageInfo;

		// if there's more data on the server, trigger the next query.
		if (hasNextPage && endCursor && endCursor.length !== 0) {
			this.paginatedQueryEndCursor$?.next(endCursor);
		} else {
			this.deviceDiscoveryInProgress.set(false);
		}
		devices.discoveredDevicesConnection.edges
			.map((d) => d.node)
			.filter((d): d is DiscoveryDeviceFragment => !!d)
			.forEach((d) => this.handleDeviceAdded(d));
	}
}
