/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';



/**
 * This list CORS with Options HTTP Method.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiCorsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notificationsOptions
   */
  static readonly NotificationsOptionsPath = '/notifications';

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsOptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiCorsService.NotificationsOptionsPath, 'options');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationsOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsOptions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.notificationsOptions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation notificationOptions
   */
  static readonly NotificationOptionsPath = '/notification';

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationOptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiCorsService.NotificationOptionsPath, 'options');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationOptions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.notificationOptions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation notificationsByIdOptions
   */
  static readonly NotificationsByIdOptionsPath = '/notification/{notificationId}';

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsByIdOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsByIdOptions$Response(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiCorsService.NotificationsByIdOptionsPath, 'options');
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationsByIdOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsByIdOptions(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.notificationsByIdOptions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mailOptions
   */
  static readonly MailOptionsPath = '/mail';

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailOptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiCorsService.MailOptionsPath, 'options');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * CORS support.
   *
   * Enable CORS by returning correct headers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailOptions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.mailOptions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
