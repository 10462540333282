import { WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';

import { UpdateRequestResponse, UpdateResponse } from '@shure/shared/models';

import { UpdateDeviceFirmwareInput } from '../../../graphql/generated/cloud-sys-api';

export abstract class CloudDeviceService {
	public abstract readonly lastFWUpdateTime: WritableSignal<number>;

	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>>;
	public abstract setMute(deviceId: string, deviceMute: boolean): Observable<UpdateRequestResponse<string>>;
	public abstract setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>>;
	public abstract rebootDevice$(deviceId: string): Observable<UpdateResponse<void, string>>;
	public abstract updateFirmware$(updates: UpdateDeviceFirmwareInput[]): Observable<UpdateResponse<void, string>>;
}
