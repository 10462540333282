<div class="sh-ip-address-container" [ngClass]="containerClass">
	<span class="sh-ip-address-label mat-caption">
		<label for="">{{ labelName }}</label>
	</span>
	<div class="sh-ip-address-table" [ngClass]="readonly ? 'sh-ip-address-readonly-mode' : ''">
		<ng-template ngFor let-idx [ngForOf]="blocksRef" ; let-isLast="last" *ngIf="!readonly">
			<div class="sh-ip-address-table-cell">
				<mat-form-field [ngClass]="invalidBlocks[idx]">
					<input
						#input
						matInput
						type="text"
						[value]="blocks[idx] || ''"
						(change)="onChange($any($event.target).value, idx)"
						(blur)="onTouched()"
						(focus)="onFocus(idx)"
						(keypress)="onKeyPress($event, idx)"
						(keyup)="onKeyUp($event, idx)"
						[id]="'sh-ip-input' + idx"
					/>
				</mat-form-field>
			</div>
			<span class="sh-ip-address-table-cell sh-ip-address-sep" *ngIf="!isLast">.</span>
		</ng-template>
		<ng-template ngFor let-idx [ngForOf]="blocksRef" ; let-isLast="last" *ngIf="readonly">
			<div class="sh-ip-address-label-only">{{ blocks[idx] ? blocks[idx] : '---' }}</div>
			<span *ngIf="!isLast">.</span>
		</ng-template>
	</div>
</div>
