import { NgModule } from '@angular/core';

import { DeviceImageProvider } from '@shure/shared/angular/components';
import {
	DeviceBehaviorPluginsModule,
	DeviceImageBehaviorPluginService
} from '@shure/shared/angular/device-behavior/core';
import { Ad600DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/ad600';
import { AdtdDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/adtd';
import { AdtqDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/adtq';
import { AniusbDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/aniusb';
import { ImxrDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/imxr';
import { McrDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mcr';
import { Mxa710DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxa710';
import { Mxa901DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxa901';
import { Mxa902DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxa902';
import { Mxa920DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxa920';
import { Mxn5cDesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxn5c';
import { Mxwapxd2DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/mxwapxd2';
import { P300DesignerDeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/devices/p300';

@NgModule({
	imports: [
		DeviceBehaviorPluginsModule.forRoot([
			Mxa710DesignerDeviceBehaviorPlugin,
			Mxa920DesignerDeviceBehaviorPlugin,
			Mxwapxd2DesignerDeviceBehaviorPlugin,
			P300DesignerDeviceBehaviorPlugin,
			AniusbDesignerDeviceBehaviorPlugin,
			Mxa901DesignerDeviceBehaviorPlugin,
			Mxa902DesignerDeviceBehaviorPlugin,
			ImxrDesignerDeviceBehaviorPlugin,
			Mxn5cDesignerDeviceBehaviorPlugin,
			McrDesignerDeviceBehaviorPlugin,
			Ad600DesignerDeviceBehaviorPlugin,
			AdtdDesignerDeviceBehaviorPlugin,
			AdtqDesignerDeviceBehaviorPlugin
		])
	],
	providers: [
		{
			provide: DeviceImageProvider,
			useExisting: DeviceImageBehaviorPluginService
		}
	]
})
export class CloudDeviceBehaviorPluginsModule {}
