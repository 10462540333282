<ng-container *transloco="let t">
	<h1 mat-dialog-title id="sh-about-dialog-h1-title">{{ t(data.title) }}</h1>
	<div mat-dialog-content>
		<p id="sh-about-dialog-p-version">{{ t('version') }} - {{ version }}</p>
		<p *ngIf="clientVersion" id="sh-about-dialog-client-version">{{ t('client-version') }} - {{ clientVersion }}</p>
		<p id="sh-about-dialog-p-rights">{{ t('shure-rights') }}</p>
	</div>
	<mat-checkbox
		(click)="$event.stopPropagation()"
		[checked]="isChecked"
		(change)="onSelectionChanged($event)"
		*ngIf="showDebugMode && isDebugModeEnabledForApplication"
		>{{ t('enable-debug-mode') }}</mat-checkbox
	>
	<div mat-dialog-actions>
		<button mat-button id="sh-about-dialog-button-close" (click)="closeDialog()">{{ t('close') }}</button>
	</div>
</ng-container>
