/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OnBoardOrgUserDto } from '../models/on-board-org-user-dto';
import { OrganizationsDto } from '../models/organizations-dto';
import { SoftDeleteOrgData } from '../models/soft-delete-org-data';
import { ValidateUserInfoDto } from '../models/validate-user-info-dto';

@Injectable({
  providedIn: 'root',
})
export class ApiOrganizationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation setDefaultOrganization
   */
  static readonly SetDefaultOrganizationPath = '/api/v2/setDefaultOrg/{orgId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDefaultOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDefaultOrganization$Response(params: {
    orgId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.SetDefaultOrganizationPath, 'post');
    if (params) {
      rb.path('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDefaultOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDefaultOrganization(params: {
    orgId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.setDefaultOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrganizations
   */
  static readonly GetOrganizationsPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Response(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.GetOrganizationsPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.getOrganizations$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createOrganization
   */
  static readonly CreateOrganizationPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganization$Response(params: {
    context?: HttpContext
    body: OrganizationsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.CreateOrganizationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganization(params: {
    context?: HttpContext
    body: OrganizationsDto
  }
): Observable<void> {

    return this.createOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrganizationsByIds
   */
  static readonly GetOrganizationsByIdsPath = '/api/v2/organizationIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByIds$Response(params: {
    ids: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.GetOrganizationsByIdsPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByIds(params: {
    ids: Array<string>;
    context?: HttpContext
  }
): Observable<void> {

    return this.getOrganizationsByIds$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteOrganization
   */
  static readonly DeleteOrganizationPath = '/api/v2/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization$Response(params: {
    organizationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.DeleteOrganizationPath, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization(params: {
    organizationId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateOrganization
   */
  static readonly UpdateOrganizationPath = '/api/v2/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization$Response(params: {
    organizationId: string;
    context?: HttpContext
    body: OrganizationsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.UpdateOrganizationPath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization(params: {
    organizationId: string;
    context?: HttpContext
    body: OrganizationsDto
  }
): Observable<void> {

    return this.updateOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrganizationsByUserId
   */
  static readonly GetOrganizationsByUserIdPath = '/api/v2/getOrganizationsByUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByUserId$Response(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.GetOrganizationsByUserIdPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByUserId(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.getOrganizationsByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation onBoardOrganizationAndUser
   */
  static readonly OnBoardOrganizationAndUserPath = '/api/v2/onboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onBoardOrganizationAndUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onBoardOrganizationAndUser$Response(params: {
    context?: HttpContext
    body: OnBoardOrgUserDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.OnBoardOrganizationAndUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `onBoardOrganizationAndUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onBoardOrganizationAndUser(params: {
    context?: HttpContext
    body: OnBoardOrgUserDto
  }
): Observable<void> {

    return this.onBoardOrganizationAndUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getVersion
   */
  static readonly GetVersionPath = '/api/v2/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion$Response(params: {
    appName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.GetVersionPath, 'get');
    if (params) {
      rb.query('appName', params.appName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion(params: {
    appName: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.getVersion$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation softDeleteOrganization
   */
  static readonly SoftDeleteOrganizationPath = '/api/v2/softDelete/{orgId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteOrganization$Response(params: {
    orgId: string;
    context?: HttpContext
    body: SoftDeleteOrgData
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.SoftDeleteOrganizationPath, 'patch');
    if (params) {
      rb.path('orgId', params.orgId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDeleteOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteOrganization(params: {
    orgId: string;
    context?: HttpContext
    body: SoftDeleteOrgData
  }
): Observable<void> {

    return this.softDeleteOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDomains
   */
  static readonly GetDomainsPath = '/api/v2/{orgId}/domains';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomains$Response(params: {
    orgId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.GetDomainsPath, 'get');
    if (params) {
      rb.path('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomains(params: {
    orgId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getDomains$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation validateUserInfo
   */
  static readonly ValidateUserInfoPath = '/api/v1/validate-user-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUserInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUserInfo$Response(params: {
    context?: HttpContext
    body: ValidateUserInfoDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiOrganizationsService.ValidateUserInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateUserInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUserInfo(params: {
    context?: HttpContext
    body: ValidateUserInfoDto
  }
): Observable<void> {

    return this.validateUserInfo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
