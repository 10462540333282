/* eslint-disable indent */
import { TranslocoService } from '@ngneat/transloco';

import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';
import { DeviceModel } from '@shure/shared/models';

/**
 * `Mxn5cDeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export abstract class Mxn5cDeviceBehaviorPluginBase implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXN5C];

	constructor(private readonly transloco: TranslocoService) {}

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXN5C:
				return {
					lightsBackground: 'device-mxn5c.svg',
					lightsOverlay: 'device-mxn5c.svg',
					live: 'device-mxn5c.svg',
					virtual: 'device-mxn5c.svg',
					liveBack: 'device-mxn5c.svg',
					virtualBack: 'device-mxn5c.svg'
				};
			default:
				return undefined;
		}
	}
}
