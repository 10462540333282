/**
 * Representation of a breadcrumb
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBreadcrumb {
	/**
	 * Name to display
	 */
	name: string;

	/**
	 * Id of the breadcrumb
	 */
	id: string;

	/**
	 * Id of the parent breadcrumb
	 */
	parentId?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBreadcrumbClickEvent {
	/**
	 * Id of the clicked breadcrumb
	 */
	id: string;
}
