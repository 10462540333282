<ng-container id="container" *ngIf="notifications$ | async as notifications">
	<div class="flex-style">
		<div class="search-bar-style">
			<mat-icon id="search-icon" fontIcon="search" class="search-icon"></mat-icon>
			<input id="search-input" matInput class="search-input" placeholder="Search" />
		</div>

		<button id="contact-button" class="contact-shure" mat-button>Contact Shure</button>
		<button id="delete-button" class="delete-button-style" mat-button>Delete</button>
	</div>
	<div id="transloco-div" *transloco="let t" style="background: black; height: 100%">
		<table
			id="notification-table"
			style="overflow-x: scroll; background-color: black"
			mat-table
			[dataSource]="notifications"
			class="mat-elevation-z8 table-style"
		>
			<ng-container matColumnDef="markAsRead">
				<th id="from-header" mat-header-cell class="bold-heading" style="width: 300px" *matHeaderCellDef>
					From
				</th>

				<td
					id="email-cell"
					mat-cell
					[ngClass]="{ unread: !notification.readAt, read: notification.readAt }"
					*matCellDef="let notification"
				>
					{{ notification.sender.email }}
				</td>
			</ng-container>
			<ng-container matColumnDef="message">
				<th id="subject-header" mat-header-cell class="bold-heading" *matHeaderCellDef>Subject</th>
				<td
					id="message-cell"
					mat-cell
					[ngClass]="{ unread: !notification.readAt, read: notification.readAt }"
					*matCellDef="let notification"
				>
					{{ notification.message }}
				</td>
			</ng-container>
			<ng-container matColumnDef="sentAt">
				<th id="received-header" mat-header-cell class="bold-heading" *matHeaderCellDef>Received</th>
				<td
					id="received-cell"
					mat-cell
					[ngClass]="{ unread: !notification.readAt, read: notification.readAt }"
					*matCellDef="let notification"
				>
					{{ formatDateService.formatDate(notification.sentAt) }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr
				id="notification-row"
				mat-row
				class="highlight-hover"
				style="padding: 2%"
				*matRowDef="let notification; columns: displayedColumns"
				[class.hidden]="notification.archivedAt"
				(click)="openPopup(notification.id, isListPage)"
			></tr>
		</table>
	</div>
</ng-container>
