import { DetectedDevice } from './detected-device';

export type DeviceDetection = {
	claimable: boolean;
	detectedAt: string; // A DateTime in ISO-8601 format
	detectionToken: string;
	device: DetectedDevice;
	expiresAt: string; // A DateTime in ISO-8601 format
};

export type MacSerialNumber = {
	macAddress: string;
	serialNumber: string;
};

// The types enable the emitting of a common type for the claiming of devices whether the claim is
// by local network, or by mac/serial number. The original structure only emitted the results for
// local claiming, and the device information was wrapped in a DeviceDetection.  To "work in" support
// for claiming by mac/serial number, the MacSerialNumberDevice is created with the mac/serial fields
// at the same spot in the `device` field of the ClaimProgress.
// In theory, this could and should be used for unclaiming, but right now, the FE isn't displaying
// up-to-date unclaim progress.
export type MacSerialNumberDevice = { device: Pick<DetectedDevice, 'id' | 'macAddress' | 'serialNumber'> };
export type DeviceClaimResult = 'success' | 'error' | 'in-progress';
export type DeviceClaimProgress<T = DeviceDetection | MacSerialNumberDevice> = {
	done: boolean;
	stats: {
		totalToDo: number;
		success: number;
		inProgress: number;
		failed: number;
	};
	devices: {
		result: DeviceClaimResult;
		device: T;
	}[];
};
