/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This method deals with sorting the values when blanks are available in the list
 * @param aValue - string representation of column data
 * @param bValue - string representation of column data
 * @param isDescending - boolean representation of sort direction.
 * @param canUseCollator - boolean representate can we use collator on sorting.
 * @param locale - string representation of locale code
 * @returns
 *  - `0`  aValue is the same as bValue
 *  - `> 0` Sort aValue after bValue
 *  - `< 0` Sort aValue before bValue
 */
export function sortWithBlankValues(aValue: any, bValue: any, isDescending: boolean, locale?: string) {
	if (!isDescending) {
		return sortOrder(aValue, bValue, isDescending, locale);
	}
	return sortOrder(bValue, aValue, isDescending, locale);
}

function sortOrder(a: any, b: any, isDescending: boolean, locale?: string): any {
	if (a === b) {
		return 0;
	}
	// Put blank values at the top for descending order, and at the bottom for ascending order.
	else if (a === '' || a === undefined) {
		return isDescending ? -1 : 1;
	} else if (b === '' || b === undefined) {
		return isDescending ? 1 : -1;
	}

	// Use Intl.Collator for cultural-aware string comparison
	const collator = new Intl.Collator(locale, { sensitivity: 'variant' });

	// Compare non-blank values with collator
	return isDescending ? collator.compare(b, a) : collator.compare(a, b);
}

/**
 * Compares two specification strings to order them numerically based on a prefix and a custom suffix.
 * Strings should start with a number and end with the specified suffix for accurate comparisons.
 * If the string does not match the expected format, it is sorted as if it has the highest possible order.
 *
 * @param {string} valueA - The first specification string to compare.
 * @param {string} valueB - The second specification string to compare.
 * @param {string} suffix - The suffix that each string should end with to be considered valid.
 * @returns {number} - Returns -1 if valueA should come before valueB, 1 if valueA should come after valueB,
 * or 0 if they are equivalent as per locale comparison.
 */
export function sortWithAlphaNumericValues(valueA: any, valueB: any, suffix: string) {
	// Special value for sorting items with 'unassigned' or non-numeric text
	const specialSortValue = Number.MAX_SAFE_INTEGER;

	// Helper function to extract the numeric part from the specification
	const extractNumber = (value: any) => {
		const pattern = new RegExp(`^(\\d+)-${suffix}$`);
		const matches = value && value.match(pattern);
		return matches ? parseInt(matches[1], 10) : specialSortValue;
	};

	const aValue = extractNumber(valueA);
	const bValue = extractNumber(valueB);

	// Compare the extracted numbers, placing special characters below numbers
	if (aValue < bValue) {
		return -1;
	} else if (aValue > bValue) {
		return 1;
	}

	// Use locale-based comparison if numeric values are the same
	return valueA.localeCompare(valueB);
}

/**
 *	sorts the grid based on the user's locale
 * @param locale - string representation of user's selected locale
 */
export function intlCollatorSort(locale?: string): any {
	const collator = new Intl.Collator(locale, { sensitivity: 'variant' });
	return (valueA: any, valueB: any) => collator.compare(valueA, valueB);
}

/**
 *	filters the grid based on the user's locale
 * @param locale - string representation of user's selected locale
 */
export function intlCollatorFilter(locale?: string) {
	const collator = new Intl.Collator(locale, { sensitivity: 'variant', usage: 'search' });
	return (filterText: any, cellValue: any) => collator.compare(filterText, cellValue) === 0;
}
