export interface DetectedDevice {
	id: string;
	hardwareId: string;
	category: string;
	model: string;
	serialNumber: string;
	macAddress: string;
	firmwareVersion: string;
	firmwareValid: boolean;
	name: string;
	ipAddress: string;
	interface: { interfaceId: number; version: string; beta: boolean };
}
