/* eslint-disable @typescript-eslint/no-empty-function */
export class MockAngularMediaMatcher {
	matchMedia(query: string) {
		return {
			matches: false,
			media: query,
			addListener: () => {},
			addEventListener: () => {},
			removeListener: () => {},
			onchange: () => {}
		};
	}
}
