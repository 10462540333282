import { FetchResult } from '@apollo/client/core';

export class GqlUtils {
	public static isSuccess<TData>(result: FetchResult<TData>): boolean {
		if ('data' in result && result.data !== null) {
			return true;
		}
		return false;
	}

	public static decomposeGQLError(error: FetchResult<unknown>): string {
		return JSON.stringify(error);
	}

	public static decomposeHTTPError(error: { message: string }): string {
		return error?.message;
	}
}
