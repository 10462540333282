import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
// import { DomSanitizer } from '@angular/platform-browser';

/**
 * Global provider for material icons using shure fonts for the application.
 *
 * **This will contain icons only used by this application and should not be included within
 * other contexts.**
 */
@NgModule({
	imports: [MatIconModule]
})
export class MaterialIconsModule {
	constructor(private readonly iconRegistry: MatIconRegistry /*, sanitizer: DomSanitizer*/) {
		this.iconRegistry.registerFontClassAlias('prism-mat', 'prism-mat-sharp');
		this.iconRegistry.setDefaultFontSetClass('prism-mat-sharp');

		/*this.iconRegistry.addSvgIcon(
			'sh-icon-route',
			sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sh-icon-route.svg')
		);
		this.iconRegistry.addSvgIcon(
			'sh-led-bad',
			sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sh-led-bad.svg')
		);
		this.iconRegistry.addSvgIcon(
			'sh-led-good',
			sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sh-led-good.svg')
		);
		this.iconRegistry.addSvgIcon(
			'sh-led-ok',
			sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sh-led-ok.svg')
		);*/
	}
}
