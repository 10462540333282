/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiMailEndpointService } from './services/api-mail-endpoint.service';
import { ApiNotificationsEndpointService } from './services/api-notifications-endpoint.service';
import { ApiCorsService } from './services/api-cors.service';
import { ApiApplicationsEndpointService } from './services/api-applications-endpoint.service';
import { ApiTemplatesEndpointService } from './services/api-templates-endpoint.service';
import { ApiSmsEndpointService } from './services/api-sms-endpoint.service';
import { ApiPushEndpointService } from './services/api-push-endpoint.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiMailEndpointService,
    ApiNotificationsEndpointService,
    ApiCorsService,
    ApiApplicationsEndpointService,
    ApiTemplatesEndpointService,
    ApiSmsEndpointService,
    ApiPushEndpointService,
    ApiConfiguration
  ],
})
export class NotificationsListApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<NotificationsListApiModule> {
    return {
      ngModule: NotificationsListApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: NotificationsListApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('NotificationsListApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
