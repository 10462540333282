/* eslint-disable max-classes-per-file */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	Input,
	QueryList,
	ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { StickyButtonsComponent } from '../sticky-button-group/sticky-buttons.component';

/**
 * Common interface for NavTab or NavCharm
 */
interface NavItem {
	id: string;
	label: string;
	routerLink: string;
	disabled: boolean;
	icon?: string | undefined;
}

/**
 * ```<sh-nav-tab>``` represents a label only tab.
 */
@Component({
	selector: 'sh-nav-tab',
	template: ''
})
export class NavTabComponent implements NavItem {
	@Input() public id!: string;
	@Input() public label!: string;
	@Input() public routerLink!: string;
	@Input() public disabled!: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public icon?: any;
}

/**
 * ```<sh-nav-charm>``` represents a icon only tab.
 */
@Component({
	selector: 'sh-nav-charm',
	template: ''
})
export class NavCharmComponent implements NavItem {
	@Input() public id!: string;
	@Input() public label!: string;
	@Input() public icon!: string;
	@Input() public routerLink!: string;
	@Input() public disabled!: boolean;
}

/**
 * ```<sh-nav-tab-group>``` is a 'responsive' wrapper for
 * [mat-tab-nav-bar](https://material.angular.io/components/tabs/overview#tabs-and-navigation), where ```<sh-nav-tab>```
 * and ```<sh-nav-charm>``` are merged into a [mat-menu](https://material.angular.io/components/menu/overview) when set in
 * collapsed mode.
 *
 * ```html
 * <sh-nav-button-group>
 * 	<sh-nav-button
 * 		label="Mute"
 * 		icon="sh-muteall"
 * 		(click)="console.log('clicked')">
 * 	</sh-nav-button>
 * 	<!-- additional content -->
 *
 * 	<sh-sticky-button-group>
 * 		<button
 * 			mat-raised-button
 * 			(click)="deploy()">
 * 			Deploy
 * 		</button>
 * 	</sh-sticky-button-group>
 * </sh-nav-button-group>
 * ```
 */
@Component({
	selector: 'sh-nav-tab-group',
	templateUrl: './navigation-tabs.component.html',
	styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabGroupComponent implements AfterViewInit {
	/**
	 * Projected ```<sh-nav-tab>```'s to be rendered.
	 */
	public get tabs(): NavTabComponent[] {
		return this._tabs.toArray();
	}
	@ContentChildren(NavTabComponent) private readonly _tabs!: QueryList<NavTabComponent>;

	/**
	 * Projected ```<sh-nav-charm>```'s to be rendered.
	 */
	public get charms(): NavTabComponent[] {
		return this._charms.toArray();
	}
	@ContentChildren(NavCharmComponent) private readonly _charms!: QueryList<NavCharmComponent>;

	/**
	 * Projected ```<sh-sticky-buttons>``` to be rendered.
	 */
	@ContentChild(StickyButtonsComponent) public readonly stickyButtons!: StickyButtonsComponent;

	/**
	 * Template reference to the container elements for rendered tabs.
	 */
	@ViewChild('tabsRef') private readonly tabsElementRef!: ElementRef<HTMLElement>;

	/**
	 * Template reference to the container elements for rendered charms.
	 */
	@ViewChild('charmsRef') private readonly charmsElementRef!: ElementRef<HTMLElement>;

	/**
	 * Template reference to the container elements for rendered sticky buttons
	 */
	@ViewChild('stickyButtonsRef') private readonly stickyButtonsRef!: ElementRef<HTMLElement>;

	/**
	 * 	Template reference to the container elements for menu
	 */
	@ViewChild('menuRef', { read: ElementRef }) private readonly menuRef!: ElementRef<HTMLElement>;

	/**
	 * Currently active item based on url.
	 */
	public readonly activeNavItem$: Observable<NavItem> = this.router.events.pipe(
		startWith(new NavigationEnd(0, this.router.url, '')),
		filter((event): event is NavigationEnd => event instanceof NavigationEnd),
		map((event) => {
			const active = this.navItems.find((i) => event.urlAfterRedirects.includes(i.routerLink));
			return active || this.navItems[0];
		})
	);

	/**
	 * Collapsed state.
	 */
	public get collapsed(): boolean {
		return this._collapsed;
	}
	private _collapsed = false;

	/**
	 * Uncollapsed width of the ```<sh-nav-tab-group>```.
	 */
	public get fullWidth(): number {
		/**
		 * this.stickyButtonsWidth will be 0 in the ngAfterViewInit hook, so first query
		 * we need to figure out the with of all the projected content
		 */
		if (!this._fullWidth) {
			this._fullWidth = this.tabsWidth + this.charmsWidth + this.stickyButtonsWidth;
		}

		return this._fullWidth;
	}
	private _fullWidth!: number;

	/**
	 * Current width of the ```<sh-nav-tab-group>```.
	 */
	public get collapsedWidth(): number {
		return this.stickyButtonsWidth + this.menuWidth;
	}

	/**
	 * Width of all ```<sh-nav-tab>```.
	 */
	private get tabsWidth(): number {
		return this.tabsElementRef ? this.tabsElementRef.nativeElement.clientWidth : 0;
	}

	/**
	 * Width of all ```<sh-nav-charm>```.
	 */
	private get charmsWidth(): number {
		return this.charmsElementRef ? this.charmsElementRef.nativeElement.clientWidth : 0;
	}

	/**
	 * Width of the ```<sh-sticky-buttons>```.
	 */
	private get stickyButtonsWidth(): number {
		return this.stickyButtonsRef ? this.stickyButtonsRef.nativeElement.clientWidth : 0;
	}

	/**
	 * Width of collpased menu
	 */
	private get menuWidth(): number {
		return this.menuRef ? this.menuRef.nativeElement.clientWidth : 0;
	}

	/**
	 * Combined list of tabs & charms.
	 */
	private get navItems(): NavItem[] {
		return [...this.tabs, ...this.charms];
	}

	constructor(private readonly cdr: ChangeDetectorRef, private readonly router: Router) {}

	public ngAfterViewInit(): void {
		/**
		 * Run change detection to avoid expression changed error when
		 * using *ngTemplateOutlet="stickyButtons.template"
		 */
		this.cdr.detectChanges();
	}

	/**
	 * Toggle the collapsed state
	 */
	public toggleCollapsed(collapsed: boolean): void {
		this._collapsed = collapsed;
		this.cdr.detectChanges();
	}
}
