import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

import { DeviceModel } from '@shure/shared/models';

import { DeviceImageModule } from '../device-image';

@Component({
	selector: 'sh-device-identify',
	templateUrl: './device-identify.component.html',
	styleUrls: ['./device-identify.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		DeviceImageModule,
		FlexLayoutModule,
		MatTooltipModule,
		TranslocoModule
	]
})
export class DeviceIdentifyComponent {
	@Input() public readonly!: boolean;
	@Input() public deviceModel!: DeviceModel;
	@Input() public isVirtual!: boolean;
	@Input() public isPasswordEnabled!: boolean;
	@Input() public isIdentifying!: boolean;
	@Output() public toggleIdentify = new EventEmitter<boolean>();

	public identifyToggle($event: Event): void {
		this.toggleIdentify.emit(!this.isIdentifying);
		$event.stopPropagation();
	}
}
