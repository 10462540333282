import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, takeUntil } from 'rxjs';

/**
 * ```sh-chip-input``` is a view component for inputting in chip components.
 *
 * Chip input is bascially a material chip with an input field showing when chip has focus.
 * Without focus, it shows the value of chip input
 *
 * An exemplary use of the chip input component
 *
 * ```html
 * 		<mat-chip-list>
 *			<sh-chip-input
 *				[formControl]="nameFormControl"
 *				[autofilled]="autofilled"
 *				[required]="required">
 *			</sh-chip-input>
 *		</mat-chip-list>
 * ```
 */
@Component({
	selector: 'sh-chip-input',
	templateUrl: './chip-input.component.html',
	styleUrls: ['./chip-input.component.scss'],
	providers: [
		{
			provide: MatFormFieldControl,
			useExisting: ChipInputComponent
		}
	]
})
export class ChipInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
	/**
	 * Id incrementer allowing for multiple <sh-chip-input> in same view.
	 * @ignore internal
	 */
	public static nextId = 0;

	/**
	 * @ignore internal-ish
	 */
	@Input()
	@HostBinding()
	public readonly id = `sh-chip-input-${ChipInputComponent.nextId++}`;

	/**
	 * @ignore internal
	 */
	@HostBinding('attr.aria-describedby')
	public describedBy = '';

	/**
	 * @ignore internal
	 */
	@HostBinding('class.floating')
	public get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	/**
	 * Placeholder text.
	 */
	@Input()
	public set placeholder(placeholder: string) {
		this._placeholder = placeholder;
		this.stateChanges.next();
	}
	public get placeholder(): string {
		return this._placeholder;
	}

	/**
	 * Enable auto fill.
	 */
	@Input()
	public set autofilled(autofilled: boolean) {
		this._autoFilled = autofilled;
		this.stateChanges.next();
	}
	public get autofilled(): boolean {
		return this._autoFilled;
	}

	/**
	 * Disable input field.
	 */
	@Input()
	public set disabled(disabled: boolean) {
		this._disabled = disabled;
		this.stateChanges.next();
	}
	public get disabled(): boolean {
		return this._disabled;
	}

	/**
	 * Set field as required
	 */
	@Input()
	public set required(required: boolean) {
		this._required = required;
		this.stateChanges.next();
	}
	public get required(): boolean {
		return this._required;
	}

	/**
	 * @ignore internal
	 */
	public set value(chip: string) {
		this._value = chip;

		if (this.onChange) {
			this.onChange(this._value);
			this.onTouched();
		}
		this.stateChanges.next();
	}
	public get value(): string {
		return this._value;
	}

	/**
	 * @ignore internal
	 */
	public set focused(focused: boolean) {
		this._focused = focused;
		this.stateChanges.next();
	}
	public get focused(): boolean {
		return this._focused;
	}

	/**
	 * @ignore internal
	 */
	public get empty(): boolean {
		return this.value === undefined || this.value === null || this.value === '';
	}

	/**
	 * @ignore internal
	 */
	public get errorState(): boolean {
		return this.ngControl ? this.ngControl.errors !== null && !!this.ngControl.touched : false;
	}

	/**
	 * @ignore internal
	 */
	public editMode = false;

	/**
	 * @ignore internal
	 */
	public controlType = 'sh-chip-input';

	/**
	 * @ignore internal
	 */
	public readonly stateChanges = new Subject<void>();

	private _value = '';
	private _placeholder = '';
	private _autoFilled = false;
	private _focused = false;
	private _required = false;
	private _disabled = false;

	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly focusMonitor: FocusMonitor,
		private readonly elementRef: ElementRef<HTMLElement>,
		@Optional() @Self() public ngControl: NgControl
	) {
		if (this.ngControl !== null) {
			/* istanbul ignore next */

			this.ngControl.valueAccessor = this;
		}

		focusMonitor
			.monitor(this.elementRef.nativeElement, true)
			.pipe(takeUntil(this.destroy$))
			.subscribe((origin) => {
				this.focused = !!origin;
				this.stateChanges.next();
			});
	}

	public ngOnDestroy(): void {
		this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
		this.stateChanges.complete();
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * @ignore internal
	 */
	public onInputChange(event: Event): void {
		this.value = (<HTMLInputElement>event.target).value;
	}

	/**
	 * @ignore internal
	 */
	public writeValue(chip: string): void {
		this.value = chip;
	}

	/**
	 * @ignore internal
	 */
	public registerOnChange(fn: (chip: string) => void): void {
		this.onChange = fn;
	}

	/**
	 * @ignore internal
	 */
	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * @ignore internal
	 */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	/**
	 * @ignore internal
	 */
	public setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	/**
	 * @ignore internal
	 */
	public onContainerClick(event: MouseEvent): void {
		if ((<Element>event.target).tagName.toLowerCase() !== 'input') {
			this.focused = false;
			return;
		}

		this.elementRef.nativeElement.querySelector('input')?.focus();
	}

	public setEditMode(editMode: boolean): void {
		this.editMode = editMode;
	}

	public onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.editMode = false;
			return;
		}

		event.stopImmediatePropagation();
	}

	private onChange = (_value: string): void => {
		/* NOOP */
	};
	private onTouched = (): void => {
		/* NOOP */
	};
}
