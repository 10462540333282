import {
	FirmwareUpdateProgress,
	FirmwareUpdateStage,
	FirmwareUpdateStatus,
	InventoryDevice,
	MissingFeatureType,
	ProxiedTransmitterForInventory,
	ProxiedTransmittersForInventory
} from '@shure/shared/models';

import { InventoryDeviceFragment, ProxiedTransmitterStatusFragment } from '../../graphql/generated/cloud-sys-api';
import { DeviceStatusService } from '../device-status/device-status.service';
import {
	mapDeviceModelFromSysApi,
	mapDeviceStateFromSysApi,
	mapFeatureValueFromSysApi,
	mapFirmwarePackagesFromSysApi
} from '../shared/device';

export function mapInventoryDeviceFromSysApi(
	device: InventoryDeviceFragment,
	statusService: DeviceStatusService
): InventoryDevice {
	const name = mapFeatureValueFromSysApi(device.features.name?.name);

	const controlNetwork = {
		ipAddress: device.features.controlNetwork
			? mapFeatureValueFromSysApi(device.features.controlNetwork.interface.ipAddress)
			: mapFeatureValueFromSysApi(device.protocol.dmp?.ipAddress),
		macAddress: mapFeatureValueFromSysApi(device.features.controlNetwork?.interface.macAddress),
		isMissing: !device.description.features.controlNetwork.supported
	};

	const firmware = {
		version: mapFeatureValueFromSysApi(device.features.firmware?.version),
		valid: device.features.firmware?.valid || true,
		isUpToDate: true,
		// TODO: LBF 7/12/2023 - firmware update not supported yet
		// (device.features.firmware?.valid && device.features.availablePackages?.primaryPackages?.length === 0) ||
		// true,
		isMissing: !device.description.features.firmware.supported
	};

	const identify = {
		identifying: device.features.identify?.identifying || false,
		isMissing: !device.description.features.identify.supported
	};

	const serialNumber = {
		serialNumber: mapFeatureValueFromSysApi(device.features.serialNumber?.serialNumber),
		isMissing: !device.description.features.serialNumber.supported
	};

	const updateProgress: FirmwareUpdateProgress & MissingFeatureType = {
		package: {
			key: device.features.updateProgress?.firmwarePackageKey ?? '',
			version: device.features.updateProgress?.firmwarePackageVersion ?? '0.0.0.0'
		},
		percentComplete: device.features.updateProgress?.deviceProgressPercentage ?? 100,
		stage: device.features.updateProgress?.updateStage ?? FirmwareUpdateStage.Idle,
		status: device.features.updateProgress?.updateStatus ?? FirmwareUpdateStatus.Success,
		isMissing: !device.description.features.updateProgress.supported
	};

	const doublestuffProxiedTransmitters: ProxiedTransmittersForInventory & MissingFeatureType = {
		...mapProxiedTransmitters(device),
		isMissing: !device.description.features.doublestuffProxiedTransmitters.supported
	};

	const availablePackages = {
		primaryPackages: mapFirmwarePackagesFromSysApi(device.features.availablePackages),
		isMissing: !device.description.features.availablePackages.supported
	};

	const unaugmentedDevice: Omit<InventoryDevice, 'conditions'> = {
		id: device.id,
		name,
		model: mapDeviceModelFromSysApi(device.interface.model ?? ''),
		virtual: device.virtual,
		deviceState: mapDeviceStateFromSysApi(device.status),
		features: {
			controlNetwork,
			firmware,
			identify,
			serialNumber,
			updateProgress,
			doublestuffProxiedTransmitters,
			availablePackages
		}
	};

	return {
		...unaugmentedDevice,
		conditions: statusService.conditionsFor(<InventoryDevice>unaugmentedDevice)
	};
}

function mapProxiedTransmitters(device: InventoryDeviceFragment): ProxiedTransmittersForInventory {
	return {
		mic1: mapProxiedTransmitter(device.features.doublestuffProxiedTransmitters?.mic1),
		mic2: mapProxiedTransmitter(device.features.doublestuffProxiedTransmitters?.mic2)
	};
}

function mapProxiedTransmitter(
	transmitter?: ProxiedTransmitterStatusFragment | null
): ProxiedTransmitterForInventory | null {
	if (!transmitter) {
		return null;
	}

	const batteryLevel = (transmitter.batteryLevelPercentage ?? Number.NaN) / 100;

	return {
		batteryLevel
	};
}
