import { Inject, Injectable } from '@angular/core';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppEnvironment, APP_ENVIRONMENT } from '@shure/shared/models';

import { AnalyticsData } from './analytics-data';

export interface DataObjectType {
	name?: string;
	userEmail?: string;
	role?: string;
	newRole?: string;
	userId?: string;
	selectedOrg?: string;
	selectedOrgId?: string;
	description?: string;
}

@Injectable()
export class AnalyticsService {
	public environment!: AppEnvironment;
	private appClientName!: string;
	private appEdition!: string;
	public mixPanel: Mixpanel;
	constructor(private deviceService: DeviceDetectorService, @Inject(APP_ENVIRONMENT) appEnv: AppEnvironment) {
		this.environment = appEnv;
		this.mixPanel = mixpanel;
	}

	/**
	 * Set the client name
	 * @param appClientName - String representation of client name like browser
	 */
	public setClientName(appClientName: string): void {
		this.appClientName = appClientName;
	}

	/**
	 * Set the app edition
	 * @param appEdition - String representation of app_edition like Org Portal
	 */
	public setAppEdition(appEdition: string): void {
		this.appEdition = appEdition;
	}

	/**
	 * Set the analytic data from application
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public analyticDataCollector(event: string, data: DataObjectType): void {
		this.submitToAnalyticsPlatform(event, this.dataFormatter(data));
	}

	/**
	 * Add the basic details to the event data
	 * @param data - DataObjectType representation of data from event
	 */
	public dataFormatter(data: DataObjectType): AnalyticsData {
		const oktaTokenStorage = JSON.parse(window.localStorage.getItem('okta-token-storage') || '{}');
		const mixpanelData: AnalyticsData = {
			...data,
			userId: oktaTokenStorage.idToken ? oktaTokenStorage.idToken.claims.sub : null,
			sessionId: oktaTokenStorage.idToken ? oktaTokenStorage.idToken.claims.jti : null,
			timestamp: new Date().getUTCDate(),
			appEdition: this.appEdition,
			productVersion: this.environment.appVersion,
			appClientName: this.appClientName,
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			browserLanguage: navigator.language,
			deviceType: this.deviceService.getDeviceInfo().device,
			browserPlatformAndVersion: `${this.deviceService.browser}_${this.deviceService.browser_version}`,
			osVersion: this.deviceService.os_version,
			optInState: true
		};
		return mixpanelData;
	}

	/**
	 * Submit the Analytic data
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public submitToAnalyticsPlatform(event: string, data: AnalyticsData): void {
		if (this.environment && this.environment.enableMixPanel && this.environment.mixpanelProjectToken) {
			//Commenting the mixpanel recordings as it is not part of V1
			//this.mixPanel.track(event, data);
		}
	}
}
