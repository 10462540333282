import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { AvailableDevice, DeviceCategory, DeviceModel } from '@shure/shared/models';

import { Mxa920DeviceBehaviorPluginBase } from './mxa920.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxa920DesignerDeviceBehaviorPlugin extends Mxa920DeviceBehaviorPluginBase {
	private readonly available: AvailableDevice[] = [
		{
			model: DeviceModel.MXA920R,
			category: DeviceCategory.Microphone
		},
		{
			model: DeviceModel.MXA920S,
			category: DeviceCategory.Microphone
		}
	];

	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
