/* eslint-disable max-classes-per-file */
import {
	ChangeDetectorRef,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChild
} from '@angular/core';

import { StickyButtonsComponent } from '../sticky-button-group/sticky-buttons.component';

/**
 * ```<sh-nav-button>``` represents a 'collapsable' button.
 */
@Component({
	selector: 'sh-nav-button',
	template: ''
})
export class NavButtonComponent {
	@Input() public id!: string;
	@Input() public label!: string;
	@Input() public icon!: string;
	@Input() public color!: string;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() public readonly click = new EventEmitter<void>();
}

/**
 * ```<sh-nav-button-group>``` is a 'responsive' button group, where all ```<sh-nav-button>``` will be rendered as
 * ```mat-raised-button``` and ```mat-fab``` when collapsed and allows to project sticky, non-collapsable, buttons
 * via the ```<sh-stick-button-group>``` component.
 *
 * ```html
 * 	<sh-nav-tab-group>
 * 		<sh-nav-tab
 * 			label="Routing">
 * 		</sh-nav-tab>
 * 		<!-- additional content -->
 *
 * 		<sh-sticky-button-group>
 * 			<button
 * 				mat-raised-button
 * 				(click)="deploy()">
 * 				Deploy
 * 			</button>
 * 		</sh-sticky-button-group>
 *
 * 	</sh-nav-tab-group>
 * ```
 */
@Component({
	selector: 'sh-nav-button-group',
	templateUrl: './navigation-button-group.component.html',
	styleUrls: ['./navigation-button-group.component.scss']
})
export class NavigationButtonGroupComponent {
	public get buttons(): NavButtonComponent[] {
		return this._buttons.toArray();
	}
	@ContentChildren(NavButtonComponent) private readonly _buttons!: QueryList<NavButtonComponent>;

	/**
	 * Projected ```<sh-sticky-buttons>``` to be rendered.
	 */
	@ContentChild(StickyButtonsComponent) public readonly stickyButtons!: StickyButtonsComponent;

	/**
	 * Template reference to the container elements for rendered buttons.
	 */
	@ViewChild('buttonsGroupRef') private readonly buttonsGroupRef!: ElementRef<HTMLElement>;

	/**
	 * Template reference to the container elements for rendered sticky buttons
	 */
	@ViewChild('stickyButtonsRef') private readonly stickyButtonsRef!: ElementRef<HTMLElement>;

	/**
	 * Collapsed state.
	 */
	public get collapsed(): boolean {
		return this._collapsed;
	}
	private _collapsed = false;

	/**
	 * Uncollapsed width of the ```<sh-nav-button-group>```.
	 */
	public get fullWidth(): number {
		/**
		 * this.stickyButtonsWidth will be 0 in the ngAfterViewInit hook, so first query
		 * we need to figure out the with of all the projected content
		 */
		if (!this._fullWidth) {
			this._fullWidth = this.buttonsWidth + this.stickyButtonsWidth;
		}

		return this._fullWidth;
	}
	private _fullWidth!: number;

	/**
	 * Width of all ```<sh-nav-button>```.
	 */
	private get buttonsWidth(): number {
		return this.buttonsGroupRef ? this.buttonsGroupRef.nativeElement.clientWidth : 0;
	}

	/**
	 * Width of the ```<sh-sticky-button-group>```
	 */
	private get stickyButtonsWidth(): number {
		return this.stickyButtonsRef ? this.stickyButtonsRef.nativeElement.clientWidth : 0;
	}

	constructor(private readonly cdr: ChangeDetectorRef) {}

	/**
	 * Toggle the collapsed state
	 */
	public toggleCollapsed(collapsed: boolean): void {
		this._collapsed = collapsed;
		this.cdr.detectChanges();
	}
}
