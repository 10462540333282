/* istanbul ignore file */
// need empty line here for 'istanbul ignore file' to work

import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { map, Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs';

import { DeviceState, UpdateResponse, UpdateState } from '@shure/shared/models';
import { DeviceModel } from '@shure/shared/models';

import { MockupInventoryDevice, MockupDeviceSerialized, MockupDevice } from './inventory.device';

const STORAGE_KEY = 'dai-mockup-inventory';

interface MockupInventoryStorage {
	devices: MockupDeviceSerialized[];
}

@Injectable()
export class MockupInventory {
	private devices = new Map<string, MockupInventoryDevice>();
	private deviceSubscriptions = new Subscription();

	public devices$ = new ReplaySubject<MockupInventoryDevice[]>(1);

	constructor(private storageService: SessionStorageService) {
		this.loadData();
	}

	public getDevice$(deviceId: string): Observable<MockupInventoryDevice> {
		const device = this.devices.get(deviceId);
		if (device) {
			return device.device$;
		}

		return throwError(() => new Error('Device not found'));
	}

	public getDevicesById$(deviceIds: string[]): Observable<MockupInventoryDevice[]> {
		return this.devices$.pipe(map((devices) => devices.filter((device) => deviceIds.includes(device.getId()))));
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		const device = this.devices.get(deviceId);
		if (!device) {
			return of({ state: UpdateState.Error, error: 'Device not found' });
		}
		device.setIdentify(identify);
		return of({ state: UpdateState.Done });
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		const device = this.devices.get(deviceId);
		if (!device) {
			return of({ state: UpdateState.Error, error: 'Device not found' });
		}

		device.setMute(mute);
		return of({ state: UpdateState.Done });
	}

	public updateDevice(deviceId: string, update: Partial<MockupDevice>): Observable<UpdateResponse<void, string>> {
		const device = this.devices.get(deviceId);

		if (!device) {
			return of({ state: UpdateState.Error, error: 'Device not found' });
		}

		device.update(update);
		this.update({ device: true });
		return of({ state: UpdateState.Done });
	}

	public rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>> {
		const device = this.devices.get(deviceId);

		if (!device) {
			return of({ state: UpdateState.Error, error: 'Device not found' });
		}
		return of({ state: UpdateState.Done });
	}

	public updateFirmware(deviceId: string): Observable<UpdateResponse<void, string>> {
		const device = this.devices.get(deviceId);

		if (!device) {
			return of({ state: UpdateState.Error, error: 'Device not found' });
		}
		return of({ state: UpdateState.Done });
	}

	private addInventoryDevice(device: MockupInventoryDevice): MockupInventoryDevice {
		this.addDeviceInternal(device);
		return device;
	}

	private update(args: { device?: boolean; room?: boolean; route?: boolean }): void {
		if (args.device) {
			this.devices$.next(Array.from(this.devices.values()));
		}
		this.saveData();
	}

	private loadData(): void {
		const savedData = this.storageService.retrieve(STORAGE_KEY);
		if (savedData) {
			const savedDataObj = <MockupInventoryStorage>JSON.parse(savedData);

			savedDataObj.devices
				.map((device) => MockupInventoryDevice.createFromSerialized(device))
				.forEach((device) => this.addDeviceInternal(device));

			this.update({ room: true, device: true });
		} else {
			this.storageService.clear(STORAGE_KEY);
			this.generateData();
		}
	}

	private saveData(): void {
		const data: MockupInventoryStorage = {
			devices: Array.from(this.devices.values()).map((device) => device.serialize())
		};
		this.storageService.store(STORAGE_KEY, JSON.stringify(data));
	}

	private generateData(): void {
		[...Array(2).keys()].forEach(() => {
			this.addInventoryDevice(MockupInventoryDevice.createP300(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createP300(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMXWAPXD2(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMXWAPXD2(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMxa920(DeviceModel.MXA920S, false, DeviceState.Online));
			this.addInventoryDevice(
				MockupInventoryDevice.createMxa920(DeviceModel.MXA920S, false, DeviceState.Offline)
			);
			this.addInventoryDevice(MockupInventoryDevice.createMxa920(DeviceModel.MXA920R, false, DeviceState.Online));
			this.addInventoryDevice(
				MockupInventoryDevice.createMxa920(DeviceModel.MXA920R, false, DeviceState.Offline)
			);
			this.addInventoryDevice(
				MockupInventoryDevice.createMXA710(DeviceModel.MXA7102FT, false, DeviceState.Online)
			);
			this.addInventoryDevice(
				MockupInventoryDevice.createMXA710(DeviceModel.MXA7102FT, false, DeviceState.Offline)
			);
			this.addInventoryDevice(
				MockupInventoryDevice.createMXA710(DeviceModel.MXA7104FT, false, DeviceState.Online)
			);
			this.addInventoryDevice(
				MockupInventoryDevice.createMXA710(DeviceModel.MXA7104FT, false, DeviceState.Offline)
			);
			this.addInventoryDevice(MockupInventoryDevice.createANIUSB(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createANIUSB(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMXA901R(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMXA901R(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMXA901S(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMXA901S(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMXA902(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMXA902(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createIMXR(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createIMXR(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMXN5C(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMXN5C(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createMCR(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createMCR(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createAD600(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createAD600(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createADTD(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createADTD(false, DeviceState.Offline));
			this.addInventoryDevice(MockupInventoryDevice.createADTQ(false, DeviceState.Online));
			this.addInventoryDevice(MockupInventoryDevice.createADTQ(false, DeviceState.Offline));
		});
	}

	private addDeviceInternal(device: MockupInventoryDevice): void {
		this.devices.set(device.getId(), device);
		this.deviceSubscriptions.add(
			device.device$.subscribe((_device) => {
				this.update({ device: true });
			})
		);
	}
}
