export type DeletePendingClaimResult = {
	id: string;
	error: {
		code: string;
		message: string;
	} | null;
};

export type DeletePendingClaimBatchResult = {
	total: number;
	numSuccess: number;
	numError: number;
	errors: string[];
};
