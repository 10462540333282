import { PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';

import {
	ApolloCacheManager,
	APOLLO_TYPE_POLICY_PROVIDERS,
	SysApiTypePolicyProvider
} from '@shure/shared/angular/apollo';

import { DaiDeviceClaimingDiscoveryService } from './domains/claiming/dai-device-claiming-discovery.service';
import { DaiDeviceClaimingService } from './domains/claiming/dai-device-claiming.service';
import { DEVICE_DISCOVERY_CLAIMING_SERVICE } from './domains/claiming/device-claiming-discovery.service';
import { DeviceClaimingApiService } from './domains/claiming/device-claiming.service';
import { DaiDetectionTokenService } from './domains/detection/dai-detection-token.service';
import { DetectionTokenApiService } from './domains/detection/detection-token.service';
import { DaiDevicePropertyPanelService } from './domains/device-property-panel/dai-device-property-panel.service';
import { DevicePropertyPanelApiService } from './domains/device-property-panel/device-property-panel-api.service';
import { DeviceStatusService } from './domains/device-status/device-status.service';
import { DaiDeviceDiscoveryService } from './domains/inventory/dai-device-discovery.service';
import { DaiInventoryDevicesService } from './domains/inventory/dai-inventory-devices.service';
import { DeviceDiscoveryApiService } from './domains/inventory/device-discovery.service';
import { InventoryDevicesApiService } from './domains/inventory/inventory-devices.service';
import { CloudDeviceService } from './domains/shared/device/cloud-device.service';
import { DaiCloudDeviceService } from './domains/shared/device/dai-cloud-device.service';
import { SysApiConnectionService } from './services/sys-api-connection.service';

@NgModule({
	imports: [ApolloModule, HttpClientModule]
})
export class DaiServicesCloudSysapiModule {
	public static forRoot(): ModuleWithProviders<DaiServicesCloudSysapiModule> {
		return {
			ngModule: DaiServicesCloudSysapiModule,
			providers: [
				ApolloCacheManager,
				SysApiConnectionService,
				{
					provide: APOLLO_TYPE_POLICY_PROVIDERS,
					multi: true,
					useClass: SysApiTypePolicyProvider
				},
				{
					provide: APOLLO_NAMED_OPTIONS,
					useFactory(sysApiConnectionService: SysApiConnectionService): NamedOptions {
						const options: NamedOptions = {
							sysApi: sysApiConnectionService.createApolloClientOptions()
						};
						return options;
					},
					deps: [SysApiConnectionService]
				},
				{
					provide: DEVICE_DISCOVERY_CLAIMING_SERVICE,
					useClass: DaiDeviceClaimingDiscoveryService
				},
				{ provide: InventoryDevicesApiService, useExisting: DaiInventoryDevicesService },
				{ provide: DevicePropertyPanelApiService, useExisting: DaiDevicePropertyPanelService },
				{ provide: DetectionTokenApiService, useExisting: DaiDetectionTokenService },
				{ provide: DeviceClaimingApiService, useExisting: DaiDeviceClaimingService },
				{ provide: DeviceDiscoveryApiService, useExisting: DaiDeviceDiscoveryService },
				{ provide: CloudDeviceService, useClass: DaiCloudDeviceService },
				DeviceStatusService,
				PercentPipe
			]
		};
	}
}
