<!-- sidenav content goes here -->
<a class="shure-cloud-logo-container" id="sh-device-manager-shure-cloud-logo-container" [href]="shureCloudUri">
	<img
		class="shure-cloud-image"
		id="sh-device-manager-shure-cloud-logo"
		src="../../../../../../assets/images/shure_logo_icon.svg"
	/>
	<span class="shure-cloud-text" id="sh-device-manager-shure-cloud-text">ShureCloud</span>
</a>

<mat-divider>&nbsp;</mat-divider>

<div class="device-management-header" id="sh-device-manager-app-name-container" *transloco="let t">
	{{ t('device-management') }}
</div>

<div class="nav-items-list">
	<div class="nav-item-container" *ngFor="let navItem of navItems">
		<button
			*transloco="let t"
			class="nav-item {{ navItem.selected ? 'nav-item-selected' : '' }}"
			id="{{ navItem.buttonId }}"
			[disabled]="navItem.disabled"
			mat-button
			[routerLink]="navItem.route"
		>
			{{ t(navItem.label) }}
			<mat-icon fontSet="prism-mat" fontIcon="{{ navItem.icon }}" class="{{ navItem.icon }}Icon"></mat-icon>
			<ng-container *ngIf="navItem.count$ as count$">
				<span id="{{ navItem.buttonId }}-count" *ngIf="count$ | async">({{ count$ | async }})</span>
			</ng-container>
		</button>
	</div>
</div>
