import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EMPTY, Observable, Subject, catchError, map, of, switchMap, take, takeUntil } from 'rxjs';

import { ApiNotificationsEndpointService, GetNotificationResponse } from '@shure/cloud-apps/notifications/data-access';

import { X_API_KEY } from '../../domain/apiKeys';
import { NotificationsDetailsComponent } from '../notifications-details/notifications-details.component';

@Component({
	selector: 'sh-notifications-popup',
	templateUrl: './notifications-popup.component.html',
	styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
	notifications$: Observable<GetNotificationResponse[] | null> = EMPTY;
	currentDate = new Date();
	public readonly destroy$ = new Subject<void>();
	isListPage = false;
	constructor(
		public notificationService: ApiNotificationsEndpointService,
		public dialogRef: MatDialog,
		public router: Router,
		public cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.loadNotifications();
	}
	loadNotifications(): void {
		const params = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			limit: '10',
			offset: '0',
			context: undefined
		};

		this.notificationService
			.getNotifications(params)
			.pipe(
				map((response) => {
					const notifications = response?.items ?? [];
					const unreadNotifications = notifications.filter(
						(notification) => !notification.readAt && !notification.archivedAt
					);
					return unreadNotifications;
				}),
				catchError(() => of([])),
				takeUntil(this.destroy$)
			)
			.subscribe((notifications) => {
				this.notifications$ = of(notifications);
				this.cdr.detectChanges();
			});
	}
	notificationDetails() {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('notifications-details');
	}
	getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	openPopup(selectedNotification: string, isListPage: boolean) {
		isListPage = false;
		this.dialogRef.closeAll();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.width = '500px';

		dialogConfig.data = { notification: selectedNotification, checkPage: isListPage };
		this.dialogRef.open(NotificationsDetailsComponent, dialogConfig);
	}

	clearAll() {
		this.notifications$
			.pipe(
				takeUntil(this.destroy$),
				switchMap((notificationList) => {
					if (!notificationList || notificationList.length === 0) {
						return of(null);
					}

					const selectedIds = notificationList.map((notification) => notification.id);
					const requestData = {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'x-api-key': X_API_KEY,
						context: undefined,
						body: selectedIds.map((id) => ({
							id: id,
							isRead: true
						}))
					};

					return this.notificationService.postNotifications(requestData).pipe(
						switchMap(() => this.notifications$),
						take(1),
						map((notifications) => {
							if (notifications) {
								selectedIds.forEach((id) => {
									const notification = notifications.find((n) => n.id === id);
									if (notification) {
										notification.readAt = this.getCurrentDateInUTC();
									}
								});
							}
							return notifications;
						}),
						catchError((_error) => {
							return of(null);
						})
					);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				if (updatedNotifications !== null) {
					this.notifications$ = of(updatedNotifications);
					this.loadNotifications();
				}
			});
	}

	closedialog() {
		this.dialogRef.closeAll();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	markedAsRead(notification: any) {
		if (!notification.isRead) {
			const requestData = {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'x-api-key': X_API_KEY,
				context: undefined,
				body: Array.isArray(notification)
					? notification.map((item) => ({
							id: item.id,
							isRead: true
					  }))
					: [{ id: notification.id, isRead: true }]
			};

			this.notificationService
				.postNotifications(requestData)
				.pipe(
					take(1),
					switchMap(() => this.notifications$),
					take(1),
					map((notifications) => {
						if (!notifications) {
							return null;
						}
						const updatedNotification = notifications.find((n) => n.id === notification.id);
						if (updatedNotification) {
							updatedNotification.readAt = this.getCurrentDateInUTC();
							this.loadNotifications();
						}

						return notifications;
					}),
					catchError((_error) => {
						return of(null);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe((updatedNotifications) => {
					if (updatedNotifications !== null) {
						this.notifications$ = of(updatedNotifications);
					}
				});
		}
	}

	notificationsList() {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('/notifications-list');
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
