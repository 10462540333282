import { Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, Subject, take, takeUntil, throwError } from 'rxjs';

import { InventoryDevicesApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { PopcornTimerService } from '@shure/cloud-apps/devices/services';
import { InventoryDevice, UpdateRequestResponse } from '@shure/shared/models';

import { MockupInventory } from './mockup/inventory';
import { MockupDevice } from './mockup/inventory.device';

@Injectable({ providedIn: 'root' })
export class DaiMockupService implements InventoryDevicesApiService, OnDestroy {
	public deviceInventoryQueryInProgress$ = new BehaviorSubject<boolean>(false);
	public deviceInventory$ = new BehaviorSubject<InventoryDevice[]>([]);
	public deviceInventoryLoading = signal(false);

	private destroy$ = new Subject<void>();

	constructor(
		private readonly inventory: MockupInventory,
		private readonly popcornTimerService: PopcornTimerService
	) {}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.inventory.devices$.pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.inventory.devices$.pipe(map((devices) => devices.map((d) => d.getAsInventoryDevice())));
	}

	public getInventoryDevice$(deviceId: string): Observable<InventoryDevice> {
		return this.inventory.getDevice$(deviceId).pipe(map((device) => device.getAsInventoryDevice()));
	}

	public getDevice$(deviceId: string): Observable<MockupDevice> {
		return this.inventory.getDevice$(deviceId).pipe(map((d) => d.getAsFullDevice()));
	}

	public getDevicesById$(deviceIds: string[]): Observable<MockupDevice[]> {
		return this.inventory
			.getDevicesById$(deviceIds)
			.pipe(map((devices) => devices.map((d) => d.getAsFullDevice())));
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateRequestResponse<string>> {
		return this.inventory.setMute(deviceId, mute);
	}
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>> {
		return this.inventory.setIdentify(deviceId, identify);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateRequestResponse<string>> {
		return this.inventory.updateDevice(deviceId, { name });
	}

	public doInventoryQuery(): void {
		this.deviceInventoryQueryInProgress$.next(true);
		this.deviceInventory$.next([]);
		this.popcornTimerService.start(10 * 1000);

		this.popcornTimerService
			.done$()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(() => {
				this.deviceInventoryQueryInProgress$.next(false);
			});

		this.getInventoryDevices$()
			.pipe(
				catchError((error: Error) => {
					this.deviceInventoryQueryInProgress$.next(false);
					this.popcornTimerService.stop();
					return throwError(() => error);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (devices: InventoryDevice[]) => {
					this.deviceInventoryQueryInProgress$.next(false);
					const mockValue: InventoryDevice[] = [];
					for (let i = 0; i < devices.length; i++) {
						mockValue.push(devices[i]);
					}

					this.deviceInventory$.next(mockValue);
					this.popcornTimerService.reset();
				}
			});
	}
}
