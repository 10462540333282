import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DevicePropertyPanelApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { PropertyPanelDevice, UpdateResponse } from '@shure/shared/models';

import { MockupInventory } from '../mockup/inventory';
@Injectable({ providedIn: 'root' })
export class DaiMockupDevicePropertyPanelService extends DevicePropertyPanelApiService {
	constructor(private readonly inventory: MockupInventory) {
		super();
	}

	public getDevice$(deviceId: string): Observable<PropertyPanelDevice> {
		return this.inventory.getDevice$(deviceId).pipe(map((device) => device.getAsPropertyPanelDevice()));
	}

	public forgetDevice(_deviceId: string) {
		return;
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		return this.inventory.setMute(deviceId, mute);
	}
	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		return this.inventory.updateDevice(deviceId, { name });
	}
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		return this.inventory.setIdentify(deviceId, identify);
	}

	public rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>> {
		return this.inventory.rebootDevice(deviceId);
	}

	public override updateFirmware(deviceId: string): Observable<UpdateResponse<void, string>> {
		return this.inventory.updateFirmware(deviceId);
	}
}
