import { Inject, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { ILogger } from '@shure/shared/angular/logging';
import { AppEnvironment, APP_ENVIRONMENT } from '@shure/shared/models';

import { OktaInterfaceService } from './okta-interface.service';

@NgModule({})
export class OktaModule {
	public static forRoot(oktaAuth: OktaAuth, signinRedirectRoute = '/signin'): ModuleWithProviders<OktaModule> {
		return {
			ngModule: OktaModule,
			providers: [
				OktaInterfaceService,
				{
					provide: OKTA_CONFIG,
					useValue: {
						oktaAuth,
						onAuthRequired: async (oktaAuth: OktaAuth, injector: Injector): Promise<void> => {
							const appEnv = injector.get(APP_ENVIRONMENT);
							const logger = injector.get(ILogger);
							const loggerInst = logger.createScopedLogger('OktaModule');

							// If there's no previous login state, it means the app is being launched.
							// If that's the case, or there isn't a a postLogoutRedirect URI configured
							// for the app, we just want to route to the signingRedirectRoute value.
							//
							// The else clause handles the case when we need to redirct an application
							// back to a parent site, such as ignite, when the user signs out of an application.

							if (appEnv.appType === 'admin' || appEnv.appType === 'device') {
								const prevAuthState = oktaAuth.authStateManager.getPreviousAuthState();
								const currAuthState = oktaAuth.authStateManager.getAuthState();

								loggerInst.debug(
									`onAuthRequired: `,
									JSON.stringify({
										previous: JSON.stringify(prevAuthState),
										current: JSON.stringify(currAuthState)
									})
								);
								// console.log(`OktaModule, authState: `, { authState: prevAuthState });
								if (!prevAuthState?.isAuthenticated || prevAuthState.error || currAuthState?.error) {
									const router = injector.get(Router);
									router.navigate([signinRedirectRoute]);
								}
							} else {
								await oktaAuth.signInWithRedirect();
							}
						}
					}
				}
			]
		};
	}
}
