import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslocoService } from '@ngneat/transloco';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import {
	NgHttpCachingModule,
	NgHttpCachingConfig,
	NgHttpCachingStrategy,
	NgHttpCachingLocalStorage
} from 'ng-http-caching';
import { NgxWebstorageModule } from 'ngx-webstorage';

import {
	DaiCloudMockupModule,
	DaiMockupDeviceDiscoveryService
} from '@shure/cloud-apps/devices/data-access-intances/dai-mockup';
import {
	DaiDeviceDiscoveryService,
	DaiServicesCloudSysapiModule,
	DeviceDiscoveryApiService,
	SystemService
} from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { CloudLoggerSink } from '@shure/cloud-apps/shared';
import { UsersApiModule } from '@shure/cloud-apps/users/data-access';
import { AnalyticsModule } from '@shure/shared/angular/analytics';
import { AppEnvironmentGuard } from '@shure/shared/angular/app-environment-guard';
import { HeaderToolbarModule, TopRibbonModule } from '@shure/shared/angular/components';
import { ApiBaseurlInterceptor, ApiUrlConfig, AuthTokenInterceptor, HttpModule } from '@shure/shared/angular/http';
import { TranslocoRootModule, translocoInitializer } from '@shure/shared/angular/i18n';
import { InactivityModule, InactivityService } from '@shure/shared/angular/inactivity';
import { ConsoleLoggerSink, LoggingModule, StaticLoggingConfigProvider } from '@shure/shared/angular/logging';
import { OktaModule } from '@shure/shared/angular/okta';
import { APP_ENVIRONMENT, getShureCloudUri } from '@shure/shared/models';

import { MaterialIconsModule } from '../components/material-icons.module';
import { ShDevicePortalSidenavComponent } from '../components/sh-device-portal-sidenav/sh-device-portal-sidenav.component';
import { environment } from '../environments/environment';
import { CloudSystemService } from '../services/system';

import { CloudDeviceBehaviorPluginsModule } from './app-device-behavior-plugins.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const appBaseURL = window.location.origin;
const shureCloudUriMap = environment.shureCloudUriMap;

const oktaConfig: OktaAuthOptions = {
	issuer: 'https://' + environment.authorization.oktaDomain + environment.authorization.authServerPath,
	clientId: environment.authorization.clientId,
	redirectUri: `${appBaseURL}/signin/callback`,
	scopes: environment.authorization.scopes,
	logoutUrl: getShureCloudUri(appBaseURL, shureCloudUriMap),
	postLogoutRedirectUri: getShureCloudUri(appBaseURL, shureCloudUriMap),
	pkce: true,
	tokenManager: {
		autoRenew: true,
		syncStorage: true
	}
};
const oktaAuth = new OktaAuth(oktaConfig);

const apiUrlConfig: ApiUrlConfig = {
	apiBaseUrl: environment.orgs?.orgsApiUrl,
	rolesApiUrl: environment.orgs?.rolesApiUrl
};

const cacheApiUrls = ['/permissions', '/version', '/contactdetails', '/organizationIds'];

const ngHttpCachingConfig: NgHttpCachingConfig = {
	lifetime: 1000 * 360, // cache expire after 6 mins
	allowedMethod: ['GET'],
	cacheStrategy: NgHttpCachingStrategy.ALLOW_ALL,
	store: new NgHttpCachingLocalStorage(),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	isCacheable: (req: HttpRequest<any>): boolean | undefined => {
		//Caching the response of the cacheApiUrls
		for (const url of cacheApiUrls) {
			if (req.urlWithParams.indexOf(url) !== -1) {
				return true;
			}
		}
		// by returning "false" for all the other APIs
		return false;
	}
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		MaterialIconsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgIdleKeepaliveModule.forRoot(),
		NgxWebstorageModule.forRoot(),
		environment.useMockup ? DaiCloudMockupModule : DaiServicesCloudSysapiModule.forRoot(),
		TranslocoRootModule.forRoot(environment.production),
		InactivityModule.forRoot(),
		TopRibbonModule,
		OktaModule.forRoot(oktaAuth),
		AppRoutingModule,
		LoggingModule.forRoot(
			environment.remoteLogging ? [CloudLoggerSink] : [ConsoleLoggerSink],
			{ base: { default: environment.logLevel } },
			StaticLoggingConfigProvider
		),
		CloudDeviceBehaviorPluginsModule,
		AnalyticsModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatSnackBarModule,
		MatButtonModule,
		ShDevicePortalSidenavComponent,
		HttpModule.forRoot(apiUrlConfig, [
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ApiBaseurlInterceptor,
				multi: true
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AuthTokenInterceptor,
				multi: true
			}
		]),
		UsersApiModule.forRoot({
			rootUrl: environment.orgs?.usersApiUrl
		}),
		NgHttpCachingModule.forRoot(ngHttpCachingConfig),
		HeaderToolbarModule
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
		{ provide: APP_ENVIRONMENT, useValue: environment },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <MatFormFieldDefaultOptions>{
				appearance: 'fill',
				subscriptSizing: 'dynamic',
				color: 'accent'
			}
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: <RippleGlobalOptions>{
				disabled: true
			}
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: <MatSnackBarConfig>{
				duration: 8000,
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
				panelClass: ['mat-toolbar', 'mat-primary']
			}
		},
		InactivityService,
		{
			provide: SystemService,
			useClass: CloudSystemService
		},
		{
			provide: DeviceDiscoveryApiService,
			useClass: environment.useMockup ? DaiMockupDeviceDiscoveryService : DaiDeviceDiscoveryService
		},
		AppEnvironmentGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
