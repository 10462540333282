/* eslint-disable max-classes-per-file */
import { AfterViewInit, Component, ContentChild, ElementRef, OnDestroy } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

import { NavigationButtonGroupComponent } from './nav-button-group/navigation-button-group.component';
import { NavigationTabGroupComponent } from './nav-tab-group/navigation-tabs.component';

@Component({
	selector: 'sh-sticky-content-left',
	template: '<ng-content></ng-content>'
})
export class StickyContentLeftComponent {}

@Component({
	selector: 'sh-sticky-content-right',
	template: '<ng-content></ng-content>'
})
export class StickyContentRightComponent {}

/**
 * ```<sh-nav-toolbar>``` is a responsive toolbar, where content is collapsed/expanded to fit the
 * viewports width.
 *
 * ### Sticky content
 * The ```<sh-sticky-content-[left|right]>``` component can be used to directly
 * [project content](https://angular.io/guide/content-projection),
 * which is pinned in the left or right side of the toolbar and will be left untouched even when the toolbar collapses.
 *
 * ```html
 * <sh-nav-toolbar>
 * 	<sh-sticky-content-left>
 * 		<span>Left side content</span>
 * 	</sh-sticky-content-left>
 * </sh-nav-toolbar>
 * ```
 *
 * ### Buttons
 * The ```<sh-nav-button-group>``` component can be used to project 'responsive' ```<sh-nav-button>``` elements, which are transformed to
 * [mat-fab](https://material.angular.io/components/button/overview) buttons when the toolbar collapses the ```<sh-nav-button-group>```.
 *
 * ```html
 * <sh-nav-toolbar>
 * 	<sh-nav-button-group>
 * 		<sh-nav-button
 * 			id="sh-mute-button"
 * 			label="Mute"
 * 			icon="sh-muteall"
 * 			[color]="muted ? 'warn'|'primary'"
 * 			(click)="muteClicked()"
 * 			[disabled]="canMute">
 * 		</sh-nav-button>
 * 	</sh-nav-button-group>
 * </sh-nav-toolbar>
 * ```
 *
 * ### Tabs
 * The ```<sh-nav-tab-group>``` component can be used to project 'responsive' ```<sh-nav-tab>``` or ```<sh-nav-charm>``` elements,
 * which are represented as a [<nav mat-tab-nav-bar>](https://material.angular.io/components/tabs/overview#tabs-and-navigation)
 * component when the viewport is large enough to display all the content, where ```<sh-nav-tab>``` are rendered in the left side
 * and ```<sh-nav-charm>``` in the right side.
 *
 * On smaller viewports the ```<sh-nav-tab>``` and ```<sh-nav-charm>``` are collapsed to a
 * [<mat-menu>](https://material.angular.io/components/menu/overview).
 *
 * ```html
 * <sh-nav-toolbar>
 * 	<sh-nav-tab-group>
 * 		<sh-nav-tab
 * 			id="sh-routing"
 * 			label="Routing"
 * 			routerLink="routing"
 * 			[disabled]="canRoute">
 * 		</sh-nav-tab>
 *
 * 		<sh-nav-charm
 * 			id="sh-presets"
 * 			label="Presets"
 * 			icon="bookmark_border"
 * 			routerLink="presets"
 * 			[disabled]="hasPresets">
 * 		</sh-nav-charm>
 * 	</sh-nav-tab-group>
 * </sh-nav-toolbar>
 * ```
 *
 * ### Sticky Buttons
 * The ```sh-sticky-button-group``` component can be used to project pinned buttons inside either the ```<sh-nav-tab-group>```, placed
 * between the ```<sh-nav-tab>``` and ```<sh-nav-charm>``` sections, or ```<sh-nav-button-group>```, placed after the ```<sh-nav-button>```
 * section, and will be left untouched when the toolbar is collapsed.
 *
 * ```html
 * <sh-nav-toolbar>
 * 	<sh-nav-button-group>
 * 		<sh-nav-button
 * 			label="Mute"
 * 			icon="sh-muteall"
 * 			(click)="console.log('clicked')">
 * 		</sh-nav-button>
 * 		<!-- additional content -->
 *
 * 		<sh-sticky-button-group>
 * 			<button
 * 				mat-raised-button
 * 				(click)="deploy()">
 * 				Deploy
 * 			</button>
 * 		</sh-sticky-button-group>
 *
 * 	</sh-nav-button-group>
 *
 * 	<sh-nav-tab-group>
 * 		<sh-nav-tab
 * 			label="Routing">
 * 		</sh-nav-tab>
 * 		<!-- additional content -->
 *
 * 		<sh-sticky-button-group>
 * 			<button
 * 				mat-raised-button
 * 				(click)="deploy()">
 * 				Deploy
 * 			</button>
 * 		</sh-sticky-button-group>
 *
 * 	</sh-nav-tab-group>
 * </sh-nav-toolbar>
 * ```
 */
@Component({
	selector: 'sh-nav-toolbar',
	templateUrl: './navigation-toolbar.component.html',
	styleUrls: ['./navigation-toolbar.component.scss']
})
export class NavigationToolbarComponent implements AfterViewInit, OnDestroy {
	/**
	 * Projected ```<sh-nav-tab-group>```.
	 */
	public get tabGroup(): NavigationTabGroupComponent {
		return this._tabGroup;
	}
	@ContentChild(NavigationTabGroupComponent) private readonly _tabGroup!: NavigationTabGroupComponent;

	/**
	 * Projected ```<sh-nav-button-group>```.
	 */
	public get buttonGroup(): NavigationButtonGroupComponent {
		return this._buttonGroup;
	}
	@ContentChild(NavigationButtonGroupComponent) private readonly _buttonGroup!: NavigationButtonGroupComponent;

	/**
	 * Width of the ```<sh-sticky-content-left>``` content.
	 */
	private get stickyLeftWidth(): number {
		return this.stickyLeftElement ? this.stickyLeftElement.nativeElement.clientWidth : 0;
	}

	/**
	 * Width of the ```<sh-sticky-content-right>``` content.
	 */
	private get stickyRightWidth(): number {
		return this.stickyRightElement ? this.stickyRightElement.nativeElement.clientWidth : 0;
	}

	/**
	 * Template ref to the ```<sh-sticky-content-left>```.
	 */
	@ContentChild(StickyContentLeftComponent, { read: ElementRef })
	private readonly stickyLeftElement!: ElementRef<HTMLElement>;

	/**
	 * Template ref to the ```<sh-sticky-content-right>```.
	 */
	@ContentChild(StickyContentRightComponent, { read: ElementRef })
	private readonly stickyRightElement!: ElementRef<HTMLElement>;

	private readonly resizeObserver = new ResizeObserver(() => this.onResize());

	private readonly MAT_TOOLBAR_DEFAULT_PADDING = 32;

	constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

	public ngAfterViewInit(): void {
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	public ngOnDestroy(): void {
		this.resizeObserver.disconnect();
	}

	private onResize(): void {
		const toolbarWidth = this.elementRef.nativeElement.clientWidth - this.MAT_TOOLBAR_DEFAULT_PADDING;
		const stickyWidth = this.stickyLeftWidth + this.stickyRightWidth;
		const tabsWidth = this.tabGroup?.fullWidth | 0;
		const buttonsWidth = this.buttonGroup?.fullWidth | 0;

		const collapseTabs = stickyWidth + buttonsWidth + tabsWidth > toolbarWidth;
		this.tabGroup?.toggleCollapsed(collapseTabs);

		const collapseButtons = stickyWidth + (this.tabGroup?.collapsedWidth | 0) + buttonsWidth > toolbarWidth;
		this.buttonGroup?.toggleCollapsed(collapseButtons);
	}
}
