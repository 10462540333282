import { map, Observable, of } from 'rxjs';

export interface ChannelNameCheckData {
	id: string;
	channelName: string;
}

export interface DuplicateChannelNameDevice {
	features: {
		channels?: {
			channels: DuplicateChannelNameChannel[];
		};
	};
}

export interface DuplicateChannelNameChannel {
	id: string;
	features: {
		channelName: string;
	};
}

export interface DuplicateChannelNameApiService {
	getChannelNameDevice$(deviceId: string): Observable<DuplicateChannelNameDevice>;
}

export class DuplicateChannelNameService {
	public static getChannelNames$(
		apiService: DuplicateChannelNameApiService | null,
		deviceId: string
	): Observable<ChannelNameCheckData[] | []> {
		if (!apiService || typeof apiService.getChannelNameDevice$ !== 'function') {
			return of([]);
		}
		return apiService.getChannelNameDevice$(deviceId).pipe(
			map((device: DuplicateChannelNameDevice) => {
				const channels = device.features.channels?.channels || [];
				return channels
					.filter((channel: DuplicateChannelNameChannel) => !!channel.features?.channelName)
					.map(
						(channel: DuplicateChannelNameChannel) =>
							<ChannelNameCheckData>{
								id: channel.id,
								channelName: channel.features.channelName
							}
					);
			})
		);
	}
}
