import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, take } from 'rxjs';

import { DeviceClaimingDiscoveryApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { PopcornTimerService } from '@shure/cloud-apps/devices/services';
import { ILogger } from '@shure/shared/angular/logging';
import { DetectedDevice, DeviceDetection, DeviceModel } from '@shure/shared/models';

const deviceClaimingPopcornTimeoutValue = 5000;

const detectedDevices: Partial<DetectedDevice>[] = [
	{
		category: 'P300_V2',
		model: DeviceModel.P300,
		serialNumber: '2BC25177588',
		macAddress: '00:11:22:33:44:55',
		firmwareVersion: '99.0.23093.0',
		name: 'P300'
	},
	{
		category: DeviceModel.MXA7102FT,
		model: DeviceModel.MXA7102FT,
		serialNumber: '2BC25177589',
		macAddress: '00:11:22:33:44:11',
		firmwareVersion: '0.1.0.0',
		name: 'MXA7102'
	},
	{
		category: DeviceModel.MXA7104FT,
		model: DeviceModel.MXA7104FT,
		serialNumber: '2BC25177581',
		macAddress: '00:11:22:33:44:00',
		firmwareVersion: '0.1.0.0',
		name: 'MXA7104'
	},
	{
		category: DeviceModel.MXA920R,
		model: DeviceModel.MXA920R,
		serialNumber: '2BC25177582',
		macAddress: '00:11:22:33:44:22',
		firmwareVersion: '99.0.25.0',
		name: 'MXA920-R'
	},
	{
		category: DeviceModel.MXA920S,
		model: DeviceModel.MXA920S,
		serialNumber: '2BC25177583',
		macAddress: '00:11:22:33:44:33',
		firmwareVersion: '99.0.25.10',
		name: 'MXA920-S'
	},
	{
		category: 'MXWN_APTCH2',
		model: DeviceModel.MXWAPXD2,
		name: 'APXD2'
	},
	{
		category: 'ANIUSB',
		model: DeviceModel.ANIUSB,
		name: 'ANIUSB'
	},
	{
		category: DeviceModel.MXA901R,
		model: DeviceModel.MXA901R,
		name: 'MXA901-R'
	},
	{
		category: DeviceModel.MXA901S,
		model: DeviceModel.MXA901S,
		name: 'MXA901-S'
	},
	{
		category: DeviceModel.MXA902S,
		model: DeviceModel.MXA902S,
		name: 'MXA902-S'
	},
	{
		category: 'IMXR',
		model: DeviceModel.IMXR,
		name: 'IMX-Room'
	},
	{
		category: DeviceModel.MXN5C,
		model: DeviceModel.MXN5C,
		name: 'MXN5-C'
	},
	{
		category: 'MCR',
		model: DeviceModel.MCR,
		name: 'ANX4'
	},
	{
		category: DeviceModel.AD600,
		model: DeviceModel.AD600,
		name: 'AD600'
	},
	{
		category: DeviceModel.ADTD,
		model: DeviceModel.ADTD,
		name: 'ADTD'
	},
	{
		category: DeviceModel.ADTQ,
		model: DeviceModel.ADTQ,
		name: 'ADTQ'
	}
];

//
// It is presumed this service is provided by the claiming dialog. The implication of this
// is that this services lives and dies with the claiming dialog. We can take advantage of that fact
// by triggering cleanup logic in ngOnDestroy()
//
@Injectable()
export class DaiMockupDeviceClaimingDiscoveryService implements DeviceClaimingDiscoveryApiService, OnDestroy {
	public unclaimedDevices$ = new BehaviorSubject<DeviceDetection[]>([]);
	public scanInProgess$ = new BehaviorSubject<boolean>(false);
	public deviceDiscoveryError$ = new ReplaySubject<string>(1);

	private auditUnclaimedDevicesTimer: ReturnType<typeof setTimeout> | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	private readonly logger: ILogger;

	constructor(logger: ILogger, private readonly popcornTimerService: PopcornTimerService) {
		this.logger = logger.createScopedLogger('DaiMockupDeviceClaimingDiscoveryService');
	}

	public ngOnDestroy(): void {
		clearTimeout(<number | undefined>this.auditUnclaimedDevicesTimer);
		this.destroy$.next();
		this.destroy$.complete();
	}

	public destroy(): void {
		this.ngOnDestroy();
	}

	public newScan(): void {
		this.logger.trace('newScan()', 'Start scan for unclaimed devices', this.scanInProgess$.value);
		if (this.scanInProgess$.value === true) {
			return;
		}

		this.scanInProgess$.next(true);
		this.unclaimedDevices$.next([]);

		this.startDeviceDiscoveryTimer();
	}

	private startDeviceDiscoveryTimer(): void {
		this.logger.trace('startDeviceDiscoveryTimer()', 'Start claiming timer');
		this.popcornTimerService.start(deviceClaimingPopcornTimeoutValue);
		this.popcornTimerService
			.done$()
			.pipe(take(1))
			.subscribe(() => this.scanCompleted());
	}

	private scanCompleted(): void {
		this.logger.trace('scanCompleted', 'Claiming scan completed');
		this.scanInProgess$.next(false);
		this.createMockClaimableDevices();
	}

	private handleNewDeviceDetection(deviceDetection: DeviceDetection | undefined): void {
		this.logger.trace('handleNewDetectionEvent', 'Handle detection of claimable device', deviceDetection);

		// don't consider devices that aren't claimable
		if (deviceDetection && deviceDetection?.claimable) {
			this.popcornTimerService.reset(); // kernel popped
			const currentDevices = this.unclaimedDevices$.value;
			const index = currentDevices.findIndex((obj) => obj.device.id === deviceDetection.device.id);
			if (index >= 0) {
				if (currentDevices[index].expiresAt < deviceDetection.expiresAt)
					currentDevices[index] = deviceDetection;
			} else {
				currentDevices.push(deviceDetection);
			}
			this.unclaimedDevices$.next(currentDevices);
		}
	}

	private createMockClaimableDevices(): void {
		for (let i = 0; i < detectedDevices.length; i++) {
			const mockDetectionEvent: DeviceDetection = {
				claimable: true,
				detectedAt: '2023-07-27T20:09:05.000Z',
				detectionToken: 'MOCKTOKEN1',
				device: <DetectedDevice>{
					id: `aMock${i}`,
					hardwareId: `aMock${i}`,
					serialNumber: '2BC25177588',
					macAddress: '00:11:22:33:44:55',
					firmwareVersion: '99.0.23093.0',
					firmwareValid: true,
					ipAddress: '192.168.3.214',
					interface: { interfaceId: 1073807362, version: '1.0.2', beta: true },
					...detectedDevices[i]
				},
				expiresAt: '2023-07-27T20:11:05.000Z'
			};

			this.handleNewDeviceDetection(mockDetectionEvent);
		}
	}
}
