import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'sh-device-about-properties',
	standalone: true,
	imports: [CommonModule, TranslocoModule, MatFormFieldModule, MatInputModule],
	templateUrl: './about-properties.component.html',
	styleUrls: ['./about-properties.component.scss']
})
export class AboutPropertiesComponent {
	/**
	 * Serial number of the device.
	 */
	@Input() public serialNumber!: string;

	/**
	 * Firmware version of the device.
	 */
	@Input() public firmwareVersion!: string;

	/**
	 * Uptime for the device.
	 */
	@Input() public uptime!: string;
}
