import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';

import { AboutDialogComponent } from './about-dialog.component';

@NgModule({
	imports: [CommonModule, MatDialogModule, MatButtonModule, TranslocoModule, MatCheckboxModule],
	declarations: [AboutDialogComponent],
	exports: [AboutDialogComponent]
})
export class ShAboutDialogModule {}
