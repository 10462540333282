import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { ContentChild, ElementRef, Inject, Input, NgZone, Optional, ViewContainerRef, OnDestroy } from '@angular/core';
import { Directive, AfterViewInit, QueryList, ContentChildren } from '@angular/core';
import { MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
	MatTooltip,
	MatTooltipDefaultOptions,
	MAT_TOOLTIP_DEFAULT_OPTIONS,
	MAT_TOOLTIP_SCROLL_STRATEGY
} from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

/**
 * ```shTooltipErrors``` is a directive allowing to display ```<mat-errors>``` as a tooltip for ```<mat-form-field>```.
 *
 * This directive detect changes and if an error occurs, displays the corresponding error message as a tooltip on mouse hover.
 * It allows binding a value to be displayed as a normal tooltip when no errors are present as shown below
 *
 * **Usage**
 *
 * In the example below we bind the ```[shTooltipErrors]``` input property to the formControl.value.
 * This makes the tooltip display the current value of mat-input field.
 * On change, it adjusts the value to the user input or displays a corresponding error text if an error occurs.
 *
 * ```html
 * <mat-form-field [shTooltipErrors]="formControl.value">
 * 	<input matInput [formControl]="formControl">
 *	<mat-error *ngIf="formControl.hasError('required')">Room name is required</mat-error>
 * </mat-form-field>
 * ```
 *
 */
@Directive({
	selector: '[shTooltipErrors]'
})
export class ShTooltipErrorsDirective extends MatTooltip implements AfterViewInit, OnDestroy {
	/**
	 * Default string to display when no errors are present.
	 */
	@Input('shTooltipErrors')
	public set defaulTooltip(message: string) {
		this._defaulTooltip = message;
		if (!this.hasError) {
			this.message = message;
		}
	}
	private _defaulTooltip = '';

	@ContentChildren(MatError, { read: ElementRef })
	private readonly matErrors!: QueryList<ElementRef<HTMLElement>>;

	@ContentChild(MatInput, { read: ElementRef }) private readonly input!: ElementRef;

	private hasError = false;
	private destroy$ = new Subject<void>();

	constructor(
		overlay: Overlay,
		elementRef: ElementRef<HTMLElement>,
		scrollDispatcher: ScrollDispatcher,
		viewContainerRef: ViewContainerRef,
		ngZone: NgZone,
		platform: Platform,
		ariaDescriber: AriaDescriber,
		focusMonitor: FocusMonitor,
		@Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: unknown,
		@Optional() dir: Directionality,
		@Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) defaultOptions: MatTooltipDefaultOptions,
		@Inject(DOCUMENT) _document: unknown
	) {
		super(
			overlay,
			elementRef,
			scrollDispatcher,
			viewContainerRef,
			ngZone,
			platform,
			ariaDescriber,
			focusMonitor,
			scrollStrategy,
			dir,
			defaultOptions,
			_document
		);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		this.destroy$.next();
		this.destroy$.complete();
	}

	public override ngAfterViewInit(): void {
		// Overwrite tooltips' element ref to attach it to <input> instead of form-field
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(<any>this)._elementRef = this.input;
		super.ngAfterViewInit();

		this.matErrors.changes
			.pipe(
				/**
				 * Force below subscription to run after change detection
				 * to avoid expression check error.
				 */
				delay(0),
				takeUntil(this.destroy$)
			)
			.subscribe((changes: QueryList<ElementRef<HTMLElement>>) => {
				if (changes.length === 0) {
					this.message = this._defaulTooltip;
					this.hasError = false;
					return;
				}
				this.hasError = true;
				if (changes.first.nativeElement.textContent !== null) {
					this.message = changes.first.nativeElement.textContent;
				}
				changes.forEach((c) => (c.nativeElement.style.display = 'none'));
			});
	}
}
