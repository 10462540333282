<ng-container *transloco="let t">
	<mat-form-field fxFlex>
		<mat-label> {{ t('common.labels.serial-number') }} </mat-label>
		<input matInput type="text" disabled [value]="serialNumber" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ t('common.labels.firmware-version') }} </mat-label>
		<input matInput type="text" disabled [value]="firmwareVersion" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ t('components.properties.about.uptime') }} </mat-label>
		<input matInput type="text" disabled [value]="uptime" />
	</mat-form-field>
</ng-container>
