import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { RouterService } from '../router-service';

@Injectable({ providedIn: 'root' })
export class UIStateService<T> implements OnDestroy {
	private readonly destroy$ = new Subject<void>();

	private readonly _uiState: BehaviorSubject<Partial<T>> = new BehaviorSubject(<Partial<T>>{});

	public readonly uiState$ = this._uiState.asObservable();

	private readonly uiStateMap: Map<string, Partial<T>> = new Map();

	constructor(private readonly routerService: RouterService) {
		this.routerService.url$.pipe(takeUntil(this.destroy$)).subscribe((url) => {
			const urlStr = url.join('');
			const routeUiState = this.uiStateMap.get(urlStr);
			if (!routeUiState) {
				this.uiStateMap.set(urlStr, <T>{});
			}
			this._uiState.next(routeUiState || <T>{});
		});
	}

	public updateOrCreateSlice(state: Partial<T>, identifier?: string): void {
		if (identifier === undefined) {
			identifier = this.routerService.url.join('');
		}
		this.uiStateMap.set(identifier, {
			...(<T>{}),
			...this.uiStateMap.get(identifier),
			...state
		});

		const routeUiState = this.uiStateMap.get(identifier);
		if (routeUiState) {
			this._uiState.next(routeUiState);
		}
	}

	public snapshot(identifier?: string): Partial<T> {
		if (identifier === undefined) {
			identifier = this.routerService.url.join('');
		}

		return this.uiStateMap.get(identifier) ?? <T>{};
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
