import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DetectionTokenApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { DetectionToken } from '@shure/shared/models';

@Injectable({ providedIn: 'root' })
export class DaiMockupDetectionTokenService implements DetectionTokenApiService {
	public detectionToken$(): Observable<DetectionToken> {
		return of(<DetectionToken>{
			generatedAt: Date.now().toString(),
			expiresAt: 'Tomorrow',
			token: 'mockTocken'
		});
	}
}
