/* istanbul ignore file */
// need empty line here for 'istanbul ignore file' to work

import { ReplaySubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
	DeviceModel,
	DeviceState,
	InventoryDevice,
	IpMode,
	PropertyPanelDevice,
	SortedDeviceConditionCollection
} from '@shure/shared/models';

let globalNextId = 0;

export type MockupDevice = InventoryDevice;
export type MockupDeviceSerialized = { device: MockupDevice };

export interface DeviceFeature {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[featureName: string]: { [featureProp: string]: any; isMissing: boolean };
}

export class MockupInventoryDevice {
	public device$ = new ReplaySubject<MockupInventoryDevice>(1);
	private identifyTimer: /*NodeJS.Timeout*/ ReturnType<typeof setTimeout> | undefined;

	constructor(private device: MockupDevice) {
		if (this.device.features.identify) {
			this.device.features.identify.identifying = false;
		}
		if (this.device.features.availablePackages) {
			this.device.features.availablePackages = {
				primaryPackages: [
					{ key: 'mock1', version: '99.0.1.MOCK1' },
					{ key: 'mock2', version: '99.0.2.MOCK2' }
				],
				isMissing: false
			};
		}
		this.device$.next(this);
	}

	public static createMxa920(
		deviceModel: DeviceModel.MXA920R | DeviceModel.MXA920S,
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		const features = 'features' in properties ? properties.features : {};
		delete properties.features;

		const deviceId = `${uuidv4()}==`;
		return this.createMockupInventoryDevice(
			deviceModel,
			virtual,
			deviceState,
			{
				features: {
					...features
				},
				...properties
			},
			deviceId
		);
	}

	public static createP300(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.P300, virtual, deviceState, {
			...properties
		});
	}

	public static createMXWAPXD2(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		const features = properties.features;

		return this.createMockupInventoryDevice(DeviceModel.MXWAPXD2, virtual, deviceState, {
			...properties,
			features: {
				doublestuffProxiedTransmitters: {
					mic1: {
						batteryLevel: 97
					},
					mic2: {
						batteryLevel: 37
					},
					isMissing: false
				},
				...features
			}
		});
	}

	public static createMXA710(
		deviceModel: DeviceModel.MXA7102FT | DeviceModel.MXA7104FT,
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(deviceModel, virtual, deviceState, {
			...properties
		});
	}

	public static createANIUSB(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.ANIUSB, virtual, deviceState, {
			...properties
		});
	}

	public static createMXA901R(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.MXA901R, virtual, deviceState, {
			...properties
		});
	}

	public static createMXA901S(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.MXA901S, virtual, deviceState, {
			...properties
		});
	}

	public static createMXA902(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.MXA902S, virtual, deviceState, {
			...properties
		});
	}

	public static createIMXR(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.IMXR, virtual, deviceState, {
			...properties
		});
	}

	public static createMXN5C(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.MXN5C, virtual, deviceState, {
			...properties
		});
	}

	public static createMCR(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.MCR, virtual, deviceState, {
			...properties
		});
	}

	public static createAD600(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.AD600, virtual, deviceState, {
			...properties
		});
	}

	public static createADTD(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.ADTD, virtual, deviceState, {
			...properties
		});
	}

	public static createADTQ(
		virtual: boolean,
		deviceState: DeviceState,
		properties: Partial<MockupDevice> = {}
	): MockupInventoryDevice {
		return this.createMockupInventoryDevice(DeviceModel.ADTQ, virtual, deviceState, {
			...properties
		});
	}

	public static createMockupInventoryDevice(
		model: DeviceModel,
		virtual: boolean,
		deviceState: DeviceState,
		properties: { [P in keyof MockupDevice]?: MockupDevice[P] } = {},
		deviceId?: string
	): MockupInventoryDevice {
		const nextId = globalNextId++;
		const features = 'features' in properties ? properties.features : {};
		delete properties.features;

		const id = deviceId ? deviceId : `${uuidv4()}==`;

		const device: MockupDevice = {
			id: id,
			name: deviceState === DeviceState.Online ? `New-Device-${nextId}` : '',
			model: model,
			deviceState: deviceState,
			virtual,
			conditions: new SortedDeviceConditionCollection(),
			...properties,
			features:
				deviceState === DeviceState.Online
					? {
							audioNetwork: {
								gateway: `111.111.111.11${nextId}`,
								ipAddress: `222.222.222.22${nextId}`,
								macAddress: '00-00-5E-00-53-BF',
								ipMode: IpMode.STATIC,
								subnetMask: `25.255.255.00${nextId}`,
								isMissing: false
							},
							controlNetwork: {
								ipMode: IpMode.STATIC,
								gateway: '123.123.123.123',
								ipAddress: `192.192.192.${nextId}`,
								subnetMask: '222.222.222.222',
								macAddress: '00-00-5E-00-53-BF',
								isMissing: false
							},
							danteName: {
								danteName: `dante-name-${nextId}`,
								isMissing: false
							},
							firmware: {
								version: `2.2.${nextId}`,
								valid: true,
								isUpToDate: false,
								isMissing: false
							},
							availablePackages: {
								primaryPackages: [],
								isMissing: false
							},
							mute: {
								muted: false,
								isMissing: false
							},
							identify: {
								identifying: false,
								isMissing: false
							},
							serialNumber: {
								serialNumber: `SERIAL${nextId}`,
								isMissing: false
							},
							uptime: {
								uptime: 'TBD',
								isMissing: false
							}
					  }
					: {}
		};
		device.features = {
			...device.features,
			...features
		};

		return new MockupInventoryDevice(device);
	}

	public static createFromSerialized(device: MockupDeviceSerialized): MockupInventoryDevice {
		const newDevice = new MockupInventoryDevice(device.device);
		return newDevice;
	}

	public getId(): string {
		return this.device.id;
	}

	public getAsInventoryDevice(): InventoryDevice {
		const liveDevice: InventoryDevice = {
			id: this.device.id,
			name: this.device.name,
			model: this.device.model,
			virtual: this.device.virtual,
			deviceState: this.device.deviceState,
			conditions: new SortedDeviceConditionCollection(),
			features: {
				controlNetwork: this.device.features.controlNetwork,
				firmware: this.device.features.firmware,
				identify: this.device.features.identify,
				serialNumber: this.device.features.serialNumber
			}
			// passwordPreferenceSet: this.device.passwordPreferenceSet
		};
		return liveDevice;
	}

	public getAsFullDevice(): MockupDevice {
		return { ...this.device };
	}

	public getAsPropertyPanelDevice(): PropertyPanelDevice {
		return {
			id: this.device.id,
			name: this.device.name,
			model: this.device.model,
			isVirtual: this.device.virtual,
			features: {
				mute: this.device.features.mute,
				serialNumber: this.device.features.serialNumber,
				firmware: this.device.features.firmware,
				availablePackages: this.device.features.availablePackages,
				identify: this.device.features.identify,
				danteName: this.device.features.danteName,
				audioNetwork: this.device.features.audioNetwork,
				controlNetwork: this.device.features.controlNetwork,
				// isDeviceLocked: this.device.features.isDeviceLocked,
				uptime: this.device.features.uptime
				// doublestuffProxiedTransmitters: this.device.features.doublestuffProxiedTransmitters
			}
		};
	}

	public serialize(): MockupDeviceSerialized {
		return {
			device: this.device
		};
	}

	public update(update: Partial<MockupDevice>): void {
		const updatedFeatures = 'features' in update ? update.features : {};
		delete update.features;

		this.device = { ...this.device, ...update, features: { ...this.device.features, ...updatedFeatures } };

		this.valueChanged();
	}

	public setIdentify(identify: boolean): void {
		if (this.device.features.identify) {
			this.device.features.identify.identifying = identify;
			this.valueChanged();

			if (this.identifyTimer) {
				clearTimeout(this.identifyTimer);
			}
			if (this.device.features.identify.identifying) {
				this.identifyTimer = setTimeout(() => {
					if (this.device.features.identify) {
						this.device.features.identify.identifying = false;
					}
					this.valueChanged();
				}, 20000);
			}
		}
	}

	public setMute(mute: boolean): void {
		if (this.device.features.mute) {
			this.device.features.mute = {
				...this.device.features.mute,
				muted: mute,
				isMissing: this.device.features.mute.isMissing
			};
		}
		// this.device = { ...this.device, deviceMute: mute };
		this.valueChanged();
	}

	private valueChanged(): void {
		this.device$.next(this);
	}
}
