export class FormatDate {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatDate(notificationDate: any): string {
		const today = new Date();
		const notificationDateObj = new Date(notificationDate);

		if (!isNaN(notificationDateObj.getTime())) {
			if (this.isSameDay(today, notificationDateObj)) {
				return `Today, ${notificationDateObj.toLocaleString([], {
					hour: 'numeric',
					minute: 'numeric',
					hour12: true
				})}`;
			} else if (this.isWithinLastWeek(today, notificationDateObj)) {
				return notificationDateObj.toLocaleString('en-US', {
					weekday: 'long',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true
				});
			}
		}

		return notificationDateObj.toLocaleDateString('en-US', {
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		});
	}

	private isSameDay(date1: Date, date2: Date): boolean {
		return (
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate()
		);
	}

	private isWithinLastWeek(today: Date, date: Date): boolean {
		const daysInMs = 24 * 60 * 60 * 1000;
		const diffInMs = today.getTime() - date.getTime();
		const diffInDays = Math.abs(Math.floor(diffInMs / daysInMs));
		return diffInDays <= 7;
	}
}
