import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { Mxa902DeviceBehaviorPluginBase } from './mxa902.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxa902DesignerDeviceBehaviorPlugin extends Mxa902DeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
