/* eslint-disable @typescript-eslint/naming-convention */
export enum IpAddressConstants {
	VALIDATE_IP_INVALID = 9,
	VALIDATE_IP_CONFLICT = 8,
	VALIDATE_IP_ERROR_BROADCAST = 6,
	VALIDATE_IP_ERROR_LOOPBACK = 2,
	VALIDATE_IP_ERROR_MULTICAST = 3,
	VALIDATE_IP_ERROR_NETWORK_ID = 5,
	VALIDATE_IP_ERROR_RESERVED = 4,
	VALIDATE_IP_ERROR_SELF_IDENTIFY = 1,
	VALIDATE_IP_ERROR_SUBNET = 7,
	VALIDATE_IP_OK = 0
}
