import { CommonModule } from '@angular/common';
import {
	Component,
	Input,
	forwardRef,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
	ElementRef,
	ChangeDetectorRef,
	OnInit,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	HostBinding,
	Renderer2
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { includes } from 'lodash-es';

import { IpAddressConstants } from './ip-address.constants';
import { ipUtilities, noop } from './ip-address.utils';
import { IpAddressValidator } from './ip-address.validator';

/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ANGULAR2_IP_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => IpAddressComponent),
	multi: true
};

function cancelEvent($event: Event): void {
	$event.preventDefault();
	$event.stopPropagation();
}

@Component({
	selector: 'sh-ip-input',
	providers: [ANGULAR2_IP_CONTROL_VALUE_ACCESSOR],
	templateUrl: 'ip-address.component.html',
	styleUrls: ['./ip-address.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule]
})
export class IpAddressComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
	@Input() public subnetMask = '';

	/**
	 * @ignore internal
	 */
	@ViewChildren('input') public inputs!: QueryList<ElementRef>;

	@HostBinding('class.sh-ip-address-disabled')
	@Input()
	public disabled = false;

	@Input() public readonly = false;

	@Input() public labelName = 'IP Address';

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() public change = new EventEmitter<string>();

	/**
	 * @ignore internal
	 */
	public blocks: string[] = ['', '', '', ''];
	/**
	 * @ignore internal
	 */
	public blocksRef: number[] = this.blocks.map((v, i) => i);
	/**
	 * @ignore internal
	 */
	public invalidBlocks: string[] | undefined[] = [];
	/**
	 * @ignore internal
	 */
	public containerClass: string[] = [];

	/**
	 * @ignore internal
	 */
	public onTouched: () => void = noop;

	@Input() public set value(v: string) {
		if (v !== this._value) {
			this._value = v;
			this.blocks = this.toBlocks(v);
			this._onChangeCallback(v);

			if (!v) {
				for (let i = 0; i < this.blocks.length; i++) {
					this.invalidBlocks[i] = undefined;
				}
			} else {
				for (let i = 0; i < this.blocks.length; i++) {
					this.markBlockValidity(this.blocks[i], i);
				}
			}

			this._cdr.markForCheck();
			this._cdr.detectChanges();
		}
	}
	public get value(): string {
		return this._value;
	}

	/**
	 * When true add's the 'sh-ip-address-error' class to the block when it's invalid.
	 */
	@Input() public set highlightInvalidBlocks(value: boolean) {
		if (this._highlightInvalidBlocks === value) {
			return;
		}

		this._highlightInvalidBlocks = value;
		for (let i = 0; i < this.blocks.length; i++) {
			this.markBlockValidity(this.blocks[i], i);
		}
	}
	public get highlightInvalidBlocks(): boolean {
		return this._highlightInvalidBlocks;
	}

	private _value!: string;

	private _onChangeCallback: (value: string) => void = noop;
	private _highlightInvalidBlocks = true;
	private _reachedLeftEnd = false;
	private _reachedRightEnd = false;
	private _regExpIpBlock = /^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/;

	constructor(private readonly _renderer: Renderer2, private readonly _cdr: ChangeDetectorRef) {
		this.containerClass.push('sh-ip-address-theme-material');
	}

	public ngOnInit(): void {
		this.blocks = ['', '', '', ''];
		this.blocksRef = this.blocks.map((v, i) => i);
	}

	public ngOnChanges(changeRecord: SimpleChanges): void {
		// eslint-disable-next-line dot-notation
		if (changeRecord['subnetMask'] && !changeRecord['subnetMask'].isFirstChange()) {
			this.showErrorsInblocks();
		}
	}

	public ngOnDestroy(): void {
		this._cdr.detach();
	}

	/**
	 * @ignore internal
	 */
	public writeValue(value: string): void {
		this.value = value;
	}

	/**
	 * @ignore internal
	 */
	public registerOnChange(fn: (value: string | null) => void): void {
		this._onChangeCallback = fn;
	}

	/**
	 * @ignore internal
	 */
	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * @ignore internal
	 */
	public onChange(value: string, idx: number): void {
		if (this.blocks[idx] === value) {
			return;
		}
		this.blocks[idx] = value;
		this._value = this.blocks.join('.');
		this.notifyChange(this._value);
		this.markBlockValidity(value, idx);
	}

	public onKeyPress($event: KeyboardEvent, idx: number): void {
		if ($event.metaKey || $event.ctrlKey || $event.altKey) {
			return;
		}
		// browser support (e.g: safari)
		const key = $event.key;
		if (key === '.') {
			cancelEvent($event);
			this.focusTowards(idx, 'next');
		}
		const value = this.insert(<HTMLInputElement>$event.target, key);
		if (!this._regExpIpBlock.test(value)) {
			return cancelEvent($event);
		}
		if (ipUtilities.isMaxLen(value)) {
			cancelEvent($event);
			// need to force set the input value and trigger change event when moving focus to the next input
			// (This manual event is being fired in focusTowards())
			// otherwise, this.blocks and this._value do not reflect the last number entered causing wrong validation
			this._renderer.selectRootElement(this.inputs.toArray()[idx].nativeElement).value = value;
			this.focusTowards(idx, 'next');
		}

		this.markBlockValidity(value, idx);
	}

	public onKeyUp($event: KeyboardEvent, idx: number): void {
		const input = <HTMLInputElement>$event.target;
		if ($event.key === 'ArrowLeft') {
			// prev
			if (this._reachedLeftEnd) {
				cancelEvent($event);
				this.focusTowards(idx, 'prev');
				this._reachedLeftEnd = false;
				this._reachedRightEnd = false;
			} else if (input.selectionStart === 0) {
				this._reachedLeftEnd = true;
			}
			return;
		}
		if ($event.key === 'ArrowRight') {
			// next
			if (this._reachedRightEnd) {
				cancelEvent($event);
				this.focusTowards(idx, 'next');
				this._reachedLeftEnd = false;
				this._reachedRightEnd = false;
			} else if (input.selectionStart === input.value.length) {
				this._reachedRightEnd = true;
			}
			return;
		}
		if ($event.key !== 'Backspace') {
			if (input.selectionStart === 0 || input.selectionStart === input.value.length) {
				this._reachedLeftEnd = true;
				this._reachedRightEnd = true;
			}
			return;
		}

		const value =
			input &&
			input.selectionStart &&
			input.selectionStart >= 0 &&
			input.selectionEnd &&
			input.selectionEnd > input.selectionStart
				? input.value.substring(0, input.selectionStart) + input.value.substring(input.selectionEnd)
				: input.value.substring(0, input.value.length - 1);

		this.markBlockValidity(value, idx);
	}

	/**
	 * @ignore internal
	 */
	public onFocus(idx: number): void {
		this._renderer
			.selectRootElement(this.inputs.toArray()[idx].nativeElement)
			.setSelectionRange(0, this.blocks[idx].toString().length);
	}

	private focusTowards(idx: number, move: string): void {
		let direction: ElementRef = this.inputs.toArray()[idx];
		if (move === 'next') {
			direction = this.inputs.toArray()[idx + 1];
		}
		if (move === 'prev') {
			direction = this.inputs.toArray()[idx - 1];
		}
		if (direction) {
			this._renderer.selectRootElement(direction.nativeElement).focus();
			this._renderer
				.selectRootElement(this.inputs.toArray()[idx].nativeElement)
				.dispatchEvent(new Event('change'));
		}
	}

	private markBlockValidity(value: string, idx: number): void {
		this.invalidBlocks[idx] =
			!this.highlightInvalidBlocks || this._regExpIpBlock.test(value) ? undefined : 'mat-form-field-invalid';
		if (ipUtilities.isValid(this.blocks)) {
			this.invalidBlocks.map((_d) => undefined);
		}
		const isValidIP = includes(this.invalidBlocks, 'mat-form-field-invalid');
		if (!isValidIP && this.subnetMask) {
			this.showErrorsInblocks();
		}
	}

	private showErrorsInblocks(): void {
		const ip = this.blocks.join('.');
		const errorIdentifier = IpAddressValidator.validateIPAndSubnet(ip, this.subnetMask);
		const className =
			errorIdentifier === IpAddressConstants.VALIDATE_IP_ERROR_NETWORK_ID ? 'sh-ip-address-error' : undefined;
		this.invalidBlocks.map((_block) => className);
	}

	private notifyChange(value: string): void {
		this._onChangeCallback(value);
		this.change.emit(value);
	}
	/**
	 * Returns array of IP octects
	 */
	private toBlocks(value: string): string[] {
		return ipUtilities.split(value);
	}

	/**
	 * Given an input element, insert the supplied value at the caret position.
	 * If some (or all) of the text is selected, replaces the selection with the value.
	 * In case the input is falsy returns the value. (universal)
	 */
	private insert(input: HTMLInputElement, value: string): string {
		if (input.selectionStart == null) return '';
		if (input.selectionEnd == null) return '';
		return input
			? input.value.substring(0, input.selectionStart) + value + input.value.substring(input.selectionEnd)
			: value;
	}
}
