<div *ngFor="let notification of notifications$ | async" id="notification-container">
	<div style="margin-left: 20px; margin-top: 20px; height: 100%">
		<p id="subject" style="color: whitesmoke; margin-top: 10px">
			{{ notification.subject }}
		</p>
		<div id="from-label" class="pop-up-items">From</div>
		<p id="sender-email" class="item-style">{{ notification.sender.email }}</p>
		<div id="received-label" class="pop-up-items">Received</div>
		<p id="sent-at" class="item-style">{{ formatDateService.formatDate(notification.sentAt) }}</p>
		<div id="message-label" class="pop-up-items">Message</div>
		<div *ngIf="notification.body">
			<p id="message-body" class="item-style" *ngFor="let line of notification.body.split('\n')">
				<span [innerHTML]="formatAndHighlightLinks(line)"></span>
			</p>
		</div>
	</div>
	<div style="padding-bottom: 10px; overflow: hidden">
		<span id="close-popup-button" (click)="closePopup()" class="close-button highlight-hover">Close</span>
		<span
			*ngIf="checkPage"
			id="archive-button"
			(click)="markAsArchive(notification.id)"
			class="delete-buttons highlight-hover"
			>Archive</span
		>
		<span
			*ngIf="!checkPage"
			id="clear-button"
			class="delete-buttons highlight-hover"
			(click)="markAsRead(notification.id)"
			>Clear</span
		>
		<span id="go-to-app-button" class="close-button highlight-hover" (click)="closePopup()">Go to app</span>
	</div>
</div>
