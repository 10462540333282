import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, shareReplay } from 'rxjs';

/**
 * `State<T>` provides a simple way of storing UI state for a feature and
 * expose it as an observable.
 */
export class State<T> {
	private readonly _state$: BehaviorSubject<T>;
	public readonly state$: Observable<T>;

	/**
	 * Snapshot of current state.
	 */
	public get snapshot(): T {
		return this._state$.getValue();
	}

	constructor(initialState: T) {
		this._state$ = new BehaviorSubject(initialState);
		this.state$ = this._state$.asObservable().pipe(filter((state) => !!state));
	}

	/**
	 * Select a property from the state.
	 */
	public select<K>(mapFn: (state: T) => K): Observable<K> {
		return this.state$.pipe(
			map((state) => mapFn(state)),
			distinctUntilChanged(),
			shareReplay({ refCount: true, bufferSize: 1 })
		);
	}

	/**
	 * Patch current state with update.
	 */
	public update(update: Partial<T>): void {
		this._state$.next({ ...this.snapshot, ...update });
	}
}
