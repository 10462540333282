/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetNotificationByIdResponse } from '../models/get-notification-by-id-response';
import { GetNotificationResponse } from '../models/get-notification-response';
import { PostNotificationRequest } from '../models/post-notification-request';
import { PostNotificationResponse } from '../models/post-notification-response';
import { PostNotificationsRequest } from '../models/post-notifications-request';


/**
 * This lists and explains endpoints that relate to CRUD operations for notifications for a user.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiNotificationsEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getNotifications
   */
  static readonly GetNotificationsPath = '/notifications';

  /**
   * show a list of notifications for the logged-in user.
   *
   * show a list of notifications for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiNotificationsEndpointService.GetNotificationsPath, 'get');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * The total count of notifications available.
         */
        'itemsCount'?: number;
        
        /**
         * An array of notifications, for the given limit and offsets.
         */
        'items'?: Array<GetNotificationResponse>;
        }>;
      })
    );
  }

  /**
   * show a list of notifications for the logged-in user.
   *
   * show a list of notifications for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}> {

    return this.getNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}>) => r.body as {

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
})
    );
  }

  /**
   * Path part for operation postNotifications
   */
  static readonly PostNotificationsPath = '/notifications';

  /**
   * update notifications for the logged-in user.
   *
   * update notifications for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotifications$Response(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext
    body?: PostNotificationsRequest
  }
): Observable<StrictHttpResponse<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiNotificationsEndpointService.PostNotificationsPath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * The total count of notifications available.
         */
        'itemsCount'?: number;
        
        /**
         * An array of notifications, for the given limit and offsets.
         */
        'items'?: Array<GetNotificationResponse>;
        }>;
      })
    );
  }

  /**
   * update notifications for the logged-in user.
   *
   * update notifications for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotifications(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext
    body?: PostNotificationsRequest
  }
): Observable<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}> {

    return this.postNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
}>) => r.body as {

/**
 * The total count of notifications available.
 */
'itemsCount'?: number;

/**
 * An array of notifications, for the given limit and offsets.
 */
'items'?: Array<GetNotificationResponse>;
})
    );
  }

  /**
   * Path part for operation getStatus
   */
  static readonly GetStatusPath = '/status';

  /**
   * show the status of a notification.
   *
   * show the status of a notification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatus$Response(params: {

    /**
     * Id of notification used to fetch the status
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
'status'?: 'SENT' | 'ERROR' | 'QUEUED' | 'SKIPPED';
'type'?: 'EMAIL' | 'SMS' | 'PUSH';
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiNotificationsEndpointService.GetStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'status'?: 'SENT' | 'ERROR' | 'QUEUED' | 'SKIPPED';
        'type'?: 'EMAIL' | 'SMS' | 'PUSH';
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * show the status of a notification.
   *
   * show the status of a notification
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatus(params: {

    /**
     * Id of notification used to fetch the status
     */
    id: string;
    context?: HttpContext
  }
): Observable<{
'status'?: 'SENT' | 'ERROR' | 'QUEUED' | 'SKIPPED';
'type'?: 'EMAIL' | 'SMS' | 'PUSH';
'message'?: string;
}> {

    return this.getStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'status'?: 'SENT' | 'ERROR' | 'QUEUED' | 'SKIPPED';
'type'?: 'EMAIL' | 'SMS' | 'PUSH';
'message'?: string;
}>) => r.body as {
'status'?: 'SENT' | 'ERROR' | 'QUEUED' | 'SKIPPED';
'type'?: 'EMAIL' | 'SMS' | 'PUSH';
'message'?: string;
})
    );
  }

  /**
   * Path part for operation postNotification
   */
  static readonly PostNotificationPath = '/notification';

  /**
   * Send a notification based on user preferences.
   *
   * The endpoint to issue a notification. Currently, this endpoint only sends out emails. Eventually, other notifications will be supported and used based on user preferences. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotification$Response(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext

    /**
     * The request payload to issue a notification. Currently, this endpoint only sends out emails. Eventually, other notifications will be supported and used based on user preferences. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
     */
    body?: PostNotificationRequest
  }
): Observable<StrictHttpResponse<PostNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiNotificationsEndpointService.PostNotificationPath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostNotificationResponse>;
      })
    );
  }

  /**
   * Send a notification based on user preferences.
   *
   * The endpoint to issue a notification. Currently, this endpoint only sends out emails. Eventually, other notifications will be supported and used based on user preferences. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotification(params: {

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext

    /**
     * The request payload to issue a notification. Currently, this endpoint only sends out emails. Eventually, other notifications will be supported and used based on user preferences. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
     */
    body?: PostNotificationRequest
  }
): Observable<PostNotificationResponse> {

    return this.postNotification$Response(params).pipe(
      map((r: StrictHttpResponse<PostNotificationResponse>) => r.body as PostNotificationResponse)
    );
  }

  /**
   * Path part for operation getNotification
   */
  static readonly GetNotificationPath = '/notification/{notificationId}';

  /**
   * fetch the notification by notification Id.
   *
   * show the notification by notification Id for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotification$Response(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetNotificationByIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiNotificationsEndpointService.GetNotificationPath, 'get');
    if (params) {
      rb.path('notificationId', params.notificationId, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNotificationByIdResponse>;
      })
    );
  }

  /**
   * fetch the notification by notification Id.
   *
   * show the notification by notification Id for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotification(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;

    /**
     * Currently, &#x27;organization-portal&#x27;, &#x27;admin-portal&#x27; and &#x27;rfscan&#x27; have different API keys for authentication.
     */
    'x-api-key': string;
    context?: HttpContext
  }
): Observable<GetNotificationByIdResponse> {

    return this.getNotification$Response(params).pipe(
      map((r: StrictHttpResponse<GetNotificationByIdResponse>) => r.body as GetNotificationByIdResponse)
    );
  }

}
