import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { ApiNotificationsEndpointService } from '@shure/cloud-apps/notifications/data-access';
import { NotificationsPopupComponent } from '@shure/cloud-apps/notifications/feature-notifications-list';
import { X_API_KEY } from '@shure/cloud-apps/notifications/feature-notifications-list';
import { OktaInterfaceService } from '@shure/shared/angular/okta';

import { AboutDialogComponent } from '../dialogs';
@Component({
	selector: 'sh-top-ribbon',
	templateUrl: './top-ribbon.component.html',
	styleUrls: ['./top-ribbon.component.scss']
})
export class TopRibbonComponent implements OnInit, OnDestroy {
	@Input() public title!: string;
	@Input() public appVersion!: string;
	@Input() public clientVersion!: string;
	@Input() public userRole!: string;
	@Input() public showAccount = true;
	@Input() public showAbout = false;
	@Input() public showNotifications = true;
	@Input() public showPreferences = true;
	@Input() public showShureLogoWhenSignedIn = true;
	@Input() public showSidenavToggleButton = false;
	@Input() public smallDevice$: Observable<boolean> = of(false);
	@Output() toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();

	public userName = '';
	notificationsCount: number | null = null;
	private destroy$: Subject<void> = new Subject<void>();
	private notificationService?: ApiNotificationsEndpointService;

	constructor(
		public oktaIntf: OktaInterfaceService,
		private router: Router,
		private dialogRef: MatDialog,
		private cdr: ChangeDetectorRef,
		private injector: Injector
	) {}

	public ngOnInit(): void {
		this.oktaIntf
			.getUserFirstName$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((userFirstName: string) => {
				this.userName = userFirstName;
			});

		if (this.showNotifications) {
			this.notificationService = this.injector.get(ApiNotificationsEndpointService);
			this.getNotificationsCount();
		}
	}

	getNotificationsCount() {
		if (!this.notificationService) {
			return;
		}

		const params = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			limit: '10',
			offset: '0',
			context: undefined
		};

		this.notificationService
			.getNotifications(params)
			.pipe(
				// eslint-disable-next-line rxjs/no-unsafe-takeuntil
				takeUntil(this.destroy$),
				map((response) => {
					const notifications = response?.items ?? [];
					const unreadNotifications = notifications.filter(
						(notification) => !notification.readAt && !notification.archivedAt
					);
					return unreadNotifications.length;
				}),
				catchError(() => of(0))
			)
			.subscribe((count) => {
				this.notificationsCount = count;
				this.cdr.detectChanges();
			});
	}

	public onSidenavToggle() {
		this.toggleSidenav.emit(true);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public goToAccount(): void {
		if (this.showAccount) {
			this.router.navigateByUrl('/account');
		} else {
			this.router.navigateByUrl('/account/profile');
		}
	}
	public openAboutDialog(): void {
		this.dialogRef.open(AboutDialogComponent, {
			width: '450px',
			data: { title: this.title, appVersion: this.appVersion }
		});
	}
	public openNotificationsPopup(): void {
		this.dialogRef.open(NotificationsPopupComponent, {
			width: '450px',
			data: this.title
		});
	}
	public notifications(): void {
		this.router.navigateByUrl('/notifications-list');
	}

	public async logout(): Promise<void> {
		await this.oktaIntf.signOut();
	}
	public preferences(): void {
		this.router.navigateByUrl('/preferences-settings');
	}

	public onLogoClick(): void {
		this.router.navigateByUrl('/');
	}
	public notificationsPopup() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.width = '360px';
		dialogConfig.data = this.title;

		dialogConfig.position = {
			top: '35px',
			right: '180px'
		};

		this.dialogRef.open(NotificationsPopupComponent, dialogConfig);
	}
}
