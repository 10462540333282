import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatOptionModule } from '@angular/material/core';
import { TranslocoConfig, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';

import { LanguagesService } from './languages.service';
import { translocoConfigFactory } from './transloco-defualt.config';
import { TranslocoHttpLoader } from './transloco-http.loader';

@NgModule({
	imports: [FlexLayoutModule, MatOptionModule, TranslocoModule],
	exports: [TranslocoModule]
})
export class TranslocoRootModule {
	/**
	 * Setup application configuration for transloco.
	 */
	public static forRoot(
		production: boolean,
		config: Partial<TranslocoConfig> = {}
	): ModuleWithProviders<TranslocoRootModule> {
		return {
			ngModule: TranslocoRootModule,
			providers: [
				LanguagesService,
				{
					provide: TRANSLOCO_LOADER,
					useClass: TranslocoHttpLoader
				},
				{
					provide: TRANSLOCO_CONFIG,
					useFactory: () => translocoConfigFactory(production, config)
				}
			]
		};
	}
}
