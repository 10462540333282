import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { NotificationsListComponent } from './notifications-list.component';
import { NotificationsDetailsComponent } from './view/notifications-details/notifications-details.component';
import { NotificationsPopupComponent } from './view/notifications-popup/notifications-popup.component';

@NgModule({
	declarations: [NotificationsListComponent, NotificationsPopupComponent, NotificationsDetailsComponent],
	imports: [
		CommonModule,
		MatTableModule,
		HttpClientModule,
		TranslocoModule,
		MatListModule,
		MatCardModule,
		MatFormFieldModule,
		MatDividerModule,
		MatIconModule,
		MatInputModule,
		RouterModule.forChild([{ path: '', pathMatch: 'full', component: NotificationsListComponent }])
	]
})
export class NotificationsListModule {}
