export type UnsubscribeToMoreCallback = () => void;

export interface SubscribeToMoreManagerConfig {
	subscribe: () => UnsubscribeToMoreCallback;
}

export class SubscribeToMoreManager {
	constructor(private config: SubscribeToMoreManagerConfig) {}

	private currentSubscription: UnsubscribeToMoreCallback | undefined;

	public subscribe(): void {
		if (this.currentSubscription === undefined) {
			this.currentSubscription = this.config.subscribe();
		}
	}

	public unsubscribe(): void {
		if (this.currentSubscription !== undefined) {
			this.currentSubscription();
			this.currentSubscription = undefined;
		}
	}
}
