export function ipAddressComparator(a?: string, b?: string) {
	return makeComparableIPAddress(a) - makeComparableIPAddress(b);
}

export function makeComparableIPAddress(value?: string) {
	if (!value) {
		return -Infinity;
	}

	const retVal = parseInt(value.replace(/[^0-9 ]/g, ''));
	if (isNaN(retVal)) {
		return -Infinity;
	}
	return retVal;
}

export function macAddressComparator(a?: string, b?: string) {
	return makeComparableMacAddress(a ?? '') - makeComparableMacAddress(b ?? '');
}

export function makeComparableMacAddress(value: string): number {
	const numericValue = parseInt(value.replace(/:/g, ''), 16);
	if (isNaN(numericValue)) {
		return Infinity;
	}
	return numericValue;
}
