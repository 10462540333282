import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { AdtdDeviceBehaviorPluginBase } from './adtd.device-behavior';

@Injectable({ providedIn: 'root' })
export class AdtdDesignerDeviceBehaviorPlugin extends AdtdDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
