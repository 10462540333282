import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { Mxn5cDeviceBehaviorPluginBase } from './mxn5c.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxn5cDesignerDeviceBehaviorPlugin extends Mxn5cDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
