import { DeviceModel } from '@shure/shared/models';

/**
 * `DeviceBehaviorPlugin` describes device specific behavior for one or more device(s)
 * and should be used in order to keep device model specific logic out of features.
 */
export interface DeviceBehaviorPlugin<T extends DeviceModel = DeviceModel> {
	/**
	 * Devices described by the plugin.
	 */
	readonly models: T[];
}
