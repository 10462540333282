import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { APP_ENVIRONMENT, AppEnvironment } from '@shure/shared/models';
@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment) {}

	public getTranslation(langPath: string): Observable<Translation> {
		return this.http.get(`./assets/i18n/${langPath}.json?v=${this.appEnv.appVersion}`);
	}
}
