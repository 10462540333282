import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

import { AppEnvironment, APP_ENVIRONMENT } from '@shure/shared/models';

import { InactivityDialogComponent } from './inactivity-dialog/inactivity-dialog.component';
import { InactivityDialogSelection } from './inactivity-dialog/inactivity-dialog.model';

@Injectable({
	providedIn: 'root'
})
export class InactivityService {
	public idleTimeout$ = new Subject();
	public signOutNow$ = new Subject();
	public secondsUntilTimeout$ = new BehaviorSubject<number>(0);
	private appEnv: AppEnvironment;
	private dialogRef: MatDialogRef<InactivityDialogComponent> | null = null;

	constructor(private idleService: Idle, private dialog: MatDialog, @Inject(APP_ENVIRONMENT) appEnv: AppEnvironment) {
		this.appEnv = appEnv;
		this.init();
	}

	public startMonitoring(): void {
		this.idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		this.idleService.watch();
	}

	public stopMonitoring(): void {
		this.idleService.stop();
		this.dialogRef?.close();
	}

	private init(): void {
		this.idleService.setIdle(this.appEnv.sessionInactivity.idleWarnTime); //
		this.idleService.setTimeout(this.appEnv.sessionInactivity.idleGracePeriod); // kicks in after 'idle' expires

		// Set up the main states and their callback handlers:
		// - IdleStart when the user goes idle for a short period of time.
		// - IdleEnd when the user performs a mouse click anywhere after IdleStart (n/a for our implemenation)
		// - TimeoutWarning - goes off every second between IdleStart and Timeout
		// - IdleTimeout when the user has been idle/inactive for a longer period of time.

		this.idleService.onIdleStart.subscribe(() => this.idleStartHandler());
		this.idleService.onIdleEnd.subscribe(() => this.idleEndHandler());
		this.idleService.onTimeoutWarning.subscribe((/*secondsUntilTimeout*/) =>
			this.idleTimeoutWarningHandler(/*secondsUntilTimeout*/));
		this.idleService.onTimeout.subscribe(() => this.idleTimeoutHandler());
	}

	private idleStartHandler(): void {
		// when we display the dialog, we don't want the action of the user moving the mouse
		// onto the dialog to end the idle time. So, we set the "interupts" to an empty array.
		// Once the user acks the dialog, and we restart monitoring, we'll reset the interupts
		// to their default values.

		this.idleService.setInterrupts([]);
		this.displayInactivityDialog();
	}

	// Normally, this would be called after the first idleStart event, but before the idleTimeout event.
	// Since we disable interupts after idleStart user activity no longer triggers this fcn to be called.
	// However, what can and does happen is this... Let's say the user has multipe tabs open. They are all
	// timing in sync. When the Dialog is presented, and the user decides to "remain logged in", this fcn
	// is called for the app instances that didn't dismiss the dialog.
	private idleEndHandler(): void {
		this.dialogRef?.close();
		this.idleService.stop();
		this.startMonitoring();
	}

	// Although this fct receives the "secondsUntilTimeout" value, this value
	// may not be accurate if the browser/tab was minimized (not in focus) for a period
	// of time. So, I"m not using it. Rather, I'm calculating the realSecondsUntilTimeout
	// by comparing the current time with the ng2Idle.main.expiry value stored in local storage.
	// This gives me the accurate/real idleTimeout value.

	private idleTimeoutWarningHandler(/*secondsUntilTimeout: number*/): void {
		// The idle package immediately puts back the "item" in local store if it's deleted.
		// But, just in case it's not there, defalt to the current time if not found.
		const now = moment().unix();
		const expiryValue = localStorage.getItem('ng2Idle.main.expiry') || now * 1000;
		const realIdleTimeoutTime = +expiryValue / 1000;
		const realSecondsUntilTimeout = Math.max(Math.round(realIdleTimeoutTime - now), 0);

		if (now >= realIdleTimeoutTime) {
			/*console.warn({
				'secondsUntilTimeout(lib)': secondsUntilTimeout,
				'secondsUntilTimeout(actual)': realSecondsUntilTimeout,
				'drift': realSecondsUntilTimeout - now,
				'message': 'Forcing timeout. Real Idle Timeout has elapsed.'
			});*/
			this.idleTimeoutHandler();
			return;
		}
		this.secondsUntilTimeout$.next(realSecondsUntilTimeout);
	}

	private idleTimeoutHandler(): void {
		// console.warn('Idle Timeout');
		this.idleService.stop();
		this.idleTimeout$.next(null);
	}

	private displayInactivityDialog(): void {
		this.dialogRef = this.dialog.open(InactivityDialogComponent, this.getMatDialogConfig());
		const subscription = this.dialogRef?.afterClosed()?.subscribe((result: InactivityDialogSelection) => {
			subscription.unsubscribe();
			if (result === InactivityDialogSelection.SignOutNow) {
				this.signOutNow$.next(null);
			} else if (result === InactivityDialogSelection.RemainSignedIn) {
				this.idleEndHandler();
			}
		});
	}

	private getMatDialogConfig(): MatDialogConfig {
		const config = new MatDialogConfig();
		config.disableClose = true; // user must click button in dialog to dismiss it
		config.autoFocus = true; // only recognize clicks in the dialog.
		config.closeOnNavigation = true; // so the dialog goes away if User doesn't close it before being logged off
		return config;
	}
}
