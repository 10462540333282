import { DeviceModel } from '@shure/shared/models';

export interface DeviceImage {
	/**
	 * Image to display for live version of device.
	 */
	live: string;

	/**
	 * Image to display for virtual version of device.
	 */
	virtual: string;
}

/**
 * `DeviceImageProvider`
 */
export abstract class DeviceImageProvider {
	/**
	 * Get images associated with device model.
	 */
	public abstract getImage(model: DeviceModel): DeviceImage;
}
