import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, take } from 'rxjs';

import {
	ChannelNameCheckData,
	DuplicateChannelNameApiService,
	DuplicateChannelNameService
} from './duplicate-channel-name-service';

export class DuplicateNameValidator {
	public static createAsyncValidator(
		service: DuplicateChannelNameApiService | null,
		deviceId: string,
		channelId: string
	): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return DuplicateChannelNameService.getChannelNames$(service, deviceId).pipe(
				take(1),
				map((channelNames: ChannelNameCheckData[]) => {
					return channelNames
						.filter((channel) => channel.id !== channelId)
						.some(
							(channel) =>
								channel.channelName &&
								channel.channelName.toLocaleLowerCase() === control.value?.toLocaleLowerCase()
						)
						? <ValidationErrors>{ duplicate: true }
						: null;
				})
			);
		};
	}
}
