<ng-container *transloco="let t; read: 'components.properties.network'">
	<mat-form-field fxFlex>
		<mat-label> {{ t('ip-mode') }}</mat-label>
		<input matInput type="text" disabled [value]="ipMode" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ 'common.labels.ip-address' | transloco }} </mat-label>
		<input matInput type="text" disabled [value]="ipAddress" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ t('subnet-mask') }}</mat-label>
		<input matInput type="text" disabled [value]="subnetMask" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ t('gateway') }}</mat-label>
		<input matInput type="text" disabled [value]="gateway" />
	</mat-form-field>
	<mat-form-field fxFlex>
		<mat-label> {{ t('mac-address') }} </mat-label>
		<input matInput type="text" disabled [value]="macAddress" />
	</mat-form-field>
</ng-container>
