import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';

import { OktaInterfaceService } from '@shure/shared/angular/okta';

/**
 * ```<sh-carousel>``` is a list-like component used to display the images in carousel
 * in carousel.
 */
@Component({
	selector: 'sh-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {
	@Input() public imageList: string[] = [];
	public currentImageIndex = 0;

	private carouselInterval$ = interval(4000);
	private readonly destroy$ = new Subject<void>();
	public firstName!: string;
	constructor(private changeDetectorRef: ChangeDetectorRef, private oktaIntfService: OktaInterfaceService) {}
	public ngOnInit(): void {
		this.oktaIntfService
			.getUserProfile$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((userProfile) => {
				this.firstName = userProfile.firstName;
			});
		this.carouselInterval$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.currentImageIndex = ++this.currentImageIndex % this.imageList.length;
			this.changeDetectorRef.detectChanges();
		});
	}
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
