export type PendingClaim = {
	id: string;
	macAddress: string;
	serialNumber: string;
	createdAt?: string;
};

export type PendingClaimStatus = {
	id: string;
	macAddress: string;
	serialNumber: string;
	status: 'CREATED' | 'DELETED';
};
