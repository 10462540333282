import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateFormatter'
})

/**
 * DateFormatterPipe
 * (It format all type of time standards, specially for UTC)
 */
export class DateFormatterPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	/**
	 * transform date string to any format
	 * @param value - string represents date string.
	 * @param format - string represents format to convert date by default value 'MM/dd/yyyy'.
	 * @returns
	 */
	transform(value: string, format = 'MM/dd/yyyy'): string {
		value = value ? value : '';
		let dateValue = value;

		// condition check if date string is ISO format convert it to UTC.
		if (value && value.includes('Z')) {
			const dateObject = new Date(value);
			dateValue = `${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateObject
				.getUTCDate()
				.toString()
				.padStart(2, '0')}/${dateObject.getUTCFullYear()}`;
		}

		// if date string is valid then format it as expected.
		if (dateValue) {
			const dateObject = new Date(dateValue);
			return dateObject instanceof Date ? this.datePipe.transform(dateObject, format) || '' : '';
		}
		return value;
	}
}
