/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable, Inject } from '@angular/core';
import { catchError, map, Observable, Subject, Subscription, takeUntil, tap, throwError } from 'rxjs';

import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/shared/angular/apollo';
import { ILogger, jsonFormat } from '@shure/shared/angular/logging';
import { monitorLoginState, OktaInterfaceService } from '@shure/shared/angular/okta';
import {
	AppEnvironment,
	APP_ENVIRONMENT,
	FirmwarePackage,
	UpdateResponse,
	PropertyPanelDevice
} from '@shure/shared/models';

import {
	PropertyPanelDeviceQueryGQL,
	PropertyPanelDeviceFragment,
	DeviceStatus,
	PropertyPanelDeviceSubscriptionGQL,
	NodeChangeType
} from '../../graphql/generated/cloud-sys-api';
import {
	CloudDeviceService,
	mapDeviceModelFromSysApi,
	mapFeatureValueFromSysApi,
	mapFirmwarePackagesFromSysApi,
	mapIpModeFromSysApi,
	mapProxiedTransmitterFromSysApi,
	mapUptimeDurationFromSysApi
} from '../shared/device';

import { DevicePropertyPanelApiService } from './device-property-panel-api.service';

@Injectable({ providedIn: 'root' })
export class DaiDevicePropertyPanelService extends DevicePropertyPanelApiService {
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	private readonly devicesGqlSubscriptions = new SubscriptionManager({
		subscriptionType: 'property-panel',
		create: (config): Subscription => this.createGqlSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceService,
		private readonly propertyPanelDeviceQueryGQL: PropertyPanelDeviceQueryGQL,
		private readonly propertyPanelDeviceSubscriptionGQL: PropertyPanelDeviceSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		super();
		this.logger = logger.createScopedLogger('DaiDevicePropertyPanelService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	private initService() {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
	}

	private suspendService() {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesGqlSubscriptions.deregisterAll();
	}

	/**
	 * Get device by id
	 * @param deviceId
	 * @returns
	 */
	public getDevice$(deviceId: string): Observable<PropertyPanelDevice> {
		this.logger.debug(
			'getDevice$()',
			`propertyPanelDeviceQueryGQL. Setting up watch query. deviceId: ${jsonFormat(deviceId)}`
		);

		return this.propertyPanelDeviceQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false
				},
				{
					errorPolicy: 'ignore',
					returnPartialData: true,
					fetchPolicy: 'cache-only'
				}
			)
			.valueChanges.pipe(
				map((query) => {
					if (query.data.node && 'isDeviceNode' in query.data.node) {
						const device = this.mapPropertyPanelDeviceFromSysApi(query.data.node);
						this.logger.debug(
							'getDevice$()',
							`propertyPanelDeviceQueryGQL watchQuery popped. deviceId: ${jsonFormat(deviceId)}`
						);
						return device;
					}
					this.logger.error('getDevice$()', 'Failed to query propertypanel device', jsonFormat({ deviceId }));
					throw query.error;
				}),
				// establish the per-device nodeChanges subscription, if not already established.
				tap((device) => this.devicesGqlSubscriptions.register([device.id])),
				catchError((error: Error) => {
					this.logger.error(
						'getDevice$()',
						'Failed to query property panel device',
						jsonFormat({
							deviceId,
							error
						})
					);
					return throwError(() => error);
				})
			);
	}

	/**
	 * Tell the service to "forget" about this device. Includes unsubscribing to
	 * the more detailed property subscriptions.
	 * @param nodeId
	 */
	public forgetDevice(nodeId: string) {
		this.devicesGqlSubscriptions.deregister(nodeId);
	}

	/**
	 * Set mute for a device.
	 * @param deviceId
	 * @param mute
	 * @returns
	 */
	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	/**
	 * Set device name.
	 * @param deviceId
	 * @param name
	 * @returns
	 */
	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', jsonFormat({ deviceId, name }));
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	/**
	 * Set device identifying state.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	/**
	 * Reboot a device
	 * @param deviceId
	 * @returns
	 */
	public rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('rebootDevice()', 'Rebooting device', { deviceId });
		return this.cloudDeviceService.rebootDevice$(deviceId);
	}

	/**
	 * Request a device firmware update
	 * @param deviceId
	 * @param firmwarePkg
	 * @returns
	 */
	public override updateFirmware(
		deviceId: string,
		firmwarePkg: FirmwarePackage
	): Observable<UpdateResponse<void, string>> {
		this.logger.trace('updateFirmware()', 'Updating firmware', { deviceId, package: firmwarePkg });
		return this.cloudDeviceService.updateFirmware$([
			{ id: deviceId, firmwarePackageKey: firmwarePkg.key, firmwarePackageVersion: firmwarePkg.version }
		]);
	}

	private createGqlSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		this.logger.debug(
			'createGqlSubscription()',
			`propertyPanelDeviceSubscriptionGQL. Setting up prop panel subscription. deviceId: ${jsonFormat(id)}`
		);

		return this.propertyPanelDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: [
						NodeChangeType.DeviceName,
						NodeChangeType.DeviceIdentify,
						NodeChangeType.DeviceAudioMute,
						NodeChangeType.DeviceUptime,
						NodeChangeType.DeviceAvailablePackages,
						NodeChangeType.DeviceDoubleStuffProxiedTransmitters
					],
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.logger.debug(
						'createGqlSubscription()',
						`propertyPanelDeviceSubscriptionGQL. Received Update. deviceId: ${jsonFormat(id)}`
					);
				},
				complete: () => {
					this.logger.debug('propertyPanelDeviceSubscriptionGQL', 'Completed', {
						id
					});
				},
				error: (error) => {
					this.logger.error(
						'propertyPanelDeviceSubscriptionGQL',
						'Encountered error',
						jsonFormat({
							id,
							error
						})
					);
					retryCallback();
				}
			});
	}

	private mapPropertyPanelDeviceFromSysApi(deviceSysApi: PropertyPanelDeviceFragment): PropertyPanelDevice {
		const {
			id,
			virtual,
			description: { features: descriptionFeatures },
			interface: { model: deviceModel },
			features: {
				name,
				audioMute,
				serialNumber,
				identify,
				danteAudioNetwork,
				audioNetwork,
				controlNetwork,
				firmware,
				uptime,
				availablePackages,
				doublestuffProxiedTransmitters
			}
		} = deviceSysApi;
		const device: PropertyPanelDevice = {
			id: id,
			name: mapFeatureValueFromSysApi(name?.name),
			model: mapDeviceModelFromSysApi(deviceModel ?? ''),
			isVirtual: virtual,
			features: {
				mute: {
					muted: audioMute?.muted || false,
					isMissing: !descriptionFeatures.audioMute.supported
				},
				serialNumber: {
					serialNumber: mapFeatureValueFromSysApi(serialNumber?.serialNumber),
					isMissing: !descriptionFeatures.serialNumber.supported
				},
				identify: {
					identifying: identify?.identifying || false,
					isMissing: !descriptionFeatures.identify.supported
				},
				danteName: {
					danteName: mapFeatureValueFromSysApi(danteAudioNetwork?.name),
					isMissing: !descriptionFeatures.danteAudioNetwork.supported
				},
				audioNetwork: {
					gateway: mapFeatureValueFromSysApi(audioNetwork?.interface.gateway),
					ipAddress: mapFeatureValueFromSysApi(audioNetwork?.interface.ipAddress),
					ipMode: mapIpModeFromSysApi(audioNetwork?.interface.ipMode),
					subnetMask: mapFeatureValueFromSysApi(audioNetwork?.interface.subnetMask),
					macAddress: mapFeatureValueFromSysApi(audioNetwork?.interface.macAddress),
					isMissing: !descriptionFeatures.audioNetwork.supported
				},
				controlNetwork: {
					ipMode: mapIpModeFromSysApi(controlNetwork?.interface.ipMode),
					ipAddress:
						deviceSysApi.status === DeviceStatus.Error
							? mapFeatureValueFromSysApi(deviceSysApi.protocol.dmp?.ipAddress)
							: mapFeatureValueFromSysApi(controlNetwork?.interface.ipAddress),
					subnetMask: mapFeatureValueFromSysApi(controlNetwork?.interface.subnetMask),
					gateway: mapFeatureValueFromSysApi(controlNetwork?.interface.gateway),
					macAddress: mapFeatureValueFromSysApi(controlNetwork?.interface.macAddress),
					isMissing: !descriptionFeatures.controlNetwork.supported
				},
				firmware: {
					version: mapFeatureValueFromSysApi(firmware?.version),
					valid: firmware?.valid || false,
					isMissing: !descriptionFeatures.firmware.supported
				},
				uptime: {
					uptime: mapUptimeDurationFromSysApi(uptime?.uptime),
					isMissing: !descriptionFeatures.uptime.supported
				},
				availablePackages: {
					primaryPackages: mapFirmwarePackagesFromSysApi(availablePackages),
					isMissing: !descriptionFeatures.availablePackages.supported
				},
				doublestuffProxiedTransmitters: {
					mic1: mapProxiedTransmitterFromSysApi(doublestuffProxiedTransmitters?.mic1),
					mic2: mapProxiedTransmitterFromSysApi(doublestuffProxiedTransmitters?.mic2),
					isMissing: !descriptionFeatures.doublestuffProxiedTransmitters.supported
				}
			}
		};

		return device;
	}
}
