<nav *ngIf="!collapsed; else collapsedView" mat-tab-nav-bar color="accent" animationDuration="0ms">
	<div [style.padding-right]="stickyButtons ? '32px' : '0'" #tabsRef>
		<a
			*ngFor="let tab of tabs"
			mat-tab-link
			[id]="tab.id"
			[routerLink]="tab.routerLink"
			queryParamsHandling="preserve"
			routerLinkActive
			#rla="routerLinkActive"
			[active]="rla.isActive"
			[disabled]="tab.disabled"
			[title]="tab.label"
		>
			{{ tab.label }}
		</a>
	</div>

	<div class="sh-sticky-button-group" *ngIf="stickyButtons" #stickyButtonsRef>
		<ng-template *ngTemplateOutlet="stickyButtons.template"></ng-template>
	</div>

	<span class="sh-nav-tabs-spacer"></span>

	<div #charmsRef>
		<a
			*ngFor="let charm of charms"
			class="sh-nav-charm"
			mat-tab-link
			mat-icon
			[id]="charm.id"
			[routerLink]="charm.routerLink"
			queryParamsHandling="preserve"
			routerLinkActive
			#rla2="routerLinkActive"
			[active]="rla2.isActive"
			[disabled]="charm.disabled"
			[title]="charm.label"
		>
			<mat-icon fontSet="prism-mat" [fontIcon]="charm.icon"> </mat-icon>
		</a>
	</div>
</nav>

<ng-template #collapsedView>
	<div *ngIf="stickyButtons" #stickyButtonsRef>
		<ng-template *ngTemplateOutlet="stickyButtons.template"></ng-template>
	</div>

	<span class="sh-nav-tabs-spacer"></span>

	<button
		*ngIf="activeNavItem$ | async as activeItem"
		id="sh-nav-tabs-menu-toggle"
		class="sh-nav-tab-menu-button"
		[matMenuTriggerFor]="navMenu"
		mat-button
		#menuRef
	>
		<mat-icon *ngIf="activeItem?.icon" fontSet="prism-mat" [fontIcon]="activeItem?.icon ?? ''"> </mat-icon>
		<span>{{ activeItem?.label }}</span>
		<mat-icon fontSet="prism-mat" fontIcon="arrow_drop_down"> </mat-icon>
	</button>

	<mat-menu yPosition="above" #navMenu>
		<button
			*ngFor="let tab of tabs"
			mat-menu-item
			[id]="tab.id"
			[routerLink]="tab.routerLink"
			[disabled]="tab.disabled"
		>
			{{ tab.label }}
		</button>
		<mat-divider></mat-divider>
		<button
			*ngFor="let charm of charms"
			mat-menu-item
			[id]="charm.id"
			[routerLink]="charm.routerLink"
			[disabled]="charm.disabled"
		>
			<mat-icon fontSet="prism-mat" [fontIcon]="charm.icon"> </mat-icon>
			{{ charm.label }}
		</button>
	</mat-menu>
</ng-template>
