import { LogEntry } from '../models';

/**
 * An outlet responsible for the final logging of entries or passing them to an external system.
 */
export abstract class LoggerSink {
	/**
	 * The name of the sink.
	 * Used to look up SinkConfig in LoggingConfig.
	 */
	public readonly name: string = '';

	/**
	 * Final outlet for logging entries or passing them to an external system.
	 */
	public abstract log(logEntry: LogEntry): void;
}
