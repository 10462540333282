import { FormControl, ValidationErrors } from '@angular/forms';

export const consecutiveSpacesRegex = /\s{2,}/;

export class WhiteSpaceValidator {
	public static valueHasSpaceAtFirstOrLastCharacter(control: FormControl): ValidationErrors | null {
		return control.value && (control.value.startsWith(' ') || control.value.endsWith(' '))
			? { spaceAsFirstOrLastCharacter: true }
			: null;
	}

	public static valueHasConsecutiveSpaces(control: FormControl): ValidationErrors | null {
		return control.value && consecutiveSpacesRegex.test(control.value) ? { consecutiveSpaces: true } : null;
	}
}
