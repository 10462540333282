import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

import { OktaInterfaceService } from '@shure/shared/angular/okta';

import { FormDataDetails, FormTypes, IgniteDialogData } from './dialog-box.model';

@Component({
	selector: 'sh-dialog-box-component',
	templateUrl: './dialog-box.component.html',
	styleUrls: ['./dialog-box.component.scss']
})
export class ShIgniteDialogBoxComponent implements OnInit, OnDestroy {
	public action: string | undefined;
	public dialogData: IgniteDialogData | undefined;
	public formGroup: FormGroup = new FormGroup({});
	public formData: FormDataDetails[] | undefined = [];
	public title = '';
	public formType = FormTypes;
	public minDate: Date;
	public domainsList!: string[];
	private readonly destroy$ = new Subject<void>();
	public organizationName!: string;
	public isFormChanged = true;

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<ShIgniteDialogBoxComponent>,
		private oktaIntfService: OktaInterfaceService
	) {
		this.minDate = new Date();
	}

	public ngOnInit(): void {
		this.oktaIntfService
			.getUserProfile$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((userProfile) => {
				this.organizationName = userProfile.orgName;
			});
		this.formData = this.dialogData?.formData;
		this.action = this.dialogData?.action;

		this.formGroup = this.formBuilder.group({});
		this.formData?.forEach((formControlDetail) => {
			this.formGroup.addControl(formControlDetail.name, new FormControl('', formControlDetail.validators));
			if (formControlDetail.disabled) {
				this.formGroup.controls[formControlDetail.name].disable();
			}
		});

		if (this.formGroup.get('groupName')) {
			this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
				// eslint-disable-next-line dot-notation
				this.isFormChanged = value.groupName !== this.dialogData?.data?.['name'];
			});
			// eslint-disable-next-line dot-notation
			this.formGroup.get('groupName')?.setValue(this.dialogData?.data?.['name']);
		}

		if (this.dialogData?.data) {
			this.formGroup.patchValue(this.dialogData?.data);
		}
	}

	public doAction(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close({ data: this.formGroup.value });
		}
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * Lifecycle hook that is called when the component is being destroyed.
	 * It unsubscribes from observables and cleans up any resources.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
