<div *ngIf="!collapsed; else collapsedView" #buttonsGroupRef>
	<button *ngFor="let btn of buttons" mat-raised-button [id]="btn.id" [color]="btn.color" (click)="btn.click.emit()">
		{{ btn.label }}
	</button>
</div>

<div class="sh-sticky-button-group" *ngIf="stickyButtons" #stickyButtonsRef>
	<ng-template *ngTemplateOutlet="stickyButtons.template"></ng-template>
</div>

<ng-template #collapsedView>
	<div class="sh-nav-button-group-collapsed">
		<button
			*ngFor="let btn of buttons"
			mat-fab
			[id]="btn.id"
			[color]="btn.color"
			[matTooltip]="btn.label"
			matTooltipPosition="left"
			(click)="btn.click.emit()"
		>
			<mat-icon fontSet="prism-mat" [fontIcon]="btn.icon"> </mat-icon>
		</button>
	</div>
</ng-template>
