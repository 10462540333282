import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { DEFAULT_AVAILABLE_LANGUAGES } from './transloco-defualt.config';

const locale = 'locale';
const englishId = 'en';
@Injectable({ providedIn: 'root' })
export class LanguagesService {
	public static readonly systemDefaultCultureCode = 'en';

	constructor(private translocoService: TranslocoService) {}

	public setLocale(cultureCode: string): boolean {
		const storedLocale = window.localStorage.getItem(locale);

		// Only change if different value than stored
		if (locale !== cultureCode) {
			// Store locale id in local storage:
			window.localStorage.setItem(locale, cultureCode);
			this.translocoService.setActiveLang(cultureCode);
		}

		return storedLocale !== cultureCode;
	}

	public getPreferredCultureCode(): string {
		const storedLocale = window.localStorage.getItem(locale);
		let newLanguage = '';

		if (!storedLocale) {
			newLanguage = this.adjustLanguageForDialects(window.navigator.language);
		} else {
			newLanguage = this.adjustLanguageForDialects(<string>storedLocale);
		}

		if (newLanguage !== storedLocale) {
			this.setLocale(newLanguage);
		}
		this.translocoService.setActiveLang(newLanguage);

		return newLanguage;
	}

	private adjustLanguageForDialects(languageCode: string): string {
		// first, check if the languageCode pass in is a direct match. If so, we're good.
		for (let i = 0; i < DEFAULT_AVAILABLE_LANGUAGES.length; i++) {
			if (DEFAULT_AVAILABLE_LANGUAGES[i].id === languageCode) {
				return languageCode;
			}
		}

		// next, check if the language Code is a dialect, but we only support the primary
		// E.g. en-US and en-GB would get translated to en
		for (let i = 0; i < DEFAULT_AVAILABLE_LANGUAGES.length; i++) {
			if (languageCode.indexOf(`${DEFAULT_AVAILABLE_LANGUAGES[i].id}-`) !== -1)
				return DEFAULT_AVAILABLE_LANGUAGES[i].id;
		}

		// else, default to English as a last resort
		return englishId;
	}
}
