/**
 * Represents an abstract constructor
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type AbstractConstructorType<T> = Function & { prototype: T };

/**
 * Represents a concrete newable class.
 *
 * Frequently used when binding interfaces with inversify.
 */
export type ConstructorType<T> = new (...args: unknown[]) => T;

/**
 * Represents a newable class of type 'T'.
 */
export type Type<T> = AbstractConstructorType<T> | ConstructorType<T>;
