/* eslint-disable indent */
import { TranslocoService } from '@ngneat/transloco';

import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';
import { DeviceModel } from '@shure/shared/models';

/**
 * `Ad600DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export abstract class Ad600DeviceBehaviorPluginBase implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.AD600];

	constructor(private readonly transloco: TranslocoService) {}

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.AD600:
				return {
					lightsBackground: '',
					lightsOverlay: '',
					live: '',
					virtual: '',
					liveBack: '',
					virtualBack: ''
				};
			default:
				return undefined;
		}
	}
}
