<div [ngClass]="{ 'sh-header-toolbar-breadcrumb-container': breadCrumb.length }">
	<mat-toolbar
		color="primary"
		[ngClass]="{ 'sh-header-toolbar-breadcrumb': breadCrumb.length }"
		id="sh-header-toolbar-info"
		*transloco="let t"
	>
		<div id="sh-header-toolbar-left-content-div">
			<ng-container *ngIf="showSidenavToggleButton && isSmallDevice">
				<button id="sh-header-toolbar-menu-button" mat-icon-button (click)="onSidenavToggle()">
					<mat-icon id="sh-header-toolbar-menu-icon" fontSet="prism-mat" fontIcon="menu"></mat-icon>
				</button>
			</ng-container>
			<sh-breadcrumbs
				*ngIf="breadCrumb.length"
				[breadcrumbs]="breadCrumb"
				(breadcrumbClick)="breadCrumbRedirect($event)"
			></sh-breadcrumbs>
		</div>
		<div id="sh-header-toolbar-right-content-div">
			<div id="sh-header-toolbar-feature-button-div" *ngIf="showFeatureButton">
				<button
					mat-button
					color="accent"
					onclick="this.blur()"
					id="sh-header-toolbar-feature-request-button"
					(click)="featureRequest()"
				>
					{{ t('feature-request') }}
				</button>
			</div>
			<div id="sh-header-toolbar-profile-container-div">
				<div id="sh-header-toolbar-profile-div" [matMenuTriggerFor]="menu">
					{{ firstNameChar + lastNameChar }}
				</div>
			</div>
		</div>

		<mat-menu #menu="matMenu" id="sh-header-toolbar-user-menu">
			<button mat-menu-item [routerLink]="'account'" id="sh-header-toolbar-button-account">
				<span>{{ t('my-account') }}</span>
			</button>
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="logout()" id="sh-header-toolbar-button-logout">
				<span id="sh-header-toolbar-menu-sign-out">{{ t('sign-out') }} </span>
			</button>
		</mat-menu>
	</mat-toolbar>
</div>
