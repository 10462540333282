/* eslint-disable no-console */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EMPTY, Observable, Subject, catchError, map, of, switchMap, take, takeUntil } from 'rxjs';

import {
	ApiNotificationsEndpointService,
	GetNotificationByIdResponse
} from '@shure/cloud-apps/notifications/data-access';

import { X_API_KEY } from '../../domain/apiKeys';
import { FormatDate } from '../../domain/utils/format-date';

@Component({
	selector: 'sh-notifications-details',
	templateUrl: './notifications-details.component.html',
	styleUrls: ['./notifications-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class NotificationsDetailsComponent implements OnInit, OnDestroy {
	checkPage = false;
	notifications$: Observable<GetNotificationByIdResponse[] | null> = EMPTY;
	notificationId = '';
	formatDateService: FormatDate = new FormatDate();
	public readonly destroy$ = new Subject<void>();
	formattedMessage = 's';
	constructor(
		// public dialogRef: MatDialog,
		private dialogRef: MatDialogRef<NotificationsDetailsComponent>,
		private notificationService: ApiNotificationsEndpointService,
		public changeDetectorRef: ChangeDetectorRef,
		public sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data: { notification: string; checkPage: boolean }
	) {}

	ngOnInit(): void {
		this.notificationId = this.data.notification;
		this.checkPage = this.data.checkPage;

		this.loadDetails();
	}

	formatAndHighlightLinks(rawText: string): SafeHtml {
		const formattedText = rawText.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

		return this.sanitizer.bypassSecurityTrustHtml(formattedText);
	}

	loadDetails(): void {
		const params = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			notificationId: this.notificationId,
			context: undefined
		};

		this.notifications$ = this.notificationService.getNotification(params).pipe(
			switchMap((response: GetNotificationByIdResponse | null) => {
				if (response) {
					return of([response]);
				}
				return of(null);
			})
		);
	}

	closePopup() {
		this.dialogRef.close();
	}
	markAsArchive(selectedId: string): void {
		const data = {
			id: selectedId,
			isArchived: true
		};
		const requestData = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			context: undefined,
			body: [data]
		};
		this.notificationService
			.postNotifications(requestData)
			.pipe(
				switchMap(() => this.notifications$),
				take(1),
				map((notifications) => {
					if (notifications) {
						return notifications.map((notification) => {
							if (selectedId.includes(notification.id)) {
								return { ...notification, isArchived: true };
							}
							return notification;
						});
					}
					return [];
				}),
				catchError((_error) => {
					return of([]);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				this.notifications$ = of(updatedNotifications);
				this.dialogRef.close({ refreshNotifications: true });
			});
	}

	markAsRead(selectedId: string) {
		const data = {
			id: selectedId,
			isRead: true
		};
		const requestData = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			context: undefined,
			body: [data]
		};
		this.notificationService
			.postNotifications(requestData)
			.pipe(
				switchMap(() => this.notifications$),
				take(1),
				map((notifications) => {
					if (notifications) {
						return notifications.map((notification) => {
							if (selectedId.includes(notification.id)) {
								return { ...notification, isRead: true };
							}
							return notification;
						});
					}
					return [];
				}),
				catchError((_error) => {
					return of([]);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				this.notifications$ = of(updatedNotifications);
				this.closePopup();
			});
	}

	deleteNotification() {
		this.dialogRef.close();
	}
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
