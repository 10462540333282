/**
 * Returns the number of days between date range.
 */
export function getIntervalBetweenDates(startDate: string, endDate: string): number {
	const startDateWithDayEndTime = new Date(startDate).setHours(23, 59, 59, 999);
	const endDateWithDayEndTime = new Date(endDate).setHours(23, 59, 59, 999);

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = endDateWithDayEndTime - startDateWithDayEndTime;

	// Convert milliseconds to days
	return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
}
