import { Subscription } from 'rxjs';

export interface SubscriptionManagerConfigCreate {
	id: string;
	retryCallback: () => void;
}
export interface SubscriptionManagerConfig {
	subscriptionType: 'inventory-devices' | 'property-panel';
	create: (config: SubscriptionManagerConfigCreate) => Subscription;
	retryWaitMs: number;
	maxRetryAttempts: number;
}

interface SubscriptionInfo {
	subscription: Subscription;
	retryAttempts: number;
}
export class SubscriptionManager {
	private readonly subscriptions = new Map<string, SubscriptionInfo>();

	constructor(private readonly config: SubscriptionManagerConfig) {}

	public register(keys: string[], deregKeysNotInInputList = false) {
		// first, see if we should remove any keys not in the requested set of keys.
		let numRegistered = 0;
		if (deregKeysNotInInputList) {
			const currentKeys = Array.from(this.subscriptions.keys());
			const keysToDereg = currentKeys.filter((ck) => !keys.includes(ck));
			keysToDereg.forEach((key) => {
				this.deregister(key);
			});
		}

		// now, add the requested keys
		keys.forEach((key) => {
			const subscriptionInfo = this.subscriptions.get(key);
			if (subscriptionInfo) {
				// shouldn't happen, but just in case.
				if (subscriptionInfo.subscription.closed) {
					subscriptionInfo.retryAttempts = 0;
					this.reCreateSubscription(key);
				}
			} else {
				const subscription = this.config.create({
					id: key,
					retryCallback: () => this.handleSubscriptionRetry(key)
				});
				this.subscriptions.set(key, { subscription, retryAttempts: 0 });

				numRegistered++;
			}
		});
		return numRegistered;
	}

	public deregister(key: string) {
		let numDeregistered = 0;
		const subscriptionInfo = this.subscriptions.get(key);
		if (subscriptionInfo) {
			this.subscriptions.delete(key);
			subscriptionInfo.subscription.unsubscribe();
			numDeregistered++;
		}
		return numDeregistered;
	}

	public deregisterAll(): void {
		this.subscriptions.forEach((subscriptionInfo, _) => {
			subscriptionInfo.subscription.unsubscribe();
		});
		this.subscriptions.clear();
	}

	private handleSubscriptionRetry(key: string): void {
		const subscriptionInfo = this.subscriptions.get(key);
		if (!subscriptionInfo || subscriptionInfo.retryAttempts >= this.config.maxRetryAttempts) {
			return;
		}

		setTimeout(() => {
			this.reCreateSubscription(key);
		}, this.config.retryWaitMs);
	}

	private reCreateSubscription(key: string): void {
		const subscriptionInfo = this.subscriptions.get(key);
		if (subscriptionInfo) {
			subscriptionInfo.subscription.unsubscribe();
			const subscription = this.config.create({
				id: key,
				retryCallback: () => this.handleSubscriptionRetry(key)
			});
			this.subscriptions.set(key, { subscription, retryAttempts: ++subscriptionInfo.retryAttempts });
		}
	}
}
