/**
 * A tick interval represents a range, linear or non-linear, on the component
 * and will be added as a set of tick marks.
 */
export interface TickInterval {
	/**
	 * Percent the interval starts from.
	 */
	percent: number;

	/**
	 * Value range the interval starts from.
	 */
	value: number;

	/**
	 * Label displayed next to the tick.
	 */
	label: string;
}

export type CustomTick =
	| number
	| {
			value: number;
			label: string;
	  };
