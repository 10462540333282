import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { WhiteSpaceValidator } from './whitespace.validator';

export enum InlineDeviceNameValidationRules {
	MaxLength = 31,
	Regex = '^[a-zA-Z0-9][a-zA-Z0-9-]*$'
}

export enum RoomNameValidationRules {
	MaxLength = 64,
	Regex = '^[a-zA-Z0-9 _()]*$'
}

export enum RoomPasswordValidationRules {
	MinLength = 8,
	MaxLength = 64
}

export enum RoomDescriptionValidationRules {
	MaxLength = 125
}

export function getDeviceNameValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.maxLength(InlineDeviceNameValidationRules.MaxLength),
		Validators.pattern(InlineDeviceNameValidationRules.Regex)
	];
}

export function getRoomNameValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.maxLength(RoomNameValidationRules.MaxLength),
		Validators.pattern(RoomNameValidationRules.Regex),
		WhiteSpaceValidator.valueHasConsecutiveSpaces,
		WhiteSpaceValidator.valueHasSpaceAtFirstOrLastCharacter
	];
}

export function getRoomPasswordValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.minLength(RoomPasswordValidationRules.MinLength),
		Validators.maxLength(RoomPasswordValidationRules.MaxLength)
	];
}

export function getRoomDescriptionValidators(): ValidatorFn[] {
	return [Validators.maxLength(RoomDescriptionValidationRules.MaxLength)];
}

export function getNumberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		if (isNaN(value)) {
			return <ValidationErrors>{ numberinvalid: true };
		}

		return null;
	};
}

export function numberInRangeValidator(range: { min: number; max: number }): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (value === null || (value >= range.min && value <= range.max)) {
			return null;
		}

		return <ValidationErrors>{ outsideRange: true };
	};
}

/**
 * Validator function for checking the validity of a license group name.
 * Ensures that the name starts with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function licenseGroupNameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		const startsWithLetterOrNumber = /^[a-zA-Z0-9]/.test(control.value);
		const validPattern = /^[a-zA-Z0-9_-\s]*$/;
		const isValidFormat = validPattern.test(control.value);

		if (!startsWithLetterOrNumber) {
			return { invalidStart: true };
		}

		if (!isValidFormat) {
			return { invalidCharacters: true };
		}

		return null;
	};
}

/**
 * Validator function for checking the validity of a name.
 * Ensures that the name starts with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function nameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		const startsWithLetterOrNumber = /^[a-zA-Z0-9]/.test(control.value);
		const endsWithLetterOrNumber = /[a-zA-Z0-9]$/.test(control.value);
		const validPattern = /^[a-zA-Z0-9_-\s]*$/;
		const isValidFormat = validPattern.test(control.value);

		if (!isValidFormat) {
			return { invalidCharacters: true };
		}
		if (!startsWithLetterOrNumber || !endsWithLetterOrNumber) {
			return { invalidStart: true };
		}

		return null;
	};
}

/**
 * Validator function for checking the validity of a user group name.
 * Ensures that the name starts/ends with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function userGroupNameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		const startsWithLetterOrNumber = /^[a-zA-Z0-9]/.test(control.value);
		const endsWithLetterOrNumber = /[a-zA-Z0-9]$/.test(control.value);
		const validPattern = /^[a-zA-Z0-9 _-\s]*$/;
		const isValidFormat = validPattern.test(control.value);
		if (control.value.length <= 0) {
			return { emptyInput: true };
		}
		if (!startsWithLetterOrNumber) {
			return { invalidStart: true };
		}
		if (!endsWithLetterOrNumber) {
			return { invalidEnd: true };
		}
		if (control.value.length > 31) {
			return { invalidLength: true };
		}
		if (!isValidFormat) {
			return { invalidCharacters: true };
		}
		return null;
	};
}

// exporting the regex so it can be used outside the context of a Validator
export const macAddressRegEx = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export function getMacAddresValidators(): ValidatorFn[] {
	return [Validators.required, Validators.pattern(macAddressRegEx)];
}

// exporting the regex so it can be used outside the context of a Validator
export const serialNumberRegEx = /^[A-Za-z0-9_]+$/;
export function getSerialNumberValidators(): ValidatorFn[] {
	return [Validators.required, Validators.pattern(serialNumberRegEx)];
}
