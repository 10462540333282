import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { IdButtonComponent } from './id-button.component';

@NgModule({
	imports: [CommonModule, MatButtonModule],
	declarations: [IdButtonComponent],
	exports: [IdButtonComponent]
})
export class IdButtonModule {}
