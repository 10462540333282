import { getBrowserLang, LangDefinition, TranslocoConfig } from '@ngneat/transloco';

/**
 * List of default languages configured with transloco.
 */
export const DEFAULT_AVAILABLE_LANGUAGES: LangDefinition[] = [
	{ id: 'en', label: 'English' },
	{ id: 'de', label: 'Deutsch' },
	{ id: 'es', label: 'Español' },
	{ id: 'fr', label: 'Français' },
	{ id: 'it', label: 'Italiano' },
	{ id: 'pt', label: 'Portuguese (Brazil)' },
	{ id: 'ru', label: 'Pусский' },
	{ id: 'zh', label: '中文（简体）' },
	{ id: 'ja', label: '日本語' },
	{ id: 'ko', label: '한국어' },
	{ id: 'ar', label: 'العربية' }
];

/**
 * Default configuration used for setting up transloco.
 */
export const TRANSLOCO_DEFAULT_CONFIG: TranslocoConfig = {
	defaultLang: getBrowserLang() || 'en',
	fallbackLang: 'en',
	availableLangs: DEFAULT_AVAILABLE_LANGUAGES,
	prodMode: false,
	reRenderOnLangChange: false,
	flatten: { aot: false },
	missingHandler: {
		logMissingKey: true,
		allowEmpty: false,
		useFallbackTranslation: false // true // TODO: LBF 1/12/2023 - set to false to see where translations are missing
	}
};

/**
 * Factory function for setting up transloco configuration.
 */
export function translocoConfigFactory(production: boolean, config: Partial<TranslocoConfig>): TranslocoConfig {
	return {
		...TRANSLOCO_DEFAULT_CONFIG,
		prodMode: production,
		flatten: {
			aot: production
		},
		...config
	};
}
