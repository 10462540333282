<div id="main-div" *transloco="let t">
	<div id="sh-my-account-nav-logo-div">
		<button id="sh-my-account-nav-logo-button" [routerLink]="navigateTo" mat-button>
			<img id="sh-my-account-nav-logo-img" src="../assets/images/shure_logo_icon.svg" />
			<span id="sh-my-account-nav-logo-span">{{ t('shure-cloud') }}</span>
		</button>
	</div>
	<mat-divider></mat-divider>
	<div id="sh-my-account-nav-beta-div">
		<span id="sh-my-account-nav-title-span">{{ t('my-account') }}</span>
		<span id="sh-my-account-nav-beta-span">{{ t('beta') }}</span>
	</div>
	<mat-nav-list>
		<mat-list-item
			[routerLink]="['account']"
			[routerLinkActiveOptions]="{ exact: true }"
			routerLinkActive="mat-menu-item-active"
		>
			<mat-icon fontSet="prism-mat" fontIcon="account_circle" id="sh-my-account-nav-profile-icon"></mat-icon>
			<span class="mat-list-text" id="sh-my-account-nav-profile-span">{{ t('profile') }}</span>
		</mat-list-item>
		<mat-list-item [routerLink]="['account/marketing-preference']" routerLinkActive="mat-menu-item-active">
			<div class="marketing-icon"></div>

			<span class="mat-list-text" id="sh-my-account-nav-preference-span">{{ t('marketing-preferences') }}</span>
		</mat-list-item>
		<mat-list-item *ngIf="showSideNavItem" routerLinkActive="mat-menu-item-active" disabled>
			<mat-icon fontSet="prism-mat" fontIcon="storage" id="sh-my-account-nav-address-icon"></mat-icon>
			<span class="mat-list-text" id="sh-my-account-nav-address-span"> {{ t('addresses') }} </span>
		</mat-list-item>
		<mat-list-item *ngIf="showSideNavItem" routerLinkActive="mat-menu-item-active" disabled>
			<mat-icon fontSet="prism-mat" fontIcon="info" id="sh-my-account-nav-info-icon"></mat-icon>
			<span class="mat-list-text" id="sh-my-account-nav-info-span"> {{ t('about') }} </span>
		</mat-list-item>
	</mat-nav-list>
</div>
