import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// This is a convenience class/directive to make it simpler for components to automatically unsubscribe from long-running subscriptions when the
// component is destroyed.  Here's how to use it:
//
// 1) Your component shoul extend this one
//
//    export class MyComponent extends DestroyerDirective
//
//    Be sure to call super() in your components constructor
//
// 2) use pipe(takeUntil(this.destroy$)) in your long-running subscriptions
//
//    someObservable.pipe(takeUntil(this.destroy$)).subscribe( ... )
//
@Directive()
export class DestroyerDirective implements OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();

	ngOnDestroy() {
		// Unsubscribe from whatever used takeUntil(destroy$)
		this.destroy$.next(true);
		// Now let's also unsubscribe from the subject itself:
		this.destroy$.complete();
	}
}
