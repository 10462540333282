import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';

import {
	DEVICE_DISCOVERY_CLAIMING_SERVICE,
	DetectionTokenApiService,
	DeviceClaimingApiService,
	DeviceDiscoveryApiService,
	DevicePropertyPanelApiService,
	InventoryDevicesApiService
} from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';

import { DaiMockupDeviceClaimingDiscoveryService } from './claiming/dai-mockup-device-claiming-discovery.service';
import { DaiMockDeviceClaimingService } from './claiming/dai-mockup-device-claiming.service';
import { DaiMockupService } from './dai-mockup.service';
import { DaiMockupDetectionTokenService } from './detection/dai-mockup-detection-token.service';
import { DaiMockupDevicePropertyPanelService } from './device-property-panel/dai-mockup-device-property-panel.service';
import { DaiMockupDeviceDiscoveryService } from './discovery/dai-mockup-device-discovery.service';
import { MockupInventory } from './mockup/inventory';

@NgModule({
	imports: [ApolloModule],
	providers: [
		MockupInventory,
		{
			provide: InventoryDevicesApiService,
			useExisting: DaiMockupService
		},
		{
			provide: DevicePropertyPanelApiService,
			useExisting: DaiMockupDevicePropertyPanelService
		},
		{
			provide: DetectionTokenApiService,
			useExisting: DaiMockupDetectionTokenService
		},
		{
			provide: DeviceClaimingApiService,
			useExisting: DaiMockDeviceClaimingService
		},
		{
			provide: DeviceDiscoveryApiService,
			useExisting: DaiMockupDeviceDiscoveryService
		},
		{
			provide: DEVICE_DISCOVERY_CLAIMING_SERVICE,
			useClass: DaiMockupDeviceClaimingDiscoveryService
		}
	]
})
export class DaiCloudMockupModule {}
