import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';

import { OrganizationsStoreService } from '@shure/cloud-apps/organizations/store';
import { IS_SKIP_ORG_HEADER } from '@shure/shared/angular/okta';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
	public defaultOrgId = '';
	constructor(private organizationStore: OrganizationsStoreService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
		//subscribed to getSelectedOrganization$ observable to get latest selected organization id
		this.organizationStore.getSelectedOrganization$().subscribe((orgData) => {
			this.defaultOrgId = orgData.orgId;
		});
	}
	public intercept(req: HttpRequest<unknown>, delegate: HttpHandler): Observable<HttpEvent<unknown>> {
		// TODO: LBF 11/9/22 - use OktaService
		const btoken: string | null = window.localStorage.getItem('okta-token-storage');
		const btokenValue = btoken ? JSON.parse(btoken)?.idToken?.idToken : null;
		let reqHeaders = req.headers;
		if (req.url.indexOf('graphql') < 0) {
			if (!req.url.includes(`${this.oktaAuth.options.issuer}`)) {
				reqHeaders = reqHeaders.set('Authorization', `Bearer ${btokenValue}`);
				if (req.method !== 'DELETE') {
					reqHeaders.set('Content-Type', 'application/json');
				}
			}

			if (!req.context.get(IS_SKIP_ORG_HEADER)) {
				reqHeaders = reqHeaders.set('Current-Org-Id', `${this.defaultOrgId}`);
			}
		}
		const request = req.clone({ headers: reqHeaders });

		return delegate.handle(request);
	}
}
