import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, take } from 'rxjs';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { OrganizationsStoreService } from '@shure/cloud-apps/organizations/store';

@Pipe({ name: 'version', pure: false })
export class VersionPipe implements PipeTransform {
	constructor(private organizationsStoreService: OrganizationsStoreService) {}
	public transform(url: string): Observable<string> {
		// This version is the BE version retrieved from RBAC BE.
		return this.organizationsStoreService.getVersion().pipe(
			take(1),
			map((versionNumber: string) => {
				return url + `?v=${versionNumber}`;
			})
		);
	}
}
