import { Injectable } from '@angular/core';

import { ApolloCacheFactory, ApolloConnectionService } from '@shure/shared/angular/apollo';
import { ILogger } from '@shure/shared/angular/logging';
import { OktaInterfaceService, monitorLoginState } from '@shure/shared/angular/okta';

import result from '../graphql/generated/cloud-sys-api';

import { SystemService } from './system.service';

@Injectable({
	providedIn: 'root'
})
export class SysApiConnectionService extends ApolloConnectionService {
	constructor(
		logger: ILogger,
		systemService: SystemService,
		apolloCacheFactory: ApolloCacheFactory,
		oktaIntfService: OktaInterfaceService
	) {
		super(
			logger,
			'sysApi',
			result.possibleTypes,
			4,
			systemService.getSysApiConnectInfo(),
			apolloCacheFactory,
			oktaIntfService
		);

		monitorLoginState(oktaIntfService, {
			onLogOut: apolloCacheFactory.resetCache.bind(this)
		});
	}
}
