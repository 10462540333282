<form [formGroup]="formGroup" *transloco="let t" class="dialog-form-fields">
	<h1 id="sh-dialog-h1-title" mat-dialog-title>{{ title }}</h1>
	<mat-dialog-content>
		<ng-container *ngFor="let formRow of formData">
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Text">
				<mat-label>{{ formRow.label }}</mat-label>
				<input [placeholder]="formRow.label" matInput [formControlName]="formRow.name" />
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
					>{{ t('invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('spaces-not-allowed') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Email">
				<mat-label>{{ formRow.label }}</mat-label>
				<input [placeholder]="formRow.label" matInput [formControlName]="formRow.name" />
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
					>{{ t('invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('enter-a-valid-email-id') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Number">
				<mat-label>{{ formRow.label }}</mat-label>
				<input
					[placeholder]="formRow.label"
					type="number"
					matInput
					[formControlName]="formRow.name"
					floatLabel="never"
				/>

				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
				>
					{{ t('invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('only-numerics-are-allowed') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Select">
				<mat-label>{{ formRow.label }}</mat-label>
				<mat-select matNativeControl [placeholder]="formRow.label" [formControlName]="formRow.name">
					<mat-option *ngFor="let dataValue of formRow.data" [value]="dataValue">
						{{ t(dataValue) }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="!formGroup.controls[formRow.name].valid && formGroup.controls[formRow.name].touched">
					{{ t('invalid-value') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field
				class="date-form-field"
				id="sh-dialog-field-{{ formRow.name }}"
				*ngIf="formRow.type === formType.Date"
			>
				<mat-label>{{ formRow.label }}</mat-label>
				<input
					matInput
					[matDatepicker]="picker"
					[formControlName]="formRow.name"
					[placeholder]="'MM / DD / YYYY'"
					[min]="minDate"
				/>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
				<mat-error *ngIf="formGroup.controls[formRow.name].hasError">{{ t('invalid-value') }} </mat-error>
			</mat-form-field>
			<ng-container
				*ngIf="
					(formRow.dependsOn && formGroup.controls[formRow.dependsOn].value ? true : false) ||
					!formRow.dependsOn
				"
			>
				<mat-form-field *ngIf="formRow.type === formType.Time">
					<mat-label>{{ formRow.label }}</mat-label>
					<input matInput type="time" min="00:00" max="23:59" [formControlName]="formRow.name" />
				</mat-form-field>
			</ng-container>
		</ng-container>
	</mat-dialog-content>
	<div
		[ngClass]="localData?.buttonListType === 'fullWidth' ? 'button-list' : 'mat-dialog-actions'"
		id="sh-dialog-box-action-buttons"
	>
		<button mat-button color="accent" role="shure" (click)="closeDialog()" id="sh-dialog-button-cancel">
			{{ t('cancel') }}
		</button>
		<button
			[disabled]="formGroup.invalid"
			mat-button
			color="accent"
			(click)="doAction()"
			role="shure"
			id="sh-dialog-button-confirm"
		>
			{{ action | transloco }}
		</button>
	</div>
</form>
