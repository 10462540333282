import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'sh-device-network-properties',
	standalone: true,
	imports: [CommonModule, TranslocoModule, MatFormFieldModule, MatInputModule],
	templateUrl: './network-properties.component.html',
	styleUrls: ['./network-properties.component.scss']
})
export class NetworkPropertiesComponent {
	/**
	 * Ip mode of the device.
	 */
	@Input() public ipMode!: string;

	/**
	 * Ip address of the device.
	 */
	@Input() public ipAddress!: string;

	/**
	 * Subnet mask of the device.
	 */
	@Input() public subnetMask!: string;

	/**
	 * Gateway of the device.
	 */
	@Input() public gateway!: string;

	/**
	 * Mac address of the device
	 */
	@Input() public macAddress!: string;
}
