import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
	ApiOrganizationsService,
	ValidateUserInfoDto,
	OrganizationsDto
} from '@shure/cloud-apps/organizations/data-access';

import { CloseTextOption, SnackbarService } from '../../html-snackbar/snackbar.service';

import { CountryDefinition, SUPPORTED_COUNTRIES } from './countries';

interface OrganizationDetails extends OrganizationsDto {
	ownerDetails: Array<{
		userId: string;
		userFirstName: string;
		userLastName: string;
		userName: string;
	}>;
}
interface OrgDetailsDialogData {
	data: OrganizationDetails;
	actionType: string;
	title: string;
}

interface Domains {
	domainName: string;
	domainId: string;
}

enum OrgDetailsActionType {
	Create = 'create',
	Edit = 'edit',
	View = 'view'
}

@Component({
	selector: 'sh-org-details',
	templateUrl: './org-details.component.html',
	styleUrls: ['./org-details.component.scss']
})
export class OrgDetailsComponent implements OnInit, OnDestroy {
	public formGroup!: FormGroup;
	private destroy$: Subject<void> = new Subject<void>();
	public isInputDisabled = false;
	public orgDetailsActionType = OrgDetailsActionType;
	public countries!: CountryDefinition[];
	public previousValues: any;
	public isFormChanged = false;
	public retrievedOrgData = false;
	constructor(
		public dialogRef: MatDialogRef<OrgDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: OrgDetailsDialogData,
		private formBuilder: FormBuilder,
		private apiOrganizationsService: ApiOrganizationsService,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService
	) {}

	public ngOnInit() {
		this.setViewForm();
		this.buildForm();
		this.setFormData();
		this.previousValues = this.formGroup.value;
		/*
		 * Checking if the user has changed the data and enabling update organization button
		 * only if the data is different from the existing
		 */
		this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.isFormChanged = JSON.stringify(value) !== JSON.stringify(this.previousValues) ? true : false;
		});
		this.countries = SUPPORTED_COUNTRIES;
	}

	/*
	 * Set the property to disable input based on the provided action type.
	 */
	public setViewForm(): void {
		if (this.data.actionType === OrgDetailsActionType.View) {
			this.isInputDisabled = true;
		}
	}

	/*
	 * Construct the form group for organization fields
	 */
	public buildForm(): void {
		let orgControlDisable = true;
		if (this.data.actionType === OrgDetailsActionType.Create) {
			orgControlDisable = false;
		}
		this.formGroup = this.formBuilder.group({
			name: [{ value: '', disabled: true }, [Validators.required]],
			number: [{ value: '', disabled: orgControlDisable }, [Validators.required, Validators.pattern('^[0-9]*$')]],
			description: [{ value: '', disabled: this.isInputDisabled }, []],
			country: [{ value: '', disabled: true }, [Validators.required]],
			domains: [{ value: '', disabled: this.isInputDisabled }, [Validators.required]],
			userId: [
				{ value: '', disabled: orgControlDisable },
				[Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
			],
			userFirstName: [{ value: '', disabled: true }, [Validators.required]],
			userLastName: [{ value: '', disabled: true }, [Validators.required]]
		});
	}

	/*
	 * Populates the form group with data if available.
	 * If data is provided, it patches the form group's values with the provided data.
	 */
	public setFormData(): void {
		if (this.data.data) {
			const domains = this.getDomainString(this.data.data.domains);

			this.formGroup.patchValue({
				...this.data.data,
				...this.data.data.ownerDetails[0],
				domains: domains,
				number: this.data.data.number.substring(2)
			});
			this.retrievedOrgData = true;
		}
	}

	public onCloseDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * Retrieves organization data based on the user ID and number, and updates it in the form.
	 */
	public retrieveOrgData(): void {
		if (!this.formGroup.get('userId')?.valid || !this.formGroup.get('number')?.valid) {
			return;
		}
		this.apiOrganizationsService
			.validateUserInfo$Response({
				body: <ValidateUserInfoDto>{
					email: this.formGroup.value.userId,
					accountNumber: 'A-' + this.formGroup.value.number
				}
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (validateUserResp) => {
					const organizationRespBody = JSON.parse(<string>(<unknown>validateUserResp.body));
					if (organizationRespBody?.body) {
						const organizationData = organizationRespBody.body;
						this.formGroup.patchValue({
							userFirstName: organizationData.firstName,
							userLastName: organizationData.lastName,
							name: organizationData.orgName,
							country: organizationData.country
						});
						this.retrievedOrgData = true;
						this.formGroup.get('userId')?.disable();
						this.formGroup.get('number')?.disable();
					}
				},
				error: (error) => {
					const errorMessage = JSON.parse(error.error);
					this.snackBarService.open(
						this.translocoService.translate('cloud-licensing-portal.messages.' + errorMessage.i18nKey),
						CloseTextOption.Ok
					);
				}
			});
	}

	public doAction(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close({
				data: {
					...this.formGroup.value,
					name: this.formGroup.get('name')?.value,
					userId: this.formGroup.get('userId')?.value,
					userFirstName: this.formGroup.get('userFirstName')?.value,
					userLastName: this.formGroup.get('userLastName')?.value,
					country: this.formGroup.get('country')?.value,
					number: `A-${this.formGroup.get('number')?.value}`,
					description: this.formGroup.value.description || undefined
				}
			});
		}
	}
	/**
	 * Lifecycle hook that is called when the component is being destroyed.
	 * It unsubscribes from observables and cleans up any resources.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Format domains as comma separated string from list of domains
	 * @param domainList - array representation of domain list
	 */
	private getDomainString(domainList: Domains[]): string {
		return domainList && Array.isArray(domainList) ? domainList.map((domain) => domain.domainName).join(',') : '';
	}
}
