import { PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ILogger, jsonFormat } from '@shure/shared/angular/logging';
import {
	DeviceConditionSeverity,
	InventoryDevice,
	ProxiedTransmitterForInventory,
	SortedDeviceConditionCollection
} from '@shure/shared/models';

@Injectable()
export class DeviceStatusService {
	public static readonly lowBatteryThreshold = 0.05; // 5%
	public static readonly veryLowBatteryThreshold = 0.02; // 2%

	private logger: ILogger;

	constructor(logger: ILogger, private translocoService: TranslocoService, private percentPipe: PercentPipe) {
		this.logger = logger.createScopedLogger('DeviceStatusService');
	}

	public conditionsFor(device: InventoryDevice): SortedDeviceConditionCollection {
		const conditionCollection = new SortedDeviceConditionCollection();

		const proxiedTransmitters = [
			device.features.doublestuffProxiedTransmitters?.mic1,
			device.features.doublestuffProxiedTransmitters?.mic2
		];

		proxiedTransmitters.forEach((mic) => {
			if (this.checkBatteryLevelAtOrBelow(DeviceStatusService.veryLowBatteryThreshold, mic)) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.High,
					description: this.translocoService.translate('mic-battery-very-low', {
						percent: this.formatBatteryLevel(mic)
					})
				});
			} else if (this.checkBatteryLevelAtOrBelow(DeviceStatusService.lowBatteryThreshold, mic)) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.Moderate,
					description: this.translocoService.translate('mic-battery-low', {
						percent: this.formatBatteryLevel(mic)
					})
				});
			}
		});

		this.logger.trace(
			'conditionsFor',
			device.name,
			jsonFormat({
				conditions: conditionCollection.conditions,
				proxiedTransmitters: device.features.doublestuffProxiedTransmitters
			})
		);
		return conditionCollection;
	}

	private checkBatteryLevelAtOrBelow(threshold: number, mic?: ProxiedTransmitterForInventory | void): boolean {
		const currentBatteryLevel = mic?.batteryLevel ?? Number.NaN;
		return currentBatteryLevel <= threshold;
	}

	private formatBatteryLevel(mic?: ProxiedTransmitterForInventory): string {
		if (mic?.batteryLevel === undefined) {
			return '';
		}

		return this.percentPipe.transform(mic?.batteryLevel) ?? '';
	}
}
