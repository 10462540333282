import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

import { DebugModeService } from '@shure/shared/angular/debug-mode';
import { AppEnvironment, APP_ENVIRONMENT } from '@shure/shared/models';

export interface DialogData {
	title: string;
	appVersion: string;
}
@Component({
	selector: 'sh-about-dialog',
	templateUrl: './about-dialog.component.html',
	styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent implements OnDestroy {
	public version: string;
	public clientVersion!: string | undefined;
	public showDebugMode!: boolean;
	public isChecked!: boolean;
	public displayDebugModeCheckbox!: boolean;
	private destroy$: Subject<void> = new Subject<void>();
	public isDebugModeEnabledForApplication: boolean | undefined;
	constructor(
		private dialogRef: MatDialogRef<AboutDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private debugModeService: DebugModeService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.version = this.data.appVersion ? this.data.appVersion : this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;
		this.isDebugModeEnabledForApplication = this.appEnv.isDebugModeEnabled;
		//To maintain the debug mode enabled status even after the about dialog is closed
		this.debugModeService
			.getDebugModeStatus$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((debugModeStatus) => {
				this.isChecked = debugModeStatus;
			});

		//To maintain the checkbox display status even after the about dialog is closed
		this.debugModeService
			.getDebugModeCheckboxDisplayStatus$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((displayDebugModeCheckbox) => {
				this.showDebugMode = displayDebugModeCheckbox;
			});
	}

	/**
	 * sets debug mode checkbox display status
	 * @param event - KeyboardEvent
	 */
	@HostListener('window:keydown', ['$event'])
	public handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.key === 'D' && event.shiftKey) {
			this.displayDebugModeCheckbox = true;
		} else if (!this.isChecked) {
			this.displayDebugModeCheckbox = false;
		}
		this.debugModeService.setDebugModeCheckboxDisplayStatus$(this.displayDebugModeCheckbox);
	}
	public closeDialog(): void {
		this.dialogRef.close();
	}
	/**
	 * sets debug mode enabled status
	 * @param event - MatCheckboxChange
	 */
	public onSelectionChanged(event: MatCheckboxChange): void {
		this.debugModeService.setDebugModeStatus$(event.checked);
	}
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
