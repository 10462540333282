import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IpAddressConstants } from './ip-address.constants';
import { ipUtilities } from './ip-address.utils';

export class IpAddressValidator {
	public static valueChangesSubscription: Subscription;
	/**
	 * validateIPAndSubnet
	 * @description Which validates the IP Address with Subnet Address
	 */
	public static validateIPAndSubnet(ipAddress: string, subnetAddress: string): IpAddressConstants | undefined {
		if (ipAddress && subnetAddress) {
			if (ipUtilities.isValid(ipUtilities.split(ipAddress))) {
				const ipAdd = ipUtilities.toUnit(ipUtilities.parseAsString(ipAddress));
				const subAdd = ipUtilities.toUnit(ipUtilities.parseAsString(subnetAddress));
				return ipUtilities.validateIpAddr(ipAdd, subAdd);
			}
			return IpAddressConstants.VALIDATE_IP_INVALID;
		}

		return undefined;
	}

	public static validateIpAgainstSubnet(addressName: string, isSubnet: boolean): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control && control.parent) {
				const currentAddress = control.value;
				if (this.valueChangesSubscription) {
					this.valueChangesSubscription.unsubscribe();
				}

				this.valueChangesSubscription = control.parent.valueChanges.subscribe((data) => {
					const ipAddress = isSubnet ? data[addressName] : currentAddress;
					const subnetAddress = isSubnet ? currentAddress : data[addressName];
					const validateIPAndSubnet = this.validateIPAndSubnet(ipAddress, subnetAddress);

					if (isSubnet) {
						if (ipUtilities.isValid(ipUtilities.split(subnetAddress))) {
							if (!ipUtilities.isValidSubnet(subnetAddress)) {
								control.setErrors({ invalidSubnet: true });
							} else {
								if (ipUtilities.isValid(ipUtilities.split(ipAddress))) {
									if (validateIPAndSubnet === IpAddressConstants.VALIDATE_IP_OK) {
										control.parent?.get(addressName)?.setErrors(null);
										control.setErrors(null);
									} else if (validateIPAndSubnet === IpAddressConstants.VALIDATE_IP_ERROR_SUBNET) {
										control.setErrors({ invalidSubnet: true });
									} else {
										control.parent?.get(addressName)?.setErrors({ invalidIP: true });
									}
								}
							}
						} else {
							control.setErrors({ badIP: true });
						}
					}
					if (!isSubnet) {
						if (ipUtilities.isValid(ipUtilities.split(ipAddress))) {
							if (validateIPAndSubnet === IpAddressConstants.VALIDATE_IP_OK) {
								control.setErrors(null);
							} else {
								control.setErrors({ invalidIP: true });
							}
						} else {
							control.setErrors({ badIP: true });
						}
					}
				});
			}
			return null;
		};
	}

	public static isValidIP(control: AbstractControl): ValidationErrors | null {
		const ipAddress = control.value;
		return ipUtilities.isValid(ipUtilities.split(ipAddress)) ? null : { badIP: true };
	}
}
