import { Component, HostBinding, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';

import { DeviceModel } from '@shure/shared/models';

import { DeviceImageProvider } from './device-image.provider';

@Component({
	selector: 'sh-device-image',
	templateUrl: './device-image.component.html',
	styleUrls: ['./device-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceImageComponent implements OnChanges {
	/**
	 * Path for the device image to be rendered
	 *
	 * @ignore internal
	 */
	@HostBinding('style.background-image')
	public backgroundImage!: string;

	/**
	 * @ignore internal
	 */
	@HostBinding('class.sh-device-image') public readonly hostClass = true;

	/**
	 * Determines if the device is in virtual/live mode
	 */
	@HostBinding('class.sh-virtual-device')
	@Input()
	public isVirtual = false;

	/**
	 * Determines the device model for the image to be displayed
	 */
	@Input() public deviceModel: DeviceModel | undefined = undefined;

	/**
	 * Determines if the device is password protected
	 */
	@Input() public isPasswordEnabled!: boolean | undefined;

	/**
	 * Determines if the locked device was successfully authenticated
	 * It is set to false, until we get full details on the authentication process.
	 */
	@Input() public isAuthenticated = false;

	/**
	 * Optional input to determine the channel count of the device.
	 * This can affect which image to display for endpoint.
	 */
	@Input() public channelCount?: string;

	constructor(private readonly provider: DeviceImageProvider) {}

	/**
	 * On one of the input properties change, calls the deviceImageService to get the path for the device image to be rendered
	 */
	public ngOnChanges(_changes: SimpleChanges): void {
		if (this.deviceModel) {
			if (this.channelCount) {
				this.setEndpointChannelImage();
			} else {
				const imageInfo = this.provider.getImage(this.deviceModel);
				const imageWithPath = this.isVirtual ? imageInfo.virtual : imageInfo.live;
				this.backgroundImage = `url(${imageWithPath})`;
			}
		}
	}

	/**
	 * Set the cell image based on the channel count.
	 * TODO: SV: This is a temporary fix and will be removed when GraphQL integration is finished for License Management.
	 */
	private setEndpointChannelImage(): void {
		if (this.channelCount === 'unassigned') {
			this.backgroundImage = `url('/assets/images/devices/device-imxroom.svg')`;
		} else {
			this.backgroundImage = `url('/assets/images/devices/device-imxroom-${this.channelCount}.svg')`;
		}
	}
}
