import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PopcornTimerService implements OnDestroy {
	private _done$ = new Subject<boolean>();
	private _timeout = NaN;
	private timeoutId: ReturnType<typeof setTimeout> | undefined;

	public start(timeout: number): void {
		this._timeout = timeout;
		this.startTimer();
	}

	public reset(): void {
		this.stopTimer();
		this.startTimer();
	}

	public stop(): void {
		this.stopTimer();
	}

	public done$(): Subject<boolean> {
		return this._done$;
	}

	public ngOnDestroy(): void {
		this.stopTimer();
	}

	private startTimer(): void {
		this.timeoutId = setTimeout(() => {
			this._done$.next(true);
		}, this._timeout);
	}

	private stopTimer(): void {
		clearTimeout(<ReturnType<typeof setTimeout>>this.timeoutId);
	}
}
