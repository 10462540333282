import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export interface ProgressDialogData {
	message: string;
}

@Component({
	selector: 'sh-progress-dialog',
	templateUrl: './progress-dialog.component.html',
	styleUrls: ['./progress-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, MatDialogModule, MatProgressBarModule]
})
export class ProgressDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ProgressDialogData) {}
}
