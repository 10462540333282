import { Component, TemplateRef, ViewChild } from '@angular/core';

/**

 */
@Component({
	selector: 'sh-sticky-button-group',
	templateUrl: './sticky-buttons.component.html'
})
export class StickyButtonsComponent {
	/**
	 * Reference for the template defined by the consuming component.
	 */
	@ViewChild(TemplateRef) public readonly template!: TemplateRef<unknown>;
}
