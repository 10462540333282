import { Injectable, OnDestroy } from '@angular/core';
import { DefaultUrlSerializer, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject, filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterService implements OnDestroy {
	private readonly urlSerializer = new DefaultUrlSerializer();
	private readonly destroy$ = new Subject<void>();

	private _url$ = new BehaviorSubject<string[]>([]);
	private _roomId$ = new BehaviorSubject<string | null>(null);
	private _deviceId$ = new BehaviorSubject<string | null>(null);

	public readonly url$ = this._url$.asObservable();
	public readonly roomId$ = this._roomId$.asObservable();
	public readonly deviceId$ = this._deviceId$.asObservable();

	public get url(): string[] {
		return this._url$.getValue();
	}

	public get roomId(): string | null {
		return this._roomId$.getValue();
	}

	public get deviceId(): string | null {
		return this._deviceId$.getValue();
	}

	constructor(private readonly router: Router) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
			const root = this.urlSerializer.parse((<NavigationEnd>event).urlAfterRedirects).root;

			// eslint-disable-next-line dot-notation, prettier/prettier
			this._url$.next(root.children['primary']?.segments.map((segment) => segment.path) || []);
		});
		this.url$.subscribe((url) => {
			const roomMatch = url.join('/').match(/room\/([\w\-%=]{1,})/);
			const deviceMatch = url.join('/').match(/device\/([\w\-%=]{1,})/);

			this._roomId$.next(roomMatch && roomMatch[1] ? roomMatch[1] : null);
			this._deviceId$.next(deviceMatch && deviceMatch[1] ? deviceMatch[1] : null);
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
