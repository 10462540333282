/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetApplicationResponse } from '../models/get-application-response';
import { PostApplicationRequest } from '../models/post-application-request';
import { PostApplicationResponse } from '../models/post-application-response';


/**
 * This lists and explains endpoints that relate to applications.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiApplicationsEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postApplication
   */
  static readonly PostApplicationPath = '/application';

  /**
   * Create a new application.
   *
   * Create a new application
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApplication$Response(params?: {
    context?: HttpContext
    body?: PostApplicationRequest
  }
): Observable<StrictHttpResponse<PostApplicationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiApplicationsEndpointService.PostApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostApplicationResponse>;
      })
    );
  }

  /**
   * Create a new application.
   *
   * Create a new application
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApplication(params?: {
    context?: HttpContext
    body?: PostApplicationRequest
  }
): Observable<PostApplicationResponse> {

    return this.postApplication$Response(params).pipe(
      map((r: StrictHttpResponse<PostApplicationResponse>) => r.body as PostApplicationResponse)
    );
  }

  /**
   * Path part for operation getApplications
   */
  static readonly GetApplicationsPath = '/applications';

  /**
   * List all applications.
   *
   * Get a list of applications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications$Response(params?: {

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * The total count of applications available.
 */
'itemsCount'?: number;

/**
 * An array of applications, for the given limit and offsets.
 */
'items'?: Array<GetApplicationResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiApplicationsEndpointService.GetApplicationsPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * The total count of applications available.
         */
        'itemsCount'?: number;
        
        /**
         * An array of applications, for the given limit and offsets.
         */
        'items'?: Array<GetApplicationResponse>;
        }>;
      })
    );
  }

  /**
   * List all applications.
   *
   * Get a list of applications
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApplications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications(params?: {

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<{

/**
 * The total count of applications available.
 */
'itemsCount'?: number;

/**
 * An array of applications, for the given limit and offsets.
 */
'items'?: Array<GetApplicationResponse>;
}> {

    return this.getApplications$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * The total count of applications available.
 */
'itemsCount'?: number;

/**
 * An array of applications, for the given limit and offsets.
 */
'items'?: Array<GetApplicationResponse>;
}>) => r.body as {

/**
 * The total count of applications available.
 */
'itemsCount'?: number;

/**
 * An array of applications, for the given limit and offsets.
 */
'items'?: Array<GetApplicationResponse>;
})
    );
  }

}
