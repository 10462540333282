import { DeviceModel } from './device-model.enum';
import { FirmwarePackage } from './firmware-package';
import { FirmwareUpdateProgress } from './firmware-update-progress';
import { MissingFeatureType } from './missing-feature-type';
import { NetworkInterface } from './network-interface';
import { ProxiedTransmitter } from './proxied-transmitter';

export interface PropertyPanelDevice {
	id: string;
	name: string;
	model: DeviceModel;
	isVirtual: boolean;

	features: {
		mute?: {
			muted: boolean;
		} & MissingFeatureType;
		serialNumber?: {
			serialNumber: string;
		} & MissingFeatureType;
		identify?: {
			identifying: boolean;
		} & MissingFeatureType;
		danteName?: {
			danteName: string;
		} & MissingFeatureType;
		audioNetwork?: NetworkInterface & MissingFeatureType;
		controlNetwork?: NetworkInterface & MissingFeatureType;
		/*isDeviceLocked?: {
			locked: boolean;
		} & MissingFeatureType;*/
		firmware?: {
			version: string;
			valid: boolean;
		} & MissingFeatureType;
		uptime?: {
			uptime: string;
		} & MissingFeatureType;
		availablePackages?: {
			primaryPackages: FirmwarePackage[];
		} & MissingFeatureType;
		updateProgress?: FirmwareUpdateProgress & MissingFeatureType;
		doublestuffProxiedTransmitters?: {
			mic1: ProxiedTransmitter | null;
			mic2: ProxiedTransmitter | null;
		} & MissingFeatureType;
	};
}
