import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class StringValidator {
	/**
	 * Intended to be supplied as the validator on a formGroup. Sets an error if a given input receives
	 * an empty string or one which contains whitespace characters only.
	 * @param source name of the form control
	 * @returns
	 */
	public static notEmpty(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const isEmpty = control.value.trim() === '';
			const errors: ValidationErrors | null = isEmpty ? { empty: true } : null;

			control.setErrors(errors);

			return errors;
		};
	}
}
