import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { NavCharmComponent, NavTabComponent, NavigationTabGroupComponent } from './navigation-tabs.component';

@NgModule({
	declarations: [NavCharmComponent, NavTabComponent, NavigationTabGroupComponent],
	exports: [NavCharmComponent, NavTabComponent, NavigationTabGroupComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatTabsModule
	]
})
export class NavigationTabGroupModule {}
