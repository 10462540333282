import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { PipesModule } from '@shure/shared/angular/pipes';

@Component({
	selector: 'sh-app-access-not-configured',
	standalone: true,
	imports: [CommonModule, TranslocoModule, PipesModule],
	templateUrl: './app-access-not-configured.component.html',
	styleUrls: ['./app-access-not-configured.component.scss']
})
export class AppAccessNotConfiguredComponent {}
