import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

import { NavigationButtonGroupModule } from './nav-button-group/navigation-button-group.module';
import { NavigationTabGroupModule } from './nav-tab-group/navigation-tabs.module';
import {
	NavigationToolbarComponent,
	StickyContentLeftComponent,
	StickyContentRightComponent
} from './navigation-toolbar.component';
import { StickyButtonsComponent } from './sticky-button-group/sticky-buttons.component';

@NgModule({
	declarations: [
		NavigationToolbarComponent,
		StickyContentLeftComponent,
		StickyContentRightComponent,
		StickyButtonsComponent
	],
	exports: [
		NavigationToolbarComponent,
		StickyContentLeftComponent,
		StickyContentRightComponent,
		StickyButtonsComponent,

		// Export components in modules
		NavigationButtonGroupModule,
		NavigationTabGroupModule
	],
	imports: [CommonModule, MatToolbarModule, NavigationButtonGroupModule, NavigationTabGroupModule]
})
export class NavigationToolbarModule {}
