/* istanbul ignore file */
import { IpAddressConstants } from './ip-address.constants';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-empty-function
export const noop = () => {};

export const ipUtilities = {
	/**
	 * toUnit
	 * @description  Convets the Object to unit number
	 * @param ipV4Address
	 * @returns {number}
	 */
	toUnit(ipV4Address: Uint8Array | undefined): number {
		if (!ipV4Address) return 0;
		let addr = 0;
		addr += (ipV4Address[0] << 24) + (ipV4Address[1] << 16) + (ipV4Address[2] << 8) + ipV4Address[3];
		return addr;
	},

	/*
	 * Perform Sanity Checks to make sure that the new IP address is a valid host address.
	 * We do not allow the following IP Addresses:
	 *   0.XX.XX.XX                     Reserved for self identification
	 *   127.XX.XX.XX                   LoopBack Address
	 *   224.XX.XX.XX - 239.XX.XX.XX    Multicast Group
	 *   240.XX.XX.XX - 255.XX.XX.XX    Reserved
	 *   XX.XX.XX.0                     Network ID (assuming netmask of 255.255.255.0)
	 *   XX.XX.XX.255                   Broadcast  (assuming netmask of 255.255.255.0)
	 * The netMask must be a contiguous mask (in the binary pattern 1...10...0).
	 * We do not allow the following netMasks:
	 *   0.0.0.0                        No netMask defined (no external network)
	 *   255.255.255.254                Peer-to-Peer (ACN required broadcast, Windows XP does not support)
	 *   255.255.255.255                No host id available
	 *
	 * Note:  Checking the low byte for Network ID or Broadcast is a simpler way of
	 * checking host ID (both in code and documenting).  A more accurate method would
	 * be to use netMask (ignoring 255.255.255.254 (point-to-point) and 255.255.255.255).
	 * However, we often use DMP properties which can not change both IP address and subnet
	 * at the same time which means we might run into issues where an IP address would be
	 * rejected due to an old subnet mask when updating the IP address before the subnet mask:
	 *   (addr & subnet) === 0           Network ID
	 *   (addr | subnet) === FFFFFFFF    Broadcast
	 */
	/* Non-standard formatting to table-ize the comparisons and error codes */

	validateIpAddr(ipAddress: number, netMask: number): IpAddressConstants {
		let retFlag = IpAddressConstants.VALIDATE_IP_OK;
		const hostMask = ~netMask;
		if ((ipAddress & 0xff000000) === 0x00000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_SELF_IDENTIFY;
		} else if ((ipAddress & 0xff000000) === 0x7f000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_LOOPBACK;
		} else if ((ipAddress & 0xff000000) >= 0xf0000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_RESERVED;
		} else if ((ipAddress & 0xff000000) >= 0xe0000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_MULTICAST;
		} else if (hostMask === (ipAddress & hostMask)) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_BROADCAST;
		} else if (netMask === 0x00000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_SUBNET;
		} else if (netMask === 0xfffffffe) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_SUBNET;
		} else if (netMask === 0xffffffff) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_SUBNET;
		} else if ((hostMask & (hostMask + 1)) !== 0) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_SUBNET; // Check for contiguous subnet
		} else if ((ipAddress & hostMask) === 0x00000000) {
			retFlag = IpAddressConstants.VALIDATE_IP_ERROR_NETWORK_ID;
		}
		return retFlag;
	},
	/**
	 * parseAsString
	 * @description Which converts string as  Uint8Array
	 * @param input
	 * @returns {Uint8Array}
	 * @private
	 */
	parseAsString(input: string): Uint8Array | undefined {
		const bytesVal = new Uint8Array(4);
		const octets = String(input).split('.');
		if (octets.length === 4) {
			for (let index = 0; index < octets.length; index++) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				const octet = octets[index] as any;
				if (!isNaN(octet) && octet >= 0 && octet <= 255) {
					bytesVal[index] = octet;
				} else {
					return undefined;
				}
			}
		}

		return bytesVal;
	},
	split(value: string): string[] {
		if (!value) {
			return ['', '', '', ''];
		}
		const result = value.split('.');
		if (result.length !== 4) {
			throw new Error('Invalid IPV4');
		}
		return result;
	},
	isValid(blocks: string[]): boolean {
		return blocks.every((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 255);
	},
	isValidSubnet(subnet: string): boolean {
		const vx = new RegExp(
			// eslint-disable-next-line max-len
			'^(((255.){3}(255|254|252|248|240|224|192|128+))|((255.){2}(255|254|252|248|240|224|192|128|0+).0)|((255.)(255|254|252|248|240|224|192|128|0+)(.0+){2})|((255|254|252|248|240|224|192|128|0+)(.0+){3}))$'
		);
		return vx.test(subnet);
	},
	isMaxLen(value: string): boolean {
		if (value.length === 3) {
			return true;
		} else if (value.length === 2 && parseInt(value, 10) > 25) {
			return true;
		}
		return false;
	}
};
