// Disabling to enforce type:utils restrictions for device-behavior-core library
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DeviceImage as DeviceImageBase } from '@shure/shared/angular/components';

export interface DeviceImage extends DeviceImageBase {
	/**
	 * Image of back of device when live.
	 */
	liveBack: string;

	/**
	 * Image of back of device when virtual.
	 */
	virtualBack: string;

	/**
	 * Background image for lights feature.
	 */
	lightsBackground: string;

	/**
	 * Overlay image for lights feature.
	 */
	lightsOverlay: string;
}
