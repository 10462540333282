import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

import { ZoomControlsButtonsComponent } from './zoom-controls-buttons.component';
@NgModule({
	imports: [CommonModule, MatButtonModule, MatIconModule, FlexLayoutModule, TranslocoModule],
	declarations: [ZoomControlsButtonsComponent],
	exports: [ZoomControlsButtonsComponent]
})
export class ZoomControlsButtonsModule {}
