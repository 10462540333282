import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, Subject, catchError, map, of, takeUntil } from 'rxjs';

import { ApiNotificationsEndpointService, GetNotificationResponse } from '@shure/cloud-apps/notifications/data-access';

import { X_API_KEY } from './domain/apiKeys';
import { FormatDate } from './domain/utils/format-date';
import { NotificationsDetailsComponent } from './view/notifications-details/notifications-details.component';

@Component({
	selector: 'sh-notifications-list',
	templateUrl: './notifications-list.component.html',
	styleUrls: ['./notifications-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit, OnDestroy {
	notifications$: Observable<GetNotificationResponse[] | null> = EMPTY;
	displayedColumns: string[] = ['markAsRead', 'message', 'sentAt'];
	public readonly destroy$ = new Subject<void>();
	checkboxState: { [notificationId: string]: boolean } = {};
	hiddenRows: string[] = [];
	isPopupOpen = false;
	sender = '';
	message = '';
	sentAt = '';
	isListPage = true;
	formatDateService: FormatDate = new FormatDate();

	constructor(
		private notificationService: ApiNotificationsEndpointService,
		public changeDetectorRef: ChangeDetectorRef,
		public snackBar: MatSnackBar,
		private dialogRef: MatDialog
	) {}

	ngOnInit(): void {
		this.loadNotifications();
	}

	loadNotifications(): void {
		const params = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-api-key': X_API_KEY,
			limit: '10',
			offset: '0',
			context: undefined
		};
		this.notifications$ = this.notificationService.getNotifications(params).pipe(
			map((response) => response?.items ?? null),
			catchError(() => of(null))
		);
		this.changeDetectorRef.detectChanges();
	}

	openPopup(selectedNotification: GetNotificationResponse, isListPage: boolean) {
		isListPage = true;
		this.dialogRef.closeAll();

		const dialogRef = this.dialogRef.open(NotificationsDetailsComponent, {
			width: '500px',
			data: { notification: selectedNotification, checkPage: isListPage }
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result && result.refreshNotifications) {
					this.loadNotifications();
				}
			});
	}

	getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
