/* eslint-disable rxjs-angular/prefer-takeuntil */
/* eslint-disable import/order */
import { Component, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { LanguagesService } from '@shure/shared/angular/i18n';
import { environment } from '../environments/environment';
import { OktaInterfaceService } from '@shure/shared/angular/okta';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/shared/models';
import { BreakpointService } from '@shure/shared/angular/services';
@Component({
	selector: 'sh-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public appVersion: string;
	public clientVersion: string | undefined;

	// Setup an observe to detect when we're on a small device.
	// Breakpoints.XSmall: max-width equals 599.99px
	// Breakpoints.Small: min-width equals 600px and max-width equals 959.99px
	// Breakpoints.Medium: min-width equals 960px and max-width equals 1279.99px
	// Breakpoints.Large: min-width equals 1280px and max-width equals 1919.99px
	// Breakpoints.XLarge: min-width equals 1920px
	public readonly isSmallDevice = this.breakpointService.isLteSmall;

	constructor(
		private readonly languageService: LanguagesService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService,
		private breakpointService: BreakpointService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.appVersion = this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;
	}

	public ngOnInit(): void {
		this.languageService.getPreferredCultureCode();
		if (environment.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
	}
}
