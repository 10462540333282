<mat-chip-option [disabled]="disabled" (click)="setEditMode(true)" [class.sh-chip-edit-mode]="editMode">
	<div *ngIf="!editMode" class="sh-chip-content">{{ value || placeholder }}</div>
	<div *ngIf="editMode">
		<input
			[value]="value"
			[disabled]="disabled"
			[placeholder]="placeholder"
			(input)="onInputChange($event)"
			(keydown)="onKeyDown($event)"
			(blur)="setEditMode(false)"
			class="sh-chip-input-field"
		/>
	</div>
</mat-chip-option>
