import { HashMap } from '@ngneat/transloco';

import { DEFAULT_AVAILABLE_LANGUAGES } from './transloco-defualt.config';

export type TranslationImporter = (lang: string, root: string) => Promise<unknown>;

/**
 * Transloco scope loader for library translations pr. https://ngneat.github.io/transloco/docs/inline-loaders
 *
 * **Will add DEFAULT_AVAILABLE_LANGUAGES as available languages**
 *
 * Usage
 *
 * ```ts
 * NgModule({
 * 	...
 * 	providers: [
 * 		{
 * 			provide: TRANSLOCO_SCOPE,
 * 			useValue: {
 * 				scope: 'myScope',
 * 				loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`))
 * 			}
 * 		}
 * 	]
 * })
 * ```
 *
 * Note: [@ngneat/transloco-keys-manager](https://github.com/ngneat/transloco-keys-manager) will not be able to extract
 * the scoped translations if the `scope` property in the `TRANSLOCO_SCOPE` provider is not provided directly.
 */
export function scopeLoader(importer: TranslationImporter, root = 'i18n'): HashMap {
	return DEFAULT_AVAILABLE_LANGUAGES.reduce((all, lang) => {
		/* istanbul ignore next */
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		all[lang.id] = () => importer(lang.id, root);
		return all;
	}, <HashMap>{});
}
