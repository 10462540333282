import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';

import { ChipInputComponent } from './chip-input.component';

@NgModule({
	imports: [CommonModule, MatInputModule, MatChipsModule],
	declarations: [ChipInputComponent],
	exports: [ChipInputComponent]
})
export class ChipInputModule {}
