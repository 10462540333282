import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgHttpCachingService } from 'ng-http-caching';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ApiFeatureRequestService, FeatureRequestDto } from '@shure/cloud-apps/users/feature-request/data-access';
import { OktaInterfaceService } from '@shure/shared/angular/okta';

import { IBreadcrumb, IBreadcrumbClickEvent } from '../breadcrumbs';
import { FormDataDetails } from '../dialogs/dialog-box-ignite/dialog-box/dialog-box.model';
import { ShIgniteDialogBoxService } from '../dialogs/dialog-box-ignite/dialog-box/dialog-box.service';
import { CloseTextOption, SnackbarService } from '../html-snackbar';

import { HeaderToolbarService } from './header-toolbar.service';

export enum MediaAlias {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg'
}
@Component({
	selector: 'sh-header-toolbar',
	templateUrl: './header-toolbar.component.html',
	styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {
	@Input() public showSidenavToggleButton = false;
	@Input() public showFeatureButton = false;
	@Output() toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
	public breadCrumb!: IBreadcrumb[];
	private destroy$: Subject<void> = new Subject<void>();
	public smallDeviceAlias = [MediaAlias.XS, MediaAlias.SM];
	public isSmallDevice!: boolean;
	public firstName!: string;
	public firstNameChar!: string;
	public lastNameChar!: string;

	private featureformData: FormDataDetails[] = [
		{
			label: this.translocoService.translate('idea-summary'),
			name: 'title',
			validators: [],
			type: 'text'
		},
		{
			label: this.translocoService.translate('more-details'),
			name: 'description',
			validators: [Validators.required],
			type: 'textarea'
		}
	];

	constructor(
		private oktaInterfaceService: OktaInterfaceService,
		private router: Router,
		private media: MediaObserver,
		private headerToolbarService: HeaderToolbarService,
		private ngHttpCachingService: NgHttpCachingService,
		public oktaIntfService: OktaInterfaceService,
		private changeDetector: ChangeDetectorRef,
		private shDialogService: ShIgniteDialogBoxService,
		private translocoService: TranslocoService,
		public featureRequestservice: ApiFeatureRequestService,
		public snackBarService: SnackbarService
	) {}

	public ngOnInit(): void {
		this.headerToolbarService
			.getBreadCrumb$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((breadCrumb: IBreadcrumb[]) => {
				this.breadCrumb = breadCrumb;
				this.changeDetector.detectChanges();
			});
		this.media
			.asObservable()
			.pipe(
				filter((changes: MediaChange[]) => changes.length > 0),
				map((changes: MediaChange[]) => changes[0]),
				takeUntil(this.destroy$)
			)
			.subscribe((change) => {
				this.isSmallDevice = (<string[]>this.smallDeviceAlias).includes(change.mqAlias);
			});
		this.oktaIntfService
			.getUserProfile$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((userProfile) => {
				this.firstName = userProfile?.firstName;
				this.firstNameChar = this.firstName?.charAt(0)?.toUpperCase();
				this.lastNameChar = userProfile?.lastName?.charAt(0)?.toUpperCase();
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public async logout(): Promise<void> {
		//Clear all the cache
		this.ngHttpCachingService.clearCache();
		await this.oktaInterfaceService.signOut();
	}

	public onSidenavToggle() {
		this.toggleSidenav.emit(true);
	}

	/**
	 * Handle Breadcrumb click event and redirect to page
	 * @param breadCrumb - Breadcrumb which is clicked
	 */
	public breadCrumbRedirect(breadCrumb: IBreadcrumbClickEvent): void {
		this.router.navigate([`/${breadCrumb.id}`]);
	}

	public featureRequest(): void {
		const featureRequestDialogData = {
			action: this.translocoService.translate('send'),
			formData: this.featureformData,
			buttonListType: 'fullWidth',
			description: this.translocoService.translate('feature-popup-info'),
			dialogFooter: this.translocoService.translate('feature-request-contact-info')
		};
		this.shDialogService
			.openDialog(this.translocoService.translate('feature-request'), featureRequestDialogData)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result?.data) {
					this.submitFeatureRequest(<FeatureRequestDto>result?.data);
				}
			});
	}

	/**
	 *	This method is called to submit the feature request using the response
	 * 	@param featureRequestData - object of type FeatureRequestDto
	 */
	private submitFeatureRequest(featureRequestData: FeatureRequestDto): void {
		this.featureRequestservice
			.submitFeatureRequest$Response({ body: featureRequestData })
			?.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (featureRequestResponse) => {
					const featureRequestResponseBody = JSON.parse(<string>(<unknown>featureRequestResponse?.body));
					this.snackBarService.open(
						this.translocoService.translate(featureRequestResponseBody?.body?.messageKey),
						CloseTextOption.Ok
					);
				},
				error: (error) => {
					const errorResponse = error.error;
					this.snackBarService.open(
						this.translocoService.translate(errorResponse?.body?.message),
						CloseTextOption.Ok
					);
				}
			});
	}
}
