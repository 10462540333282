import { Inject, Injectable, Optional } from '@angular/core';

import { ILogger } from './ilogger';
import { LoggerService } from './logger.service';
import { LogLevel } from './models';

@Injectable()
export class Logger extends ILogger {
	constructor(private loggerService: LoggerService, @Optional() @Inject(String) scope: string) {
		super();
		this.scope = scope || '';
	}

	public log(level: LogLevel, eventId: string, message: string, args?: unknown): void {
		this.loggerService.log(this.scope, level, eventId, message, args);
	}

	public createScopedLogger(@Optional() scope: string): ILogger {
		const newLogger = new Logger(this.loggerService, scope);
		return newLogger;
	}
}
