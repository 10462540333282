import { TickInterval, CustomTick } from './models';

/**
 * Map tick intervals to internal representationn of a interval.
 */
export function mapLinearScale(min: number, max: number, stepSize: number): TickInterval[] {
	let stepPercent = stepSize / (max - min);
	let steps = Math.ceil((max - min) / stepSize);

	stepPercent = isFinite(stepPercent) ? stepPercent : 1;
	steps = isFinite(steps) ? steps : 1;

	const intervals: TickInterval[] = [];

	for (let i = 0; i <= steps; i++) {
		if (i === steps) {
			intervals.push({
				percent: 100,
				value: max,
				label: (min + i * stepSize).toString()
			});
		} else {
			const percent = i * stepPercent * 100;
			intervals.push({
				percent: percent,
				value: min + i * stepSize,
				label: (min + i * stepSize).toString()
			});
		}
	}

	return intervals;
}

export function mapDefaultMeteringScale(min: number, max: number): TickInterval[] {
	const steps = 5;
	const stepSize = Math.ceil((max - min) / steps);
	const intervals: TickInterval[] = [];

	for (let i = 0; i <= steps; i++) {
		if (i === steps) {
			intervals.push({
				percent: 100,
				value: max,
				label: max.toString()
			});
		} else {
			const value = min + i * stepSize;
			const label = value.toString();
			if (i < 2) {
				intervals.push({
					percent: i * 10,
					value,
					label
				});
			} else if (i < 4) {
				intervals.push({
					percent: i * 20 - 10,
					value,
					label
				});
			} else {
				intervals.push({
					percent: 75,
					value,
					label
				});
			}
		}
	}

	return intervals;
}

/**
 * Map ticks to be displayed in a custom format.
 */
export function mapLinearTicks(min: number, max: number, ticks: CustomTick[]): TickInterval[] {
	return ticks.map((tick) => {
		const tickValue = typeof tick === 'number' ? tick : tick.value;
		return {
			percent: ((tickValue - min) * 100) / (max - min),
			value: tickValue,
			label: typeof tick === 'number' ? tick.toString() : tick.label
		};
	});
}

/**
 * Returns the percent of a value in a range.
 */
export function getRangePercent(value: number, min: number, max: number): number {
	const percent = ((value - min) * 100) / (max - min);
	return isFinite(percent) ? percent : 0;
}

export function getDefaultIntervalPercent(value: number, min: number, max: number, intervals: TickInterval[]): number {
	if (!intervals) {
		return 0;
	}

	if (value <= min) {
		return 0;
	}

	if (value >= max) {
		return 100;
	}

	const steps = 5;
	const stepSize = Math.ceil((max - min) / steps);
	const lowerInterval = intervals.find((i) => {
		return value >= i.value && value <= i.value + stepSize;
	});
	const higherInterval = intervals.find((i) => {
		return value <= i.value && value >= i.value - stepSize;
	});
	if (!lowerInterval || !higherInterval) {
		return 0;
	}

	const distance = value - lowerInterval.value;
	const intervalPercent = higherInterval.percent - lowerInterval.percent;
	return Math.ceil((distance / stepSize) * intervalPercent) + lowerInterval.percent;
}
