<mat-card-header id="notification-card-header">
	<mat-card-title id="notification-card-title" class="header-style">Notifications</mat-card-title>
</mat-card-header>
<span id="current-date" class="current-date-style">{{ currentDate | date: 'MMMM d, y' }}</span>
<p id="loading-message" class="loading-style" *ngIf="(notifications$ | async) === null">Loading notifications...</p>
<div id="notification-list-container" class="notification-list-container">
	<mat-list id="notification-list">
		<mat-list-item
			id="notification-list-item"
			class="list-style"
			*ngFor="let notification of notifications$ | async"
		>
			<div mat-line id="notification-message" class="message-style">
				<div id="custom-message" class="custom-message">
					{{ notification.message }}
				</div>
			</div>
			<div mat-line id="notification-date" class="custom-date">
				{{ notification.sentAt | date: 'hh:mm a  MMM-d-yyyy' }}
			</div>
			<div id="notification-button" class="button-style">
				<span id="clear-button" class="clear-buttons" (click)="markedAsRead(notification)">Clear</span>
				<span id="details-button" (click)="openPopup(notification.id, isListPage)" class="details-button"
					>Details</span
				>
			</div>
		</mat-list-item>
	</mat-list>
</div>
<p id="no-notification-message" class="no-notification" *ngIf="(notifications$ | async)?.length === 0">
	No notifications at this time
</p>
<div class="button-row button-group">
	<span id="view-all-button" class="close-button" (click)="notificationsList()">View all</span>
	<span id="close-button" class="close-button" (click)="closedialog()">Close</span>
	<span id="clear-all-button" *ngIf="(notifications$ | async)?.length" (click)="clearAll()" class="clear-button-style"
		>Clear all</span
	>
	<button id="clear-all-button" class="clear-button" *ngIf="(notifications$ | async)?.length === 0">Clear all</button>
</div>
