import { HttpContextToken, HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AccessToken, AuthState, IDToken, OktaAuth, RefreshToken, Tokens, UserClaims } from '@okta/okta-auth-js';
import { assertNever } from 'assert-never';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs/operators';

import { InactivityService } from '@shure/shared/angular/inactivity';
import { ILogger } from '@shure/shared/angular/logging';
import { APP_ENVIRONMENT, AppEnvironment, TokenError } from '@shure/shared/models';

interface UserProfile {
	firstName: string;
	lastName: string;
	email: string;
	locale?: string;
	role: string;
	userId: string;
	orgId: string;
	orgName: string;
}
/* eslint-disable @typescript-eslint/naming-convention */
interface DecodedToken {
	shure_role: string;
	shure_tenant: string;
	shure_userId: string;
	shure_tenant_name: string;
}
/* eslint-disable @typescript-eslint/naming-convention */
interface TokenResponse {
	access_token: AccessToken;
	id_token: IDToken;
	refresh_token: RefreshToken;
}

/**
 *
 * @param oktaInterface
 * @param callBacks
 *        Optional methods to invoke when/if the user logs In or logs Out.
 * @returns
 */
export function monitorLoginState(
	oktaInterface: OktaInterfaceService,
	callBacks: {
		onLogIn?: () => void;
		onLogOut?: () => void;
	}
) {
	oktaInterface.$isUserAuthenticated.subscribe((userLoggedIn) => {
		if (userLoggedIn) {
			if (callBacks.onLogIn) callBacks.onLogIn();
		} else {
			if (callBacks.onLogOut) callBacks.onLogOut();
		}
	});
}

@Injectable({
	providedIn: 'root'
})
export class OktaInterfaceService {
	public $isUserAuthenticated!: Observable<boolean>;
	public $idTokenClaims = new BehaviorSubject<UserClaims | null>(null);
	public $accessTokenClaims = new BehaviorSubject<UserClaims | null>(null);

	constructor(
		public authStateService: OktaAuthStateService,
		public inactivityService: InactivityService,
		private translocoService: TranslocoService, // TODO: LBF 3/14/2022 - remove CMR-54
		public http: HttpClient,
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		private readonly logger: ILogger,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private readonly router: Router
	) {
		this.logger = logger.createScopedLogger('OktaInterfaceService');

		// reduce the amount of logging... we only care about the
		// auth State events, and not the token events
		this.$isUserAuthenticated = this.authStateService.authState$.pipe(
			filter((s: AuthState) => !!s && s.isAuthenticated !== undefined),
			map((s: AuthState) => s.isAuthenticated ?? false),
			tap((isAuthenticated: boolean) => {
				this.logger.debug(`authState$: `, `${isAuthenticated}`);
			}),

			distinctUntilChanged(),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		this.$isUserAuthenticated.subscribe((isAuthenticated) => {
			if (isAuthenticated) {
				this.handleToAuthorizedTransition();
			} else {
				this.handleToUnauthorizedTransition();
			}
		});

		this.inactivityService.idleTimeout$.subscribe(() => this.signOut());
		this.inactivityService.signOutNow$.subscribe(() => this.signOut());
	}

	/**
	 * signOut a user.
	 * This method will signOut a user from the application. Based on an env setting, it will
	 * either signout the user from Okta or just from the app (local)
	 * Details here: https://developer.okta.com/docs/guides/sign-users-out/react/main/
	 */
	public async signOut(): Promise<void> {
		const signOutScope = this.appEnv.signOutScope ?? 'signout-okta';
		this.logger.debug('signOut', 'signOutScope', signOutScope);

		switch (signOutScope) {
			case 'signout-okta':
				this.signOutOkta();
				break;

			case 'signout-app':
				this.signOutOktaApp();
				break;

			default:
				assertNever(signOutScope);
		}
	}
	/**
	 * This method Signs Out the user completely from the Okta
	 * Reference: https://help.okta.com/en-us/content/topics/apps/apps_single_logout.htm#:~:text=Enable%20SLO%20for%20OIDC%20integrations
	 */
	public signOutOkta(): void {
		const id_token = this.oktaAuth.getIdToken();
		//To clear the okta token from the User's browser
		localStorage.setItem('okta-token-storage', '');
		//To clear the user's session in the Okta
		window.open(
			`${this.oktaAuth.options.issuer}/v1/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${this.oktaAuth.options.logoutUrl}`,
			'_self'
		);
	}

	/**
	 * This method Signs Out the user from the respective app
	 */
	public signOutOktaApp() {
		this.oktaAuth.tokenManager.clear();
		window.open(this.oktaAuth.options.postLogoutRedirectUri, '_self');
	}

	/**
	 * @returns Okta's accessToken string
	 */
	public getAccessTokenJWT(): string {
		return <string>this.oktaAuth.getAccessToken();
	}

	/**
	 * @returns Okta's refreshToken string
	 */
	public getRefreshTokenJWT(): string {
		return <string>this.oktaAuth.getRefreshToken();
	}

	/**
	 * Make a POST call to OKTA to exercise the inline hook with the supplied tenant ID
	 * The Okta Inline hook will return a JWT with the tenant ID in the shure_tenant to use for the session
	 * @param tenantId
	 * @returns
	 */
	public setOktaSessionTenant$(tenantId: string): Observable<void> {
		/* eslint-disable @typescript-eslint/naming-convention */
		const oktaScopes = <string[]>this.oktaAuth.options.scopes;
		const body = new URLSearchParams();
		body.set('client_id', encodeURIComponent(<string>this.oktaAuth.options.clientId));
		body.set('grant_type', encodeURIComponent('refresh_token'));
		body.set('scope', oktaScopes.toString().replace(/,/gi, ' '));
		body.set('refresh_token', encodeURIComponent(this.getRefreshTokenJWT()));

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.http
			.post<TokenResponse>(`${this.oktaAuth.options.issuer}/v1/token`, body.toString(), {
				headers: headers,
				params: { shure_tenant: tenantId }
			})
			.pipe(
				take(1),
				map((response: TokenResponse) => {
					const responseTokens: Tokens = {
						accessToken: response.access_token,
						idToken: response.id_token,
						refreshToken: response.refresh_token
					};
					this.updateTokenStorage(responseTokens);
				}),
				catchError((error) => {
					// Handle the error here, re-throw the error
					return throwError(() => error);
				})
			);
		/* eslint-enable */
	}

	public getUserEmail$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.email;
			})
		);
	}

	public getUsername$(): Observable<string> {
		return this.$idTokenClaims.pipe(
			map((tokenClaims) => {
				return <string>tokenClaims?.name;
			})
		);
	}

	public getUserFirstName$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.firstName;
			})
		);
	}

	public getUserLastName$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.lastName;
			})
		);
	}

	public getUserRole$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.role;
			})
		);
	}

	public getUserId$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.userId;
			})
		);
	}

	public getDefaultOrgId$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.orgId;
			})
		);
	}

	public getUserProfile$(): Observable<UserProfile> {
		return this.$idTokenClaims.pipe(
			filter((tokenClaims) => !!tokenClaims),
			map((tokenClaims) => {
				const name = <string>tokenClaims?.name;
				const btoken: string | null = window.localStorage.getItem('okta-token-storage');
				// const userType = btoken ? JSON.parse(btoken).idToken.claims.shure_role : null;
				// const uId = btoken ? JSON.parse(btoken).idToken.claims.shure_userId : null;
				// const orgId = btoken ? JSON.parse(btoken).idToken.claims.shure_tenant : null;
				const idToken = btoken ? JSON.parse(btoken).idToken.idToken : null;
				const idTokenData = { userType: '', uId: '', orgId: '', orgName: '' };
				if (idToken) {
					const decodedIdToken = <DecodedToken>jwt_decode(idToken);

					if (decodedIdToken) {
						idTokenData.userType = decodedIdToken.shure_role;
						idTokenData.uId = decodedIdToken.shure_userId;
						idTokenData.orgId = decodedIdToken.shure_tenant;
						idTokenData.orgName = decodedIdToken.shure_tenant_name;
					}
				}

				return {
					firstName: name ? name.split(' ')[0] : '',
					lastName: name ? name.split(' ')[1] : '',
					email: tokenClaims?.email ? tokenClaims?.email : '',
					// TODO: LBF 3/14/2022 - get locale from Okta when available
					// locale: tokenClaims?.locale ? tokenClaims?.locale : 'en',
					locale: this.translocoService.getActiveLang(),
					role: idTokenData.userType,
					userId: idTokenData.uId,
					orgId: idTokenData.orgId,
					orgName: idTokenData.orgName
				};
			})
		);
	}

	public handleToUnauthorizedTransition(): void {
		this.logger.debug('handleToUnauthorizedTranstion', 'stopping inactivity monitoring');
		this.inactivityService.stopMonitoring();
		this.oktaAuth.tokenManager.off('renewed');
	}

	/**
	 * Update the tokens in storage
	 * This will trigger the this.authStateService.authState$ subscription
	 * @param responseTokens
	 */
	public updateTokenStorage(responseTokens: Tokens): void {
		const storageToken = this.oktaAuth.storageManager.getTokenStorage().getStorage();
		storageToken.accessToken.accessToken = responseTokens.accessToken;
		storageToken.refreshToken.refreshToken = responseTokens.refreshToken;
		storageToken.idToken.idToken = responseTokens.idToken;
		return this.oktaAuth.tokenManager.setTokens(storageToken);
	}

	public handleToAuthorizedTransition(): void {
		this.logger.debug('handleToUnauthorizedTranstion', 'starting inactivity monitoring, emitting user claim info');
		this.inactivityService.startMonitoring();
		this.oktaAuth.tokenManager.on('renewed', (key, token) => {
			// only one token is emitted at a time
			if (key === 'idToken') {
				this.validateIdToken(<IDToken>token);
			}
			if (key === 'accessToken') {
				this.validateAccessToken(<AccessToken>token);
			}
		});
		this.emitCurrentUserClaimInfo();
	}

	private hasValidRoles(role: string): boolean {
		if (this.appEnv.appRoles.length < 1) {
			return true;
		}

		return this.appEnv.appRoles.some((appRole) => role === appRole);
	}

	private validateAccessToken(token: AccessToken) {
		// eslint-disable-next-line dot-notation
		const tenant = token.claims['shure_tenant'];
		const missingTenant = tenant === undefined;
		this.logger.debug('validateToken', 'shure_tenant from token', tenant);

		if (!missingTenant || this.appEnv.appType === 'admin') {
			return;
		}
		// we are in shure cloud and should redirect to the main page to get the error dialog
		if (this.appEnv.appType === 'cloud') {
			// MM 04/16/2024 - As per requirement in ORG-2420 This code is commented so that no error dialog is displayed and restricting auto signout, considering consumer login experience scenario.
			// this.router.navigate([''], {
			// 	state: { error: TokenError.MissingTenant }
			// });
		} else {
			// we are in a portal and should logout and return to shure cloud to handle the error
			this.signOut();
		}
	}

	private validateIdToken(token: IDToken) {
		// eslint-disable-next-line dot-notation
		const role = token.claims['shure_role']?.toString();
		const missingRole = !this.hasValidRoles(role);
		this.logger.debug('validateToken', 'shure_tole from token', role);

		if (!missingRole) {
			return;
		}
		// we are in shure cloud and should redirect to the main page to get the error dialog
		if (this.appEnv.appType === 'cloud') {
			// MM 04/16/2024 - As per requirement in ORG-2420 This code is commented so that no error dialog is displayed and restricting auto signout, considering consumer login experience scenario.
			// this.router.navigate([''], {
			// 	state: { error: TokenError.MissingRole }
			// });
		} else {
			// we are in a portal and should logout and return to shure cloud to handle the error
			this.signOut();
		}
	}

	// NOTE: this class exists in ignite, and all other portals. The check/logout needs to be generic enough
	// to work for all of them.
	private async emitCurrentUserClaimInfo(): Promise<void> {
		const tokens = await this.oktaAuth.tokenManager.getTokens();

		if (tokens.accessToken && tokens.idToken) {
			this.validateAccessToken(tokens.accessToken);
			this.validateIdToken(tokens.idToken);
			this.$accessTokenClaims.next(tokens.accessToken.claims);
			this.$idTokenClaims.next(tokens.idToken.claims);
		} else {
			// We need both tokens for this app to work
			// id token to know their permissions
			// access token to pass to the API
			this.router.navigate([''], {
				state: {
					error: TokenError.Unknown
				}
			});
		}
	}
}

export const IS_SKIP_ORG_HEADER = new HttpContextToken<boolean>(() => false);
