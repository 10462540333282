import assertNever from 'assert-never';
import { Duration } from 'ts-luxon';

import {
	IpMode,
	DeviceModel,
	DeviceState,
	FirmwarePackage,
	ProxiedTransmitter,
	ProxiedTransmitterStatus
} from '@shure/shared/models';

import {
	DeviceStatus,
	DoublestuffProxiedTransmitterFragment,
	PropertyPanelDeviceFragment,
	DoubleStuffProxiedTransmitterStatus,
	IpMode as SysApiMode
} from '../../../graphql/generated/cloud-sys-api';

// wangth: The usage of this value should be removed, and replaced with test for isMissing attribute,
// which should go all the way to UI.
export const MISSING_VALUE = '..';

export function mapFeatureValueFromSysApi(value?: string | null): string {
	return value ?? MISSING_VALUE;
}

export function mapIpModeFromSysApi(value: SysApiMode | undefined): IpMode | undefined {
	if (value === undefined) {
		return undefined;
	}
	switch (value) {
		case SysApiMode.Dhcp:
			return IpMode.DHCP;
		case SysApiMode.Static:
			return IpMode.STATIC;
		default:
			assertNever(value);
	}
}

export function mapDeviceModelFromSysApi(model: string): DeviceModel {
	switch (model) {
		case 'MXA920-S':
			return DeviceModel.MXA920S;
		case 'MXA920-R':
			return DeviceModel.MXA920R;
		case 'P300':
			return DeviceModel.P300;
		case 'MXWAPXD2':
			return DeviceModel.MXWAPXD2;
		case 'MXA710-4FT':
			return DeviceModel.MXA7104FT;
		case 'MXA710-2FT':
			return DeviceModel.MXA7102FT;
		case 'ANIUSB-MATRIX':
			return DeviceModel.ANIUSB;
		case 'MXA901-R':
			return DeviceModel.MXA901R;
		case 'MXA901-S':
			return DeviceModel.MXA901S;
		case 'MXA902-S':
			return DeviceModel.MXA902S;
		case 'IMX-Room':
			return DeviceModel.IMXR;
		case 'MXN5-C':
			return DeviceModel.MXN5C;
		case 'ANX4':
			return DeviceModel.MCR;
		case 'AD600':
			return DeviceModel.AD600;
		case 'ADTD':
			return DeviceModel.ADTD;
		case 'ADTQ':
			return DeviceModel.ADTQ;
		default:
			return DeviceModel.UNKNOWN;
	}
}

export function mapDeviceStateFromSysApi(deviceStatus: DeviceStatus): DeviceState {
	switch (deviceStatus) {
		case DeviceStatus.Discovered:
			return DeviceState.Discovered;
		case DeviceStatus.Error:
			return DeviceState.Error;
		case DeviceStatus.Offline:
			return DeviceState.Offline;
		case DeviceStatus.Online:
			return DeviceState.Online;
		default:
			return DeviceState.Error;
	}
}

export function mapUptimeDurationFromSysApi(duration: string | undefined): string {
	if (!duration || duration === MISSING_VALUE) return '';

	// Converts ISO 8601 time duration to [h]:mm:ss
	return Duration.fromISO(duration).toFormat('hh:mm:ss');
}

export function mapFirmwarePackagesFromSysApi(
	availablePkgs: PropertyPanelDeviceFragment['features']['availablePackages']
): FirmwarePackage[] {
	const array = Array.from(<FirmwarePackage[]>availablePkgs?.primaryPackages ?? []);

	const sortFn = (a: FirmwarePackage, b: FirmwarePackage): number => {
		// example: 12.34.56.7 -> 00012000340005600007
		const aVersion = a.version
			.split('.')
			.map((n) => n.toString().padStart(5, '0')) // max of 65535 per hextet
			.join('');

		const bVersion = b.version
			.split('.')
			.map((n) => n.toString().padStart(5, '0')) // max of 65535 per hextet
			.join('');

		if (aVersion > bVersion) {
			return -1;
		} else if (bVersion > aVersion) {
			return 1;
		}

		return 0;
	};

	return array.sort(sortFn);
}

export function mapProxiedTransmitterFromSysApi(
	transmitter?: DoublestuffProxiedTransmitterFragment | null
): ProxiedTransmitter {
	return {
		status: mapProxiedTransmitterStatusFromSysApi(transmitter?.status),
		name: transmitter?.name,
		online: transmitter?.online,
		batteryLevel: mapProxiedTransmitterBatteryLevelFromSysApi(transmitter?.batteryLevelPercentage),
		muted: transmitter?.muted ?? undefined
	};
}

function mapProxiedTransmitterStatusFromSysApi(
	transmitterStatus?: DoubleStuffProxiedTransmitterStatus | null
): ProxiedTransmitterStatus {
	switch (transmitterStatus) {
		case 'ACTIVE':
			return ProxiedTransmitterStatus.Active;
		case 'CHARGING':
			return ProxiedTransmitterStatus.Charging;
		default:
			return ProxiedTransmitterStatus.Unknown;
	}
}

function mapProxiedTransmitterBatteryLevelFromSysApi(batteryLevel?: number | null): number | undefined {
	if (batteryLevel === undefined || batteryLevel === null) {
		return undefined;
	}

	return batteryLevel / 100;
}
