<div *ngIf="params.data" id="sh-endpoint-name-renderer-cell-div">
	<span id="sh-endpoint-name-renderer-container-span">
		<sh-device-state-bar
			*ngIf="!params.data.hideStateBar"
			id="sh-endpoint-name-renderer-device-state"
			[deviceState]="params.data.deviceState"
		></sh-device-state-bar>
		<sh-device-image
			id="sh-endpoint-name-renderer-device-image"
			[deviceModel]="params.data.model"
			[channelCount]="getChannelCount(params.data.channel)"
		>
		</sh-device-image>
		<span id="sh-endpoint-name-renderer-text-span">{{ params.data.name }}</span>
	</span>
</div>
