import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NavButtonComponent, NavigationButtonGroupComponent } from './navigation-button-group.component';

@NgModule({
	declarations: [NavigationButtonGroupComponent, NavButtonComponent],
	exports: [NavigationButtonGroupComponent, NavButtonComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule]
})
export class NavigationButtonGroupModule {}
