import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { DeviceClaimingApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import {
	ClaimResults,
	DeletePendingClaimBatchResult,
	DeviceClaimProgress,
	DeviceDetection,
	MacSerialNumberDevice,
	PendingClaim
} from '@shure/shared/models';

@Injectable({
	providedIn: 'root'
})
export class DaiMockDeviceClaimingService implements DeviceClaimingApiService {
	public pendingClaims$ = new BehaviorSubject<PendingClaim[]>([]);
	public claimOpInProgress$ = new BehaviorSubject<boolean>(false);
	public deleteInProgress$ = new BehaviorSubject<boolean>(false);

	public getPendingClaims$(): Observable<PendingClaim[]> {
		return this.pendingClaims$.asObservable();
	}

	public claimDevicesThrottled$(devices: DeviceDetection[]): Observable<DeviceClaimProgress<DeviceDetection>> {
		return new Observable<DeviceClaimProgress<DeviceDetection>>((subscriber) => {
			const progress: DeviceClaimProgress<DeviceDetection> = {
				done: false,
				stats: {
					totalToDo: devices.length,
					success: 0,
					inProgress: 0,
					failed: 0
				},
				devices: []
			};

			this.claimOpInProgress$.next(true);
			subscriber.next(progress);

			devices.forEach((d) => {
				progress.stats.inProgress++;
				progress.devices.push({
					result: 'in-progress',
					device: d
				});
				subscriber.next(progress);
			});

			devices.forEach((d) => {
				progress.stats.inProgress--;
				progress.stats.success++;
				const pd = progress.devices.find((pd) => pd.device.device.id === d.device.id);
				if (pd) {
					pd.result = 'success';
				}
				subscriber.next(progress);
			});

			progress.done = true;
			subscriber.next(progress);
			subscriber.complete();
			this.claimOpInProgress$.next(false);
		});
	}

	public claimDevicesByMacSerialThrottled$(
		devices: MacSerialNumberDevice[]
	): Observable<DeviceClaimProgress<MacSerialNumberDevice>> {
		// unless the mock version needs to do something different, just reuse the
		// mock claim for by DetectionToken.
		return this.claimDevicesThrottled$(<DeviceDetection[]>devices);
	}

	unClaimDevicesThrottled$(_deviceNodeIds: string[]): Observable<ClaimResults> {
		const retVal$ = new Subject<ClaimResults>();
		return retVal$.asObservable();
	}

	public listPendingClaims$(): Observable<PendingClaim[]> {
		return of([
			{
				id: 'test',
				macAddress: '',
				serialNumber: '',
				createdAt: ''
			}
		]);
	}
	public deletePendingClaimsThrottled$(_deviceNodeIds: string[]): Observable<DeletePendingClaimBatchResult> {
		return of({
			total: 1,
			numSuccess: 1,
			numError: 0,
			errors: []
		});
	}
}
