<ng-container *ngIf="(oktaIntf.$isUserAuthenticated | async) === false; else UserLoggedIn">
	<mat-toolbar color="primary" class="top-nav-bar" id="sh-top-ribbon-logged-out-toolbar">
		<img height="48" src="./assets/images/shure_logo.png" id="sh-top-ribbon-logged-out-logo" />
	</mat-toolbar>
</ng-container>

<ng-template #UserLoggedIn>
	<mat-toolbar color="primary" id="sh-top-ribbon-logged-in-toolbar" class="shure-top-ribbon-info" *transloco="let t">
		<!-- Only show the Shure Logo and title when configured to do so -->
		<ng-container *ngIf="showShureLogoWhenSignedIn === true">
			<img
				height="50"
				src="./assets/images/shure_logo.png"
				id="sh-top-ribbon-logged-in-logo"
				(click)="onLogoClick()"
			/>
			<label class="toolbar-app-name-label" id="sh-top-ribbon-app-name-label" (click)="onLogoClick()">{{
				t(title)
			}}</label>
		</ng-container>

		<!-- Only show the sidenav toggle button when configured to do so, and
		when we're on a small device  -->
		<ng-container *ngIf="showSidenavToggleButton === true && (smallDevice$ | async) === true">
			<button class="menu" mat-icon-button (click)="onSidenavToggle()">
				<mat-icon fontSet="prism-mat" fontIcon="menu"></mat-icon>
			</button>
		</ng-container>

		<!-- The magical spacing of fxFlex -- this pushes everything to the right. -->
		<span fxFlex></span>

		<ng-container *ngIf="showNotifications">
			<div class="notification-icon-container" style="margin-right: 50px" (click)="notificationsPopup()">
				<mat-icon
					class="icon-style"
					fontIcon="notifications"
					id="sh-notifications-icon"
					color="white"
				></mat-icon>
				<div class="notification-count" *ngIf="notificationsCount !== null && notificationsCount > 0">
					{{ notificationsCount > 9 ? '9+' : notificationsCount }}
				</div>
			</div>
		</ng-container>

		<button
			class="sh-top-arrow"
			mat-icon-button
			[matMenuTriggerFor]="menu"
			color="accent"
			id="sh-top-ribbon-button-user"
		>
			<div>
				<mat-icon role="shure" id="sh-person-icon" class="valign-middle" fontSet="prism-mat" fontIcon="person">
				</mat-icon>
			</div>
		</button>
		<mat-menu class="person-menu" #menu="matMenu" id="sh-top-ribbon-user-menu">
			<button mat-menu-item (click)="goToAccount()" *ngIf="showAccount" id="sh-top-ribbon-button-account">
				<span>{{ t('my-account') }}</span>
			</button>

			<button id="sh-top-ribbon-button-about" mat-menu-item (click)="openAboutDialog()" *ngIf="showAbout">
				<span id="sh-top-ribbon-span-about">{{ t('about') }} </span>
			</button>

			<button
				mat-menu-item
				(click)="notifications()"
				*ngIf="showNotifications"
				id="sh-top-ribbon-button-notifications"
			>
				<span id="sh-top-ribbon-span-notifications">{{ t('notifications') }}</span>
			</button>

			<button mat-menu-item (click)="preferences()" id="sh-top-ribbon-button-preferences" *ngIf="showPreferences">
				<span id="sh-top-ribbon-span-preferences">{{ t('preferences') }}</span>
			</button>

			<button mat-menu-item (click)="logout()" id="sh-top-ribbon-button-logout">
				<span id="sh-top-ribbon-menu-sign-out">{{ t('sign-out') }} </span>
			</button>
		</mat-menu>
	</mat-toolbar>
</ng-template>
