<button
	(click)="identifyToggle($event)"
	*transloco="let t"
	[disabled]="readonly || isVirtual"
	mat-icon-button
	[matTooltip]="t('common.identify.click-to-identify')"
	matTooltipPosition="after"
	matTooltipShowDelay="75"
	class="sh-device-identify"
	[ngClass]="{ 'sh-is-identifying': isIdentifying }"
	fxLayout
	fxLayoutAlign="center center"
>
	<span class="sh-device-identify-start-button">{{ t('common.identify.id') }}</span>
	<div *ngIf="isIdentifying" class="sh-device-identify-stop-button" fxLayout fxLayoutAlign="center center">
		<span class="sh-device-identify-stop-button-rectangle"></span>
	</div>
	<sh-device-image
		class="sh-device-image"
		[deviceModel]="deviceModel"
		[isVirtual]="isVirtual"
		[isPasswordEnabled]="isPasswordEnabled"
	></sh-device-image>
</button>
