import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { OktaAuthModule, OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

import { appEnvironmentGuard } from '@shure/shared/angular/app-environment-guard';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SigninGuard } from '@shure/shared/angular/okta-signin';

const appRoutes: Route[] = [
	{
		path: '',
		redirectTo: 'devices',
		pathMatch: 'full'
	},
	{
		path: 'signin',
		loadChildren: () => import('@shure/shared/angular/okta-signin').then((m) => m.OktaSignInModule),
		canActivate: [SigninGuard]
	},

	{
		path: 'devices',
		loadChildren: () => import('@shure/cloud-apps/devices/feature-devices').then((m) => m.CloudDevicesModule),
		canActivate: [OktaAuthGuard]
	},
	{
		path: 'account',
		loadComponent: () =>
			import('../components/my-account-wrapper/my-account-wrapper.component').then(
				(m) => m.MyAccountWrapperComponent
			),
		canActivate: [OktaAuthGuard]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	{
		path: 'settings',
		loadComponent: () =>
			import('../components/device-settings-wrapper/device-settings-wrapper.component').then(
				(m) => m.DeviceSettingsWrapperComponent
			),
		canActivate: [OktaAuthGuard, appEnvironmentGuard(['showUserSettings'])]
	},
	// ** is the "page not found" route.
	// Try to go to /devices. If the user isn't logged in, the auth guard
	// will redirect them to the Signin Page.
	{
		path: '**',
		redirectTo: 'devices',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: false // must be false for okta signin/callback to route.
		}),
		OktaAuthModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
