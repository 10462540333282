export enum DeviceModel {
	MXA7102FT = 'MXA710-2FT',
	MXA7104FT = 'MXA710-4FT',
	MXA920S = 'MXA920-S',
	MXA920R = 'MXA920-R',
	P300 = 'P300',
	MXWAPXD2 = 'MXWAPXD2',
	ANIUSB = 'ANIUSB-MATRIX',
	MXA901R = 'MXA901-R',
	MXA901S = 'MXA901-S',
	MXA902S = 'MXA902-S',
	IMXR = 'IMX-Room',
	MXN5C = 'MXN5-C',
	MCR = 'ANX4',
	AD600 = 'AD600',
	ADTD = 'ADTD',
	ADTQ = 'ADTQ',
	UNKNOWN = 'UNKNOWN'
}
