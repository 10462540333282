import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { McrDeviceBehaviorPluginBase } from './mcr.device-behavior';

@Injectable({ providedIn: 'root' })
export class McrDesignerDeviceBehaviorPlugin extends McrDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
