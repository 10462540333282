import { TranslocoService } from '@ngneat/transloco';
import { first, map, Observable } from 'rxjs';

export function translocoInitializer(translocoService: TranslocoService): () => Observable<boolean> {
	return () =>
		translocoService.selectTranslation().pipe(
			first(),
			map((_) => true)
		);
}
