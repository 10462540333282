<div *transloco="let t">
	<h1 class="sh-title" mat-dialog-title id="sh-org-details-h1">{{ data.title }}</h1>
	<form [formGroup]="formGroup" *transloco="let t" class="dialog-form-fields">
		<div class="sh-dialog-content">
			<div class="details-container">
				<div>
					<mat-form-field id="sh-org-details-field-userId">
						<mat-label> {{ t('userId') }}/{{ t('email') }}</mat-label>
						<input
							matInput
							type="text"
							[placeholder]="t('userId') + '/' + t('email')"
							formControlName="userId"
						/>
						<mat-error
							*ngIf="
								formGroup.controls['userId'].hasError('required') &&
								formGroup.controls['userId'].touched
							"
							>{{ t('common.errors.user-id-email-required') }}
						</mat-error>
						<mat-error
							*ngIf="
								formGroup.controls['userId'].hasError('pattern') && formGroup.controls['userId'].touched
							"
							>{{ t('enter-a-valid-email-id') }}
						</mat-error>
					</mat-form-field>
					<mat-form-field id="sh-org-details-field-number">
						<mat-label class="sh-account-number-label"> {{ t('account-number') }} </mat-label>
						<span matTextPrefix>{{ t('account-number-prefix') }}</span>
						<mat-icon
							matSuffix
							matTooltip="{{ t('unique-identifier-salesforce') }}"
							fontSet="prism-mat"
							fontIcon="info"
						></mat-icon>
						<input matInput type="text" [placeholder]="t('account-number')" formControlName="number" />
						<mat-error
							*ngIf="
								formGroup.controls['number'].hasError('required') &&
								formGroup.controls['number'].touched
							"
							>{{ t('common.errors.account-number-required') }}
						</mat-error>
						<mat-error
							*ngIf="
								formGroup.controls['number'].hasError('pattern') && formGroup.controls['number'].touched
							"
							>{{ t('only-numerics-are-allowed') }}
						</mat-error>
					</mat-form-field>
				</div>
				<div
					*ngIf="!retrievedOrgData && data.actionType === orgDetailsActionType.Create"
					id="sh-org-details-retrieve-div"
				>
					<button mat-raised-button id="sh-org-details-retrieve-button" (click)="retrieveOrgData()">
						{{ t('retrieve-org-data') }}
					</button>
				</div>
				<ng-container *ngIf="retrievedOrgData">
					<div>
						<mat-form-field id="sh-org-details-field-user-first-name-form-field">
							<mat-label id="sh-org-details-field-user-first-name-label">
								{{ t('first-name') }}</mat-label
							>
							<input
								matInput
								type="text"
								[placeholder]="t('first-name')"
								formControlName="userFirstName"
							/>
							<mat-error
								*ngIf="
									formGroup.controls['userFirstName'].hasError('required') &&
									formGroup.controls['userFirstName'].touched
								"
								>{{ t('common.errors.first-name-required') }}
							</mat-error>
							<mat-error
								*ngIf="
									formGroup.controls['userFirstName'].hasError('pattern') &&
									formGroup.controls['userFirstName'].touched
								"
								>{{ t('spaces-not-allowed') }}
							</mat-error>
						</mat-form-field>

						<mat-form-field id="sh-org-details-user-last-name-form-field">
							<mat-label id="sh-org-details-user-last-name-label"> {{ t('last-name') }}</mat-label>
							<input matInput type="text" [placeholder]="t('last-name')" formControlName="userLastName" />
							<mat-error
								*ngIf="
									formGroup.controls['userLastName'].hasError('required') &&
									formGroup.controls['userLastName'].touched
								"
								>{{ t('common.errors.last-name-required') }}
							</mat-error>
							<mat-error
								*ngIf="
									formGroup.controls['userLastName'].hasError('pattern') &&
									formGroup.controls['userLastName'].touched
								"
								>{{ t('spaces-not-allowed') }}
							</mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field id="sh-org-details-field-name">
							<mat-label> {{ t('company-name') }}</mat-label>
							<input matInput type="text" [placeholder]="t('company-name')" formControlName="name" />
							<mat-icon
								id="sh-org-details-field-name-info-icon"
								matSuffix
								matTooltip="{{ t('field-name-info') }}"
								fontSet="prism-mat"
								fontIcon="info"
							></mat-icon>
							<mat-error
								*ngIf="
									formGroup.controls['name'].hasError('required') &&
									formGroup.controls['name'].touched
								"
								>{{ t('common.errors.org-name-required') }}
							</mat-error>
						</mat-form-field>
						<mat-form-field id="sh-org-details-field-country">
							<mat-label> {{ t('country') }}</mat-label>
							<mat-select formControlName="country" id="sh-org-details-field-country-select">
								<mat-option *ngFor="let country of countries" [value]="country.id">
									{{ country.label }}
								</mat-option>
							</mat-select>
							<mat-error
								*ngIf="
									formGroup.controls['country'].hasError('required') &&
									formGroup.controls['country'].touched
								"
								>{{ t('common.errors.country-required') }}
							</mat-error>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field id="sh-org-details-field-description">
							<mat-label> {{ t('description') }}</mat-label>
							<input
								matInput
								type="text"
								[placeholder]="t('description')"
								formControlName="description"
							/>
						</mat-form-field>
						<mat-form-field id="sh-org-details-domains-field">
							<mat-label id="sh-org-details-domains-label"> {{ t('domains') }}</mat-label>
							<input
								id="sh-org-details-domains-input"
								matInput
								type="text"
								[placeholder]="t('domains')"
								formControlName="domains"
								matTooltip="{{ formGroup.controls['domains'].value }}"
								[matTooltipDisabled]="!isInputDisabled"
								[ngClass]="isInputDisabled ? 'sh-org-details-ellipse' : ''"
							/>
							<mat-icon
								id="sh-org-details-domains-info-icon"
								matSuffix
								matTooltip="{{ t('domain-names-info') }}"
								fontSet="prism-mat"
								fontIcon="info"
							></mat-icon>
							<mat-error
								*ngIf="
									formGroup.controls['domains'].hasError('required') &&
									formGroup.controls['domains'].touched
								"
								>{{ t('common.errors.domains-required') }}
							</mat-error>
						</mat-form-field>
					</div>
				</ng-container>
			</div>
			<div class="button-list">
				<button
					mat-button
					color="accent"
					role="shure"
					(click)="onCloseDialog()"
					id="sh-org-details-button-cancel"
				>
					{{ t(data.actionType === orgDetailsActionType.View ? 'close' : 'cancel') }}
				</button>
				<button
					*ngIf="data.actionType !== orgDetailsActionType.View"
					[disabled]="formGroup.invalid || !isFormChanged"
					id="sh-org-details-ok-button"
					mat-button
					color="accent"
					type="button"
					(click)="doAction()"
				>
					<span id="sh-org-details-span-ok-button-text">{{
						t(
							data.actionType === orgDetailsActionType.Create
								? 'create-organization'
								: 'update-organization'
						)
					}}</span>
				</button>
			</div>
		</div>
	</form>
</div>
