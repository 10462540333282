import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { DeviceModel } from '@shure/shared/models';

import { DeviceBehaviorPlugin } from './models';

/**
 * Injection token that can be used to specify device behavior plugins.
 */
export const DEVICE_BEHAVIOR_PLUGIN_TOKEN = new InjectionToken<DeviceBehaviorPlugin[]>(
	'__@shure/device-plugins/plugin__'
);

/**
 * Provides access to all registered `DeviceBehaviorPlugins` and can be used by
 * features to retrieve feature plugins for a given device.
 */
@Injectable({ providedIn: 'root' })
export class DeviceBehaviorPluginsService {
	/**
	 * All registered plugins.
	 */
	private readonly plugins: DeviceBehaviorPlugin[];

	/**
	 * Map of `DeviceModel` to `DeviceBehaviorPlugin`.
	 */
	private readonly _plugins: Record<DeviceModel, DeviceBehaviorPlugin>;

	constructor(@Inject(DEVICE_BEHAVIOR_PLUGIN_TOKEN) @Optional() plugins: DeviceBehaviorPlugin[]) {
		this.plugins = plugins || [];
		this._plugins = this.plugins.reduce(
			(all, plugin) => ({
				...all,
				...plugin.models.reduce((a, m) => ({ ...a, [m]: plugin }), {})
			}),
			<Record<DeviceModel, DeviceBehaviorPlugin>>{}
		);
	}

	/**
	 * Get plugin for specific device model.
	 *
	 * Will apply filter if passed to ensure plugins matches required interface.
	 *
	 * Example:
	 * ```ts
	 * // Get plugin for P300 model with filtering
	 * const plugins = s.get(DeviceModel.P300);
	 *
	 * // Get plugin for P300 model that has property 'myFunction'
	 * const pluginsOfA = s.get<A>(DeviceModel.P300, ['myFunction']);
	 *
	 * // Get plugin for P300 model that matches custom filter e.g. has property 'myFunction'
	 * const pluginsOfB = s.get<B>(DeviceModel.P300, (plugin: B) => 'myFunction' in plugin);
	 * ```
	 */
	public get<T extends object = DeviceBehaviorPlugin>(model: DeviceModel): T | undefined;
	public get<T extends object = DeviceBehaviorPlugin>(model: DeviceModel, filter: Array<keyof T>): T | undefined;
	public get<T extends object = DeviceBehaviorPlugin>(
		model: DeviceModel,
		filter: (plugin: T) => plugin is T
	): T | undefined;
	public get<T extends object = DeviceBehaviorPlugin>(
		model: DeviceModel,
		filter?: Array<keyof T> | ((plugin: T) => plugin is T)
	): T | undefined {
		const plugin = <T>this._plugins[model];
		if (plugin && this.filter(plugin, filter)) {
			return plugin;
		}

		return undefined;
	}

	/**
	 * Get all registered plugins.
	 *
	 * Will apply filter if passed to ensure plugins matches required interface.
	 *
	 * Example:
	 * ```ts
	 * // Get all plugins without filtering.
	 * const plugins = s.getAll();
	 *
	 * // Get all plugins of type A that has property 'myFunction'
	 * const pluginsOfA = s.getAll<A>(['myFunction']);
	 *
	 * // Get all plugins of type B that matches custom filter e.g. has property 'myFunction'.
	 * const pluginsOfB = s.getAll<B>((plugin: B) => 'myFunction' in plugin);
	 * ```
	 */
	public getAll<T extends object = DeviceBehaviorPlugin>(): T[];
	public getAll<T extends object = DeviceBehaviorPlugin>(filter: Array<keyof T>): T[];
	public getAll<T extends object = DeviceBehaviorPlugin>(filter: (plugin: T) => plugin is T): T[];
	public getAll<T extends object = DeviceBehaviorPlugin>(
		filter?: Array<keyof T> | ((plugin: T) => plugin is T)
	): T[] {
		if (!filter) {
			return <T[]>this.plugins;
		}

		// Filter for plugins that implements required filter.
		return <T[]>this.plugins.filter((p) => this.filter(<T>p, filter));
	}

	/**
	 * Apply filter for determining if the plugin is of type T.
	 */
	private filter = <T extends object = DeviceBehaviorPlugin>(
		plugin: T,
		filter?: Array<keyof T> | ((plugin: T) => plugin is T)
	): plugin is T => {
		if (!filter) {
			return true;
		}

		return Array.isArray(filter) ? filter.every((prop) => prop in plugin) : filter(plugin);
	};
}
