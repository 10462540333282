import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

//  per a comment where device info is exported, this content type is to account for Safari,
export const csvFileOptions = { type: 'data:application/octet-stream;charset=UTF-8' };
export const textFileOptions = { type: 'text/plain; charset=utf-8' };

@Injectable({
	providedIn: 'root'
})
export class FileSaverService {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor() {}

	saveAsCSV(destinationFileName: string, csvContents: string): void {
		const blob = new Blob([csvContents], csvFileOptions);
		saveAs(blob, destinationFileName);
	}
}
