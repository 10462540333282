import { Injectable, Inject, signal, computed, untracked, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
	map,
	Observable,
	tap,
	catchError,
	throwError,
	Subscription,
	takeUntil,
	Subject,
	BehaviorSubject,
	debounceTime,
	take
} from 'rxjs';

import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/shared/angular/apollo';
import { ILogger, jsonFormat } from '@shure/shared/angular/logging';
import { OktaInterfaceService, monitorLoginState } from '@shure/shared/angular/okta';
import { AppEnvironment, APP_ENVIRONMENT, InventoryDevice, UpdateResponse } from '@shure/shared/models';

import {
	NodeChangeType,
	InventoryDeviceSubscriptionGQL,
	InventoryDeviceByIdQueryGQL
} from '../../graphql/generated/cloud-sys-api';
import { DeviceStatusService } from '../device-status/device-status.service';
import { DaiCloudDeviceService } from '../shared/device/dai-cloud-device.service';

import { DaiDeviceDiscoveryService } from './dai-device-discovery.service';
import { InventoryDevicesApiService } from './inventory-devices.service';
import { mapInventoryDeviceFromSysApi } from './utils';

@Injectable({ providedIn: 'root' })
export class DaiInventoryDevicesService implements InventoryDevicesApiService {
	public deviceInventoryQueryInProgress$ = new BehaviorSubject<boolean>(false);
	public deviceInventory$ = new BehaviorSubject<InventoryDevice[]>([]);

	// Public signal for the device inventory loading indicator. Set to true when initial loading begins
	// and false when we think we're initially loaded. Once set to false when initial loading is done
	// it will not be set to true again unless the user were to logout/in or refresh the page.
	public deviceInventoryLoading = signal(false);

	// This signal is used like a popcorn time to help control the deviceInventoryLoading signal.
	// If we haven't loaded all the devices in the inventory, but changes stop occuring, this is set to false
	// Similar to a popcorn timer... when the kernels stop popping, or in our case, the inventory device count
	// stops changing, the timer goes off.
	private inventoryCountChangedBeforeTimeout = signal(false);

	// This signal is tied to the number of devices in the inventory. It is compared to the number
	// of discoverd devices signal exposed by the discovery service to know how many devices we should expect
	// in the inventory.
	private deviceInventoryCount = toSignal(this.getInventoryDevicesCount$());

	protected readonly logger: ILogger;
	private destroy$ = new Subject<void>();

	private readonly devicesSubscriptionManager = new SubscriptionManager({
		subscriptionType: 'inventory-devices',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: DaiCloudDeviceService,
		private readonly inventoryDeviceSubscriptionGQL: InventoryDeviceSubscriptionGQL,
		private readonly inventoryDeviceByIdQueryGQL: InventoryDeviceByIdQueryGQL,
		private readonly deviceDiscoveryService: DaiDeviceDiscoveryService,
		private readonly deviceStatusService: DeviceStatusService,
		private readonly oktaService: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DaiInventoryDevicesService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});

		effect(
			() => {
				const discoveryInProgress = this.deviceDiscoveryService.deviceDiscoveryInProgress();
				const numDiscoveredDevices = this.deviceDiscoveryService.numDiscoveredDevices();
				const numInventoryDevices = this.deviceInventoryCount();
				const inventoryCountChangedBeforeTimeout = this.inventoryCountChangedBeforeTimeout();

				let tempIsLoading = discoveryInProgress || numDiscoveredDevices !== numInventoryDevices;
				if (tempIsLoading === false) {
					this.inventoryCountChangedBeforeTimeout.set(false);
				} else if (inventoryCountChangedBeforeTimeout === false) {
					tempIsLoading = false;
				}

				this.logger.debug(
					'deviceInventoryLoading',
					'signal',
					`DiscoveryInProgress:    ${discoveryInProgress}\n` +
						`Num Discovered Devices: ${numDiscoveredDevices}\n` +
						`Num Inventory Devices:  ${numInventoryDevices}\n` +
						`Inv Count Δ B4 Timeout: ${inventoryCountChangedBeforeTimeout}\n` +
						`Is Loading (result):    ${tempIsLoading}`
				);
				// currnet design is this effect will only transition the value of deviceInventoryLoading
				// from true to false. Never false to true. We only want the loading indicator
				// to appear when the app first starts up, while devices are initially loading. It should
				// not appear later one when devices are being added or removed from the inventory.
				this.deviceInventoryLoading.set(tempIsLoading);
			},
			{ allowSignalWrites: true }
		);
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.deviceInventory$.asObservable();
	}

	public getInventoryDevice$(deviceId: string): Observable<InventoryDevice> {
		return this.inventoryDeviceByIdQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'cache-first'
				}
			)
			.valueChanges.pipe(
				tap(() => {
					this.logger.debug('inventoryDeviceByIdQueryGQL', 'Received update', jsonFormat({ deviceId }));
				}),
				map((query) => {
					const device = query.data.node;
					if (device && 'isDevice' in device) {
						return mapInventoryDeviceFromSysApi(device, this.deviceStatusService);
					}
					throw query.error;
				}),
				tap((device) => {
					// when query response received, set up the per-device subscription.
					this.devicesSubscriptionManager.register([device.id]);
				}),
				catchError((error: Error) => {
					this.logger.error(
						'getInventoryDevice$()',
						'Failed to query device',
						jsonFormat({ deviceId, error })
					);
					return throwError(() => error);
				})
			);
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', { deviceId, name });
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	private initService() {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
		this.inventoryCountChangedBeforeTimeout.set(true);
		this.getInventoryDevicesCount$()
			// wait up to 10s w/out changes before we give up.
			.pipe(debounceTime(10000), take(1), takeUntil(this.destroy$))
			.subscribe(() => {
				this.inventoryCountChangedBeforeTimeout.set(false);
			});

		this.deviceDiscoveryService
			.deviceRemoved$()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (removedDevice) => {
					this.devicesSubscriptionManager.deregister(removedDevice);
				}
			});

		this.getInventoryDevicesInternal();
	}

	private suspendService() {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.inventoryCountChangedBeforeTimeout.set(false);
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesSubscriptionManager.deregisterAll();
	}

	private getInventoryDevicesInternal(): void {
		this.deviceDiscoveryService
			.getDiscoveredDevicesByQuery$<InventoryDevice>(
				(id) => this.getInventoryDevice$(id).pipe(takeUntil(this.destroy$)), // query fct
				(_device) => true // filter function (don't remove any)
			)
			.pipe(
				tap((devices) => {
					this.deviceInventory$.next(devices);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private createDeviceSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		return this.inventoryDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: [
						NodeChangeType.DeviceName,
						NodeChangeType.DeviceIdentify,
						NodeChangeType.DeviceUpdateProgress,
						NodeChangeType.DeviceAvailablePackages,
						NodeChangeType.DeviceDoubleStuffProxiedTransmitters
					],
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  we always want subscription data from the server
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.logger.debug(
						'inventoryDeviceSubscriptionGQL',
						'Received update',
						jsonFormat({
							id
						})
					);
				},
				complete: () => {
					this.logger.warning('inventoryDeviceSubscriptionGQL', 'subscription completed', {
						id
					});
				},
				error: (error) => {
					this.logger.error('inventoryDeviceSubscriptionGQL', 'Encountered error', jsonFormat({ id, error }));
					retryCallback();
				}
			});
	}
}
