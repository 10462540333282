import { Injectable } from '@angular/core';

// Only for Datadog example: https://docs.datadoghq.com/logs/log_collection/javascript/
// import { datadogLogs } from '@datadog/browser-logs';

// Only for Sentry example:
// https://docs.sentry.io/platforms/javascript/guides/angular/usage/?original_referrer=https%3A%2F%2Fsentry.io%2F
//import * as Sentry from '@sentry/angular-ivy';

import { LogEntry, /*LogLevel,*/ LoggerSink } from '@shure/shared/angular/logging';

@Injectable()
export class CloudLoggerSink implements LoggerSink {
	public readonly name = 'CloudLogger';

	/**
	 * Forward all log entries to Datadog logger
	 * @param logEntry
	 */
	/*public log(logEntry: LogEntry): void {
		const message = `[${logEntry.scope}] ${logEntry.eventId} ‣ ${logEntry.message}`;
		switch (logEntry.level) {
			case LogLevel.Critical:
			case LogLevel.Error:
				datadogLogs.logger.error(message, { eventId: logEntry.eventId }, <Error>logEntry.args);
				break;
			case LogLevel.Warning:
				datadogLogs.logger.warn(message, logEntry.args || undefined);
				break;
			case LogLevel.Information:
				datadogLogs.logger.info(message, logEntry.args || undefined);
				break;
			case LogLevel.Debug:
				datadogLogs.logger.debug(message, logEntry.args || undefined);
				break;
			case LogLevel.Trace:
				datadogLogs.logger.log(message, logEntry.args || undefined);
				break;
		}
	}*/

	/**
	 * Forward all log entries to Sentry logger
	 * @param logEntry
	 */
	/*public log(logEntry: LogEntry): void {
		const message = `[${logEntry.scope}] ${logEntry.eventId} ‣ ${logEntry.message}`;
		switch (logEntry.level) {
			case LogLevel.Critical:
			case LogLevel.Error:
				Sentry.captureMessage(message, 'error');
				break;
			case LogLevel.Warning:
				Sentry.captureMessage(message, 'warning');
				break;
			case LogLevel.Information:
				Sentry.captureMessage(message, 'info');
				break;
			case LogLevel.Debug:
				Sentry.captureMessage(message, 'debug');
				break;
			case LogLevel.Trace:
				Sentry.captureMessage(message, 'log');
				break;
		}
	}*/
	public log(logEntry: LogEntry): void {
		// eslint-disable-next-line no-console
		console.warn('TODO: implement desired cloud logging solution:', logEntry);
	}
}
