import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { ShIgniteDialogBoxComponent } from './dialog-box.component';
import { IgniteDialogDataResponse, IgniteDialogData } from './dialog-box.model';

@Injectable()
export class ShIgniteDialogBoxService {
	public dialogRefId: string | undefined;

	public readonly defaultDialogConfig: MatDialogConfig = { width: '400px', panelClass: 'sh-custom-dialog-container' };

	constructor(private dialog: MatDialog) {}

	/**
	 * popup dialog
	 * @param {string} title
	 * @param {IgniteDialogData} dialogData
	 * @param {MatDialogConfig} [config={}]
	 * @returns {Observable<IgniteDialogDataResponse>}
	 */
	public openDialog(
		title: string,
		dialogData?: IgniteDialogData | undefined,
		config: MatDialogConfig = this.defaultDialogConfig
	): Observable<IgniteDialogDataResponse> {
		let dialogRef: MatDialogRef<ShIgniteDialogBoxComponent> | undefined;

		if (this.dialogRefId && this.dialog.openDialogs.length) {
			dialogRef = <MatDialogRef<ShIgniteDialogBoxComponent>>this.dialog.getDialogById(this.dialogRefId);
		}

		if (dialogRef && dialogRef.componentInstance) {
			return dialogRef.afterClosed();
		}
		dialogRef = this.dialog.open(ShIgniteDialogBoxComponent, config);
		dialogRef.componentInstance.dialogData = dialogData;
		dialogRef.componentInstance.title = title;
		this.dialogRefId = dialogRef.id;
		return dialogRef.afterClosed().pipe(map((row) => row));
	}
}
