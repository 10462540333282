import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, Inject, Output, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

import { InventoryDevicesApiService } from '@shure/cloud-apps/devices/data-access-intances/dai-services-cloud-sysapi';
import { APP_ENVIRONMENT, AppEnvironment, getShureCloudUri } from '@shure/shared/models';

import { MaterialIconsModule } from '../material-icons.module';

type NavigationItems = {
	icon: string;
	label: string;
	disabled: boolean;
	route: string;
	selected: boolean;
	buttonId: string;

	// A somewhat generic way to allow the dynamic updating of values in a nav label. The initial
	// use case is to include the count of claimed devies in the nav label. If this field
	// is left undefined, nothing happens.
	count$?: Observable<number>;
};

@Component({
	selector: 'sh-device-portal-sidenav',
	templateUrl: './sh-device-portal-sidenav.component.html',
	styleUrls: ['./sh-device-portal-sidenav.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MaterialIconsModule,
		RouterModule,
		MatSidenavModule,
		MatToolbarModule,
		MatDividerModule,
		MatIconModule,
		MatButtonModule,
		TranslocoModule
	]
})
export class ShDevicePortalSidenavComponent implements OnInit, OnDestroy {
	// when an item from the navlist is selected,we'll emit an event to the parent.
	// the parent can chose to do something with the event, such as close the sidenav.

	@Output() navItemSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
	protected userSignedIn = false;

	public shureCloudUri = 'https://cloud.shure.com'; // gets set from environment, but default to prod URI in case env not provided
	public navItems: NavigationItems[] = [
		{
			icon: 'sh-devices',
			label: 'devices', // will get translated in the HTML.
			disabled: false,
			route: 'devices',
			buttonId: 'sh-device-manager-sidenav-item-devices',
			selected: false,
			count$: this.inventoryDevicesApiService.getInventoryDevicesCount$()
		}
	];

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		protected readonly inventoryDevicesApiService: InventoryDevicesApiService,
		protected readonly router: Router,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		const shureCloudUri = getShureCloudUri(window.location.origin, this.appEnv.shureCloudUriMap);
		if (shureCloudUri) {
			this.shureCloudUri = shureCloudUri;
		}
	}

	ngOnInit(): void {
		if (this.appEnv.showUserSettings) {
			this.navItems.push({
				icon: 'settings',
				label: 'settings',
				disabled: false,
				route: 'settings',
				buttonId: 'sh-device-device-manager-sidenav-item-settings',
				selected: false
			});
		}

		this.router.events
			.pipe(
				filter((event): event is NavigationEnd => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe((event) => {
				const match = this.navItems.find(
					({ route }) => event.url.includes(route) || event.urlAfterRedirects.includes(route)
				);
				this.navItems.forEach((navItem) => (navItem.selected = false));
				if (match) {
					match.selected = true;
				}
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
